import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import AlertItem from "../widgets/AlertItem";
import EnhancedTable from '../widgets/ResponsiveTable';
import EnhancedToolbar from '../widgets/Toolbar';
import FormDialog from "../etqa/FormDialog";
import { StatusForm } from "./StatusForm";
import { ResearchOutcomeReportForm } from "./ResearchOutcomeReportForm";

export const ResearchVisitDashBoardHeadCells = [
    { id: 'EmployerName', numeric: false, disablePadding: true, label: 'Employer Name' },
    { id: 'EmployerSDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: 'ScheduledDate', numeric: false, disablePadding: true, label: 'Scheduled Date and Time' },
    { id: 'ContactPersonName', numeric: false, disablePadding: true, label: 'Contact Person Name' },
    { id: 'ContactPersonEmail', numeric: false, disablePadding: true, label: 'Contact Person E-mail' },
    { id: 'ContactPersonCellPhoneNumber', numeric: false, disablePadding: true, label: 'Contact Person Cell Phone Number' },
    { id: 'FoodBevSETAContact', numeric: false, disablePadding: true, label: 'FoodBev SETA Contact' },
    { id: 'FoodBevSETAEmail', numeric: false, disablePadding: true, label: 'FoodBev SETA Email' },
    { id: 'DocumentUploaded', numeric: false, disablePadding: true, label: 'Document Uploaded' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];

const toolbarRef = React.createRef();
const utils = new ViewUtils();
const statusFormRef = React.createRef();
const documentFormRef = React.createRef();

class ResearchVisitDashboardSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedResearchVisit: null,
            showTable: true,
            updateStatusDialogOpen: false,
            hasOutcomeReport: false,
            updateDocumentDialogOpen: false,
            contactPersonEmail: null,
            contactPersonFullName: null,
            EmployerName: null,
            ScheduledDate: null,
            message: "",
            messageStyle: "",
            searchParameters: [{ Name: "ResearchVisitID", Value: null }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'uploadDocument', label: 'UPLOAD OUTCOME REPORT', tooltip: 'Upload Document', disabled: true },
                        { id: 'updateStatus', label: 'UPDATE STATUS', tooltip: 'Update Research Visit Status', disabled: true },
                        { id: 'confirm', label: 'CONFIRM', tooltip: 'Confirm This Research Visit', disabled: true }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("updateStatus" === buttonId) {
            this.setState({ updateStatusDialogOpen: true, message: "" })
        }
        else if ("uploadDocument" === buttonId) {
            this.setState({ updateDocumentDialogOpen: true, message: "" })
        }
        else if ("confirm" === buttonId) {
            this.confirm()
        }
    }

    confirm() {
        let url = "api/sims/researchvisitdashboard/confirmResearchVisit";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
            this.setState({
                showTable: false
            }, () => {
                this.setState({ showTable: true })
            });
        },
            (response) => {
                this.errorCallback(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(({
                    "Id": this.state.selectedResearchVisit,
                    "ContactPersonEmail": this.state.contactPersonEmail,
                    "ContactPersonName": this.state.contactPersonName,
                    "EmployerName": this.state.EmployerName,
                    "ScheduledDate": this.state.ScheduledDate
                }))
            }
        )
    }

    successCallback(response) {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
        });
    }

    errorCallback(response) {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            if (selection[0].DocumentUploaded == '' || utils.isNull(selection[0].DocumentUploaded)) {
                this.setState({
                    selectedResearchVisit: selection[0].Id,
                    message: "",
                    messageStyle: "",
                    hasOutcomeReport: false,
                    contactPersonEmail: selection[0].ContactPersonEmail,
                    contactPersonName: selection[0].ContactPersonName,
                    EmployerName: selection[0].EmployerName,
                    ScheduledDate: selection[0].ScheduledDate
                });
            } else {
                this.setState({
                    selectedResearchVisit: selection[0].Id,
                    message: "",
                    messageStyle: "",
                    hasOutcomeReport: true,
                    contactPersonEmail: selection[0].ContactPersonEmail,
                    contactPersonName: selection[0].ContactPersonName,
                    EmployerName: selection[0].EmployerName,
                    ScheduledDate: selection[0].ScheduledDate
                });
            }
            
            if (selection[0].Status == "Visit Confirmed") {
                toolbarRef.current.setButtonDisabled("uploadDocument", false);
                toolbarRef.current.setButtonDisabled("updateStatus", false);
                toolbarRef.current.setButtonDisabled("confirm", true);
            }
            else if (selection[0].Status == "Visit Pending") {
                toolbarRef.current.setButtonDisabled("uploadDocument", true);
                toolbarRef.current.setButtonDisabled("updateStatus", true);
                toolbarRef.current.setButtonDisabled("confirm", false);
            } else {
                toolbarRef.current.setButtonDisabled("uploadDocument", true);
                toolbarRef.current.setButtonDisabled("updateStatus", true);
                toolbarRef.current.setButtonDisabled("confirm", true);
            }

        } else {
            this.setState({
                selectedResearchVisit: null,
                message: "",
                messageStyle: "",
                hasOutcomeReport: false,
                contactPersonEmail: null,
                contactPersonName: null,
                EmployerName: null,
                ScheduledDate: null
            });

            toolbarRef.current.setButtonDisabled("uploadDocument", true);
            toolbarRef.current.setButtonDisabled("updateStatus", true);
            toolbarRef.current.setButtonDisabled("confirm", true);
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateStatusDialogOpen: false, selectedResearchVisit: null, updateDocumentDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateStatusDialogOpen: false,
                updateDocumentDialogOpen: false
            })
        }
    }

    dialogUploadActionHandler(isCancel, added) {
        if (isCancel === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateStatusDialogOpen: false, selectedResearchVisit: null, updateDocumentDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Research Visit Dashboard"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={ResearchVisitDashBoardHeadCells}
                            dataUrl={`api/sims/researchvisitdashboard/GetAllResearchVisits`}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <FormDialog
                    open={this.state.updateStatusDialogOpen}
                    dialogTitle={"Update Research Visit Status"}
                    viewRef={this}
                    entityId={this.state.selectedVisit}
                    formRef={statusFormRef}
                    usePut={true}
                    saveUrl={"api/sims/researchvisitdashboard/UpdateResearchVisitStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <StatusForm ref={statusFormRef} hasOutComeReportFlag={this.state.hasOutcomeReport} id={this.state.selectedResearchVisit} dataURL='api/sims/researchvisitdashboard/GetFindResearchVisitStatus' controllerName='researchvisitdashboard' />
                </FormDialog>

                <FormDialog
                    open={this.state.updateDocumentDialogOpen}
                    dialogTitle={"Upload Research Visit Document"}
                    viewRef={this}
                    entityId={this.state.selectedVisit}
                    formRef={documentFormRef}
                    hideSave={true}
                    usePut={true}
                    saveUrl={"api/sims/researchvisitdashboard/UpdateResearchVisitStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogUploadActionHandler(isCancelled, linked)}
                >
                    <ResearchOutcomeReportForm ref={documentFormRef} id={this.state.selectedResearchVisit} controllerName='researchvisitdashboard' />
                </FormDialog>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ResearchVisitDashboardSearch)
);
