import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import DocumentUpload from "../../widgets/DocumentUpload";


const styles = makeStyles({
    TextArea: {
        '& .MuiOutlinedInput-inputMultiline': {
            height: "150px !important",
            width: "600px !important"
        }
    }
})

const utils = new ViewUtils();
export const BulkEmailForm = React.forwardRef((props, ref) => {
    const [UploadFilename, setUploadFilename] = React.useState("");

    const onUploadHandler = (data) => {
        if (data) {
            setUploadFilename(data.name);
        }
    };

    return (
        <EntityForm ref={ref} viewId="bulkemail" values={props.data} dataURL='api/sims/bulkemails/GetFind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="BulkEmailForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="Subject"
                                        label="Subject"
                                        style={{ width: "50%" }}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="Body"
                                        label="Message Body"
                                        multiline
                                        styles={styles()}
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <DocumentUpload
                                        showViewer={false}
                                        onUploadHandler={onUploadHandler}
                                        buttonLabel="Attachment"
                                        filename={UploadFilename}
                                        form={form}
                                        id="Attachment"
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})

