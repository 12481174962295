import React from 'react';
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./CRM.css"
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';

export default function Documents(props, ref) {

    const headCells = props.IsSkills ?
        [

            { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded By' },
            { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' },
            { id: 'DocumentRelates', numeric: false, disablePadding: true, label: 'Document Relates' },
            { id: 'Comment', numeric: false, disablePadding: true, label: 'Comment' },
            { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
        ]
        :
        [
            { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded By' },
            { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' },
            { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' }
        ]
    return (
        <EnhancedTable headCells={headCells} onCellSelectionChange={(rowid, columnid) => { props.handleCell(rowid, columnid) }} searchParameters={[{ Name: `${props.moduleName}ID`, Value: props.selection }]} paged={true}
            dataUrl={props.documentsDataUrl} 
        />
    );
}
