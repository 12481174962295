import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerBursaryForm } from '../../etqa/program/registration/LearnerBursaryForm';
import withMenuPermission from "../../widgets/withMenuPermission";

const BursaryRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerBursary extends Component {

    state = {
        model: {
            BursaryTypeID: null
        },
        BursaryDetails: {
            BursaryTitle: "",
            BursaryID: ""
        },
        messageStyle: "message",
        id: null,
        showTable: true,
        CurrentProgramme: this.props.location.state.BursaryID,
        //dependency: null,
        //postDependencyNQF: null,
        clearOptions: false,
        CreateNewFlag: this.props.location.state.hideFields,
        Learner: this.props.location.state.LearnerID,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon', visible: !this.props.location.state.viewOnly }
                    ]
                }
            ]
        }
    };


    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            this.setState({
                BursarySetupDetails: {
                    BursaryID: values.BursaryID,
                    BursaryTitle: values.BursaryTitle
                },
                Learner: this.props.location.state.LearnerID,
                /* postDependencyNQF: values.NQFLevelID*/
            });

            //if (!utils.isNull(values.BursaryTypeID) && JSON.stringify(this.state.dependency) !== JSON.stringify(values.BursaryTypeID)) {
            //    const bursaryTypeID = {
            //        Name: "BursaryTypeID",
            //        Value: values.BursaryTypeID.Id
            //    }
            //    if (!utils.isNull(bursaryTypeID.Value)) {
            //        this.setState({ dependency: bursaryTypeID })
            //    }
            //};

            let newValues = null;
            if (!utils.isNull(values)) {
                newValues = { ...values };
            }
            if (!utils.isNull(newValues)) {
                if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                    //if (!utils.isNull(newValues.BursaryTypeID) && !utils.isNull(this.state.model.BursaryTypeID) && newValues.BursaryTypeID.Id !== this.state.model.BursaryTypeID.Id) {
                    //    newValues.NQFLevelID = null;
                    //    this.setState({ clearOptions: true });
                    //}
                    this.setState({
                        model: newValues,
                    });
                }
            }
        }
    }

    onChangeBursaryTypeIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.NQFLevelID = null;
        this.setState({ model: newModel, clearOptions: true })
    }

    clearOptionsHandler = () => {
        this.setState({ clearOptions: false });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false, model: {
                    BursaryTypeID: null
                }
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = BursaryRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnerbursary/LinkLearnerBursary", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {

        this.props.history.push({
            pathname: '/LearnerBursarySearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New Bursary > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update Bursary > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <LearnerBursaryForm ref={BursaryRef}
                                            viewRef={this}
                                            /*dependencyHander={this.state.postDependencyNQF}*/
                                            id={this.state.CurrentProgramme}
                                            /*dependency={this.state.dependency}*/
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                            hideFields={this.state.CreateNewFlag}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            clearOptions={this.state.clearOptions}
                                            clearOptionsHandler={this.clearOptionsHandler}
                                            model={this.state.model}
                                            data={this.state.model}
                                            onChangeBursaryTypeIDHandler={this.onChangeBursaryTypeIDHandler}
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddLearnerBursary));

