import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { CustomDateTimePicker } from '../../widgets/CustomDateTimePicker';


const utils = new ViewUtils();
export const EmployerAddResearchVisitForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="employerAddResearchVisit" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="employerAddResearchVisit">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomDateTimePicker id="ScheduledDate" className="w-100" required={true} label="Scheduled Date and Time" form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="ContactPersonName"
                                        key={tabValue}
                                        label="Contact Person Name"
                                        required={true}
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="ContactPersonSurname"
                                        required={true}
                                        key={tabValue}
                                        label="Contact Person Surname"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="ContactPersonEmail"
                                        key={tabValue}
                                        label="Contact Person E-mail"
                                        required={true}
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="ContactPersonCellPhoneNumber"
                                        required={true}
                                        key={tabValue}
                                        label="Contact Person Cell Phone Number"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField id="FoodBevSETAContact"
                                        required={true}
                                        key={tabValue}
                                        label="FoodBev SETA Contact"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                                <td>
                                    <CustomTextField id="FoodBevSETAEmail"
                                        key={tabValue}
                                        required={true}
                                        label="FoodBev SETA Email"
                                        className="w-100"
                                        InputProps={{
                                            readOnly: false,
                                        }}
                                        form={form} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
