import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import SearchView from "../../../widgets/SearchView";
import SkillsProgrammeSearchForm from './SkillsProgrammeSearchForm';
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

const headCells = [
    { id: "SkillsProgrammeCode", numeric: false, disablePadding: true, label: "Skills Programme Code" },
    { id: "SkillsProgrammeTitle", numeric: false, disablePadding: true, label: "Skills Programme Title" }
]


class SkillsProgrammeSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.programmeId) ?
            [
                { Name: "id", Value: this.props.programmeId },
                { Name: "SkillsProgrammeCode", Value: "" },
                { Name: "SkillsProgrammeTitle", Value: "" }
            ]
            :
            [
                { Name: "SkillsProgrammeCode", Value: "" },
                { Name: "SkillsProgrammeTitle", Value: "" },
            ]


        let model = !utils.isNull(this.props.programmeId) ?
            {

                id: this.props.programmeId,
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: ""

            }
            :
            {
                SkillsProgrammeCode: "",
                SkillsProgrammeTitle: ""
            }


        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedSP: null,
            linkDialogOpen: false
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.props.showTitle === true || utils.isNull(this.props.showTitle) ? 'Skills Programme Search' : null}>
                <SearchView
                    mode={this.props.mode}
                    headCells={headCells}
                    dataUrl={this.props.dataUrl}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="SkillsProgrammeCode"
                    SecondDescriptionProp="SkillsProgrammeTitle"
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    keyUpSwitch={this.state.keyUpSwitch}
                    mode="lookup"
                    showToolBar={true}
                >
                    <SkillsProgrammeSearchForm onKeyUp={(e) => { this.onkeyup(e) }} model={this.state.model} />
                </SearchView>
            </ViewContainer>
        )
    }
}

export default withRouter(
    connect()(SkillsProgrammeSearch)
);