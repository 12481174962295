import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import EnhancedToolbar from '../widgets/Toolbar';
import withMenuPermission from "../widgets/withMenuPermission";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


import ApplicationPerInterventionTypeBarChart from '../DGDashboardCharts/ApplicationPerInterventionTypeBarChart';
import ApplicationPerInterventionTypeLineChart from '../DGDashboardCharts/ApplicationPerInterventionTypeLineChart';
import ApplicationPerInterventionTypePieChart from '../DGDashboardCharts/ApplicationPerInterventionTypePieChart';

import ApplicationPerStatusLineChart from '../DGDashboardCharts/ApplicationPerStatusLineChart';
import ApplicationPerStatusPieChart from '../DGDashboardCharts/ApplicationPerStatusPieChart';
import ApplicationPerStatusBarChart from '../DGDashboardCharts/ApplicationPerStatusBarChart';

import PerRegionApplicationBarChart from '../DGDashboardCharts/PerRegionApplicationBarChart';
import PerRegionApplicationLineChart from '../DGDashboardCharts/PerRegionApplicationLineChart';
import PerRegionApplicationPieChart from '../DGDashboardCharts/PerRegionApplicationPieChart';

import { Row } from 'react-bootstrap';
import { event } from 'jquery';
const utils = new ViewUtils();


class DGDashboard extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            perregionapplicationshow : false,
            applicationperinterventiontypeshow: false,
            applicationperstatusshow : false,
            //applicationreportperstatusshow : false,          
        };
    }

    componentDidMount() {
        utils.setLegend("DG Dashboard");
        this.setState({ perregionapplicationshow: true });        
    }


    handleReportNameChanged = (event) => {

        var divToShow = event.target.value;
        //event.preventDefault();
        

        if (divToShow == 'divPerRegionApplication') {

            this.setState({ perregionapplicationshow: true });

            this.setState({ applicationperinterventiontypeshow: false });
            this.setState({ applicationperstatusshow: false })
        }
        else if (divToShow == 'divApplicationPerInterventionType') {
            this.setState({ applicationperinterventiontypeshow: true });  

            this.setState({ perregionapplicationshow: false });
            this.setState({ applicationperstatusshow: false });
        }
        else if (divToShow == 'divApplicationPerStatus')
        {
            this.setState({ applicationperstatusshow: true });

            this.setState({ applicationperinterventiontypeshow: false });  
            this.setState({ perregionapplicationshow: false });

        }
        
    }


    render() {

        
        return (

            <div className="container-fluid">
                <br />
                <Row>
                    <TextField className="w-30" id="ReportNameID" label="Report Name: " select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleReportNameChanged(values)}>
                        <MenuItem value="divPerRegionApplication" key="1">Per Region Application</MenuItem>
                        <MenuItem value="divApplicationPerInterventionType" key="2">Application Per Intervention Type</MenuItem>
                        <MenuItem value="divApplicationPerStatus" key="3">Application Per Status</MenuItem>
                    </TextField>
                </Row>

                <br />


                {
                    this.state.perregionapplicationshow ?
                        <div id="divPerRegionApplication" key="1">
                            <Row>
                                <PerRegionApplicationBarChart  />
                                <PerRegionApplicationLineChart />
                            </Row>
                            <Row>
                                <PerRegionApplicationPieChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.applicationperinterventiontypeshow ?
                        <div id="divApplicationPerInterventionType" key="2">
                            <Row>
                                <ApplicationPerInterventionTypePieChart />
                                <ApplicationPerInterventionTypeLineChart />
                            </Row>
                            <Row>
                                <ApplicationPerInterventionTypeBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.applicationperstatusshow ?
                        <div id="divApplicationPerStatus" key="3">
                            <Row>
                                <ApplicationPerStatusLineChart />
                                <ApplicationPerStatusPieChart />
                            </Row>
                            <Row>
                                <ApplicationPerStatusBarChart />
                            </Row>
                        </div> : null
                }

            </div>
        );

    }
}

export default withRouter(withMenuPermission(DGDashboard));
