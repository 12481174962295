import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import { CustomTextField } from '../../widgets/CustomTextField';
import { Row, Col } from 'react-bootstrap';
import { SelectItem } from '../../widgets/SelectItem';

export default function SkillsQueryReasonFormSearch(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="SkillsQueryReasonFormSearch" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="AssessorForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <SelectItem
                                dataUrl="api/sims/SkillsQueryReasons/GetFinancialYearLookupListSpecific"
                                id="FinancialYearID"
                                label="Financial Year"
                                className="w-100"
                                form={form} />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="Name"
                                label="Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="Description"
                                label="Description"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
}
