import React from 'react';
import { SelectItem } from '../widgets/SelectItem';
import { CustomTextField } from '../widgets/CustomTextField';
import { CustomDatePicker } from '../widgets/CustomDatePicker';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';

const utils = new ViewUtils();
export const ProjectNotificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="ProjectNotification" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProjectNotificationForm">
                    <table cellPadding="2" className="w-100">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="NotificationName"
                                        label="Notification Name"
                                        className="w-100"
                                        form={form}
                                        required
                                    />
                                </td>
                                <td>
                                    <CustomTextField
                                        id="NotificationMessage"
                                        label="Notification Message"
                                        className="w-100"
                                        form={form}
                                        required
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/projectnotification/GetLookupList?listId=projectprogrammetype"
                                        id="ProjectProgrammeTypeID"
                                        required
                                        key={tabValue}
                                        label="Learning Programmes"
                                        className="w-100"
                                        form={form} />
                                </td>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/projectnotification/GetLookupList?listId=ProjectNotificationPeriod"
                                        id="ProjectNotificationPeriodID"
                                        required
                                        key={tabValue}
                                        label="Notification Trigger"
                                        className="w-100"
                                        form={form} />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
