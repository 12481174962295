import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import LearnerQualificationSearch from '../program/LearnerQualificationSearch';
import { LookupField } from '../../widgets/LookupField';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const LearnerTradeTestForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [docName, setDocName] = React.useState('');

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const styles = makeStyles({
        TextArea: {
            '& .MuiOutlinedInput-inputMultiline': {
                height: "200px !important",
                width: "600px !important"
            }
        }
    })

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="TradeTest">

                    <div className="row w-100">
                        <div className="col w-100">
                            <div>
                                {docName != '' ? docName : ''}
                            </div>
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Venue"
                                                key={tabValue}
                                                label="Venue"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="TestDateFrom"
                                                key={tabValue}
                                                label="Test Date From"
                                                className='{classes.textField} w-100 '
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="TestDateTo"
                                                key={tabValue}
                                                label="Test Date To"
                                                className='{classes.textField} w-100 '
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/unitstandardsetupdetails/GetLookupList?listId=ArtisansTradeTestsResults"
                                                id="ResultsID"
                                                key={tabValue}
                                                label="Results"
                                                className="w-50"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TradeTestNumber"
                                                key={tabValue}
                                                label="Trade Test Number"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="AssessorRegistrationNumber"
                                                key={tabValue}
                                                label="Assessor Registration Number"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ModeratorRegistrationNumber"
                                                key={tabValue}
                                                label="Moderator Registration Number"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/unitstandardsetupdetails/GetLookupList?listId=AttemptNumber"
                                                id="AttemptNumberID"
                                                key={tabValue}
                                                label="Attempt Number"
                                                className="w-50"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <LookupField
                                            id="QualificationID"
                                            label="Qualification"
                                            className="w-100"
                                            form={form}
                                        >
                                            <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                dataUrl='api/sims/learnerTrade/QualificationSearch'
                                                onLookupValueChange={(value) => {
                                                    form.setLookupSelection("QualificationID", value)
                                                }} autoFetchData={true} selection={props.selection} />
                                        </LookupField>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})