import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../../widgets/Toolbar';
import "./../../../../Grid.css";
import "./../../../../Form.css";
import "./../../../../App.css";
import '../../Style.css'
import ViewUtils from '../../../../ViewUtils';
import AlertItem from "../../../../widgets/AlertItem";
import Button from '@material-ui/core/Button';
import { ViewContainer } from "./../../../../View.jsx";
import EnhancedTable from '../../../../widgets/ResponsiveTable';

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Forms', tooltip: '' }
            ]
        }
    ]
};

const formRef = React.createRef();

const headCells = [
    { id: 'Section', numeric: false, disablePadding: true, label: 'Section'},
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' },
    { id: 'Completed', numeric: false, disablePadding: true, label: 'Completed' },
];

class CheckList extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        WSPATRID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
        WSPATRFormsID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormsID)) ? this.props.location.state.WSPATRFormsID : null,
        WSPATRFormName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormName)) ? this.props.location.state.WSPATRFormName : null,
        RecordID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RecordID)) ? this.props.location.state.RecordID : null,
        NQFLevel: null,
        open: null,
        searchParameters: [{ Name: "WSPATRID", Value: this.props.location.state.WSPATRID }, { Name: "WSPATRFormsID", Value: this.props.location.state.WSPATRFormsID }],
        adddisabled: false,
        editdisabled: true,
        canceldisabled: true,
        EditFormData: null,
        formPermission: "Enabled",
        addDefaultObject: {
            Section: "",
            Description: "",
            Completed: ""
        }
    };

    close() {
        this.props.history.push({
            pathname: '/WspAtrForms',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber,
                WSPATRID: this.props.location.state.WSPATRID
            }
        });
    }

    componentDidMount() {

        this.getFormHead()
        this.GetWSPATRFormPermissionCheck()
    }

    getFormHead() {
        let url = "api/sims/wspatr/GetFindHeader?WSPATRID=" + this.state.WSPATRID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    GetWSPATRFormPermissionCheck() {
        let url = "api/sims/wspatr/GetWSPATRFormPermissionCheck?WSPATRID=" + this.state.WSPATRID + "&WSPATRFormsID=" + this.state.WSPATRFormsID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "Enabled") {
                this.setState({
                    adddisabled: true,
                    removedisabled: true,
                    removealldisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    formPermission: response.Result
                })
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }


    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        } 
    }

    render() {

        return (
            <ViewContainer mode={this.props.mode} title="">
                <div className="view w-100 h-100">
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <br />
                    <table width="100%" class="FormsTableHeader">
                        <tbody>
                            <tr>
                                <td>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                                </td>
                                <td align="center">
                                    <b>{this.state.WSPATRFormName}</b><br />
                                    <b>{this.state.FinancialYear}</b>
                                </td>
                                <td align="right">
                                    <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <div className="searchResults">
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/WSPATRForms2023/GetSearchCheckList`}
                            makeRowBold={true}
                            searchParameters={this.state.searchParameters} paged={false} clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow} />
                    </div>
                </div>
            </ViewContainer>
        );
    }    
}

export default withRouter(CheckList);