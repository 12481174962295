import React from "react";
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "./Provider.css";
import { CustomTextField } from '../../../widgets/CustomTextField';
import { EntityForm } from '../../../widgets/Forms';
import { SelectItem } from '../../../widgets/SelectItem';
import ViewUtils from '../../../ViewUtils';

const utils = new ViewUtils();

export const AddProviderRoleForm = React.forwardRef((props, ref) => {
    const [autoSDL, setautoSDL] = React.useState(null);

    console.clear()
    console.log(props.SecondaryRegistrationType)

    function AttemptSDLGeneration(form, generateSDLFlag) {
        if (generateSDLFlag !== undefined) {
            if (generateSDLFlag.Description == "No") {
                let url = "api/sims/OnlineProviderRegistration/GetOnlineRegistrationAutoSDLNumber";
                utils.invokeUrlExternal(url, (response) => {
                    setautoSDL(response)
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            }
        }

        if (autoSDL) {
            form.values['SDLNumber'] = autoSDL;
        }
    }

    function LookupValidation(form, value) {
        if (props.SecondaryRegistrationType == "reaccreditation") {
            return false
        }
        if (value !== undefined && value !== null) {
            if (value.Description == "Yes") {
                setautoSDL('')
                return false
            }
            else if (value.Description == "No") {
                return true
            }
        }
        else {
            return true
        }
    }

    return (
        <EntityForm ref={ref} viewId="AddProviderRoleForm"
            values={props.values} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler} IsExternal={props.IsExternal}>
            {form =>
                <form className={form.classes.container} id="ProviderForm">
                    <div className="row">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    {
                                        (props.SecondaryRegistrationType === "newaccreditation" || props.SecondaryRegistrationType === "programmeapproval") ?
                                            <tr>
                                                <td>
                                                    <SelectItem
                                                        dataUrl={"api/sims/" + props.role + "/GetLookupList?listId=YesNo"}
                                                        id="YesNoID"
                                                        required
                                                        label="Do you have an SDL number?"
                                                        className="wideDrop"
                                                        onChange={AttemptSDLGeneration(form, form.values["YesNoID"])}
                                                        form={form}
                                                        checkRefreshToken={!props.IsExternal}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                     }
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="SDLNumber"
                                                label="SDL Number"
                                                className="wideDrop"
                                                required
                                                value={autoSDL}
                                                form={form}
                                                disabled={LookupValidation(form, form.values["YesNoID"])}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            }
        </EntityForm >
    )
})