import React from 'react';
import TabPanel, { FormTabContainer } from '../widgets/TabPanel';
import { SelectItem } from '../widgets/SelectItem';
import { CustomTextField } from '../widgets/CustomTextField';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';
import { CustomDatePicker } from '../widgets/CustomDatePicker';
import { CustomDateTimePicker } from '../widgets/CustomDateTimePicker';
import EmployerSearch from '../etqa/employer/EmployerSearch';
import QualificationSetupSearch from '../etqa/program/QualificationSetupSearch';
import { LookupField } from '../widgets/LookupField';


const utils = new ViewUtils();

export const CaptureSiteVisitForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="SiteVisitForm" values={props.data} org="saseta" dataURL='api/sims/projectsitevisit/GetFind'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="CaptureSiteVisitForm">
                    <FormTabContainer>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <LookupField
                                            id="EmployerID"
                                            label="Company Name"
                                            className="w-100"
                                            form={form}
                                            required
                                        >
                                            <EmployerSearch showTitle={false} mode='lookup' dataUrl="api/sims/projectsitevisit/EmployerSearch"
                                                onLookupValueChange={(value) => {
                                                    form.setLookupSelection("EmployerID", value)
                                                }} autoFetchData={false} selection={props.selection} />
                                        </LookupField>
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=ProjectProgrammeType"
                                            id="ProjectProgrammeTypeID"
                                            required
                                            key={tabValue}
                                            label="Learning Programmes"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <LookupField
                                            id="QualificationID"
                                            label="Qualification"
                                            className="w-100"
                                            form={form}
                                        >
                                            <QualificationSetupSearch showTitle={true} mode='lookup' dataUrl="api/sims/projectsitevisit/QualificationSearch"
                                                onLookupValueChange={(value) => form.setLookupSelection("QualificationID", value)} autoFetchData={false} selection={props.selection} />
                                        </LookupField>
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=province"
                                            id="ProvinceID"
                                            required
                                            key={tabValue}
                                            label="Province"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="OfficialResponsible"
                                            label="Official Responsible"
                                            className="w-100"
                                            form={form}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="PhysicalAddressLines"
                                            label="Physical Address"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomDateTimePicker
                                            id="SiteVisitStartDate"
                                            key={tabValue}
                                            label="Site Visit Start Date"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            validationGroup="registrationDates"
                                            required
                                            defaultDate={new Date()}  
                                            minDate={new Date()}
                                        />
                                    </td>
                                    <td>
                                        <CustomDateTimePicker
                                            id="SiteVisitEndDate"
                                            key={tabValue}
                                            validationGroup="registrationDates"
                                            label="Site Visit End Date"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            required
                                            validator={{
                                                script: (formValues) => {
                                                    return !utils.isNull(formValues)
                                                        && !utils.isNull(formValues.SiteVisitStartDate)
                                                        && formValues.SiteVisitStartDate.getTime() < formValues.SiteVisitEndDate.getTime()
                                                }, message: "Start date must be before the end date"
                                            }}
                                            defaultDate={new Date()}
                                            minDate={new Date()}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=projectsitevisitreason"
                                            id="ProjectSiteVisitReasonID"
                                            required
                                            key={tabValue}
                                            label="Site Visit Reason"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="ContactPersonName"
                                            label="Contact Person Name"
                                            className="w-100"
                                            form={form}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="ContactTelephoneNumber"
                                            key={tabValue}
                                            label="Contact Telephone Number"
                                            validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                            className="w-100"
                                            form={form}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="ContactCellphoneNumber"
                                            key={tabValue}
                                            label="Contact Cellphone Number"
                                            validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                            className="w-100"
                                            form={form}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="ContactPersonEMail"
                                            required
                                            key={tabValue}
                                            label="E Mail"
                                            validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                            className='{classes.textField} w-100'
                                            form={form}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="NumberOfLearnersCR"
                                            label="Total Number Of Learners CR"
                                            className="w-100"
                                            form={form}
                                            validationRegex={/^\d*$/}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="NumberOfLearnersMIS"
                                            label="Total Number Of Learners MIS"
                                            className="w-100"
                                            validationRegex={/^\d*$/}
                                            form={form}
                                            required
                                        />
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=financialyear"
                                            id="FinancialYearID"
                                            required
                                            key={tabValue}
                                            label="Financial Year"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=projectlearnerreport"
                                            id="ProjectLearnerReportID"
                                            required
                                            key={tabValue}
                                            label="Learner Report"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=projectsitevisitstatus"
                                            id="ProjectSiteVisitStatusID"
                                            required
                                            key={tabValue}
                                            label="Site Visit Status"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectsitevisit/GetLookupList?listId=yesno"
                                            id="ReportReceivedID"
                                            required
                                            key={tabValue}
                                            label="Signed Site Visit Report Received"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="Comments"
                                            label="Comments"
                                            className="w-100"
                                            form={form}
                                            required
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>

    );
})
