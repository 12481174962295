import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../widgets/Toolbar';
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { ViewContainer } from "./../View.jsx";
import ViewUtils from "./../ViewUtils";
import AlertItem from "../widgets/AlertItem";
import { ProjectBeneficiariesForm } from './ProjectBeneficiariesForm';
import { Dialog, DialogContent, DialogContentText, DialogTitle, TextField, Button, DialogActions } from '@material-ui/core';

const beneficiaryRef = React.createRef();
const utils = new ViewUtils();

class ProjectBeneficiaryDetail extends Component {

    state = {
        model: {
            LNumber: this.props.location.state.selectedBeneficiary.LNumber,
            EmployerName: this.props.location.state.selectedBeneficiary.EmployerName,
            ContractNumber: this.props.location.state.selectedBeneficiary.TaskNumber,
            ContractStatus: "NA",
            ContractStartDate: this.props.location.state.selectedBeneficiary.ContractStartDate,
            ContractEndDate: this.props.location.state.selectedBeneficiary.ContractEndDate,
            LearnersAwarded: this.props.location.state.selectedBeneficiary.LearnersAwarded,
            LearnersAssociated: 12,
            LearnersRemained: 5,
            AmountAwarded: this.props.location.state.selectedBeneficiary.AmountAwarded,
            GrantsPaid: 17500,
            GrantsClaimsCreated: 5,
            GrantsClaimsApproved: 5,
            GrantsBatchesCreated: 5,
            GrantsBatchesApproved: 5,
            GrantsDue: 7
        },
        messageStyle: "message",
        projectID: this.props.location.state.projectID,
        showTable: true,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'email', label: 'EMAIL', tooltip: 'email', visible: true, disabed: true },
                        { id: 'download', label: 'DOWNLOAD', tooltip: 'download', visible: true, disabed: true }
                    ]
                }
            ]
        }
    };

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: { projectID: this.state.projectID }
            });
        }
        else if ("email" === buttonId) {
            this.handleDialogOpen();
        }
        else if ("download" === buttonId) {
            this.download()
        }
    }

    email = () => {

        let data = {
            ProjectID: this.state.projectID,
            Message: this.state.emailMessage,
            EmailAddress: this.state.emailAddress,
            ...this.state.model
        }

        utils.invokeUrl("api/sims/ProjectBeneficiaries/GenerateAndEmailBeneficiaryDetails", (response) => {
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    download = () => {

        let data = {
            ProjectID: this.state.projectID,
            ...this.state.model
        }

        utils.invokeUrl("api/sims/ProjectBeneficiaries/DownloadBeneficiaryDetails", (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ BeneDetails: values });
        }
    }

    handleDialogOpen = () => {
        this.setState({ formDialogOpen: true });
    };

    handleDialogClose = () => {
        this.setState({ formDialogOpen: false });
    };

    handleEmailChange = (event) => {
        this.setState({ emailAddress: event.target.value });
    };

    handleMessageChange = (event) => {
        this.setState({ emailMessage: event.target.value });
    };

    handleSubmit = () => {
        this.setState({ formDialogOpen: false });
        this.email();
    };


    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    Project Beneficiary
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <ProjectBeneficiariesForm ref={beneficiaryRef}
                                            viewRef={this}
                                            data={this.state.model}
                                            valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Dialog open={this.state.formDialogOpen} onClose={this.handleDialogClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Send an email</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            To send an email, please enter the email address and your message here.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="email"
                            label="Email Address"
                            type="email"
                            fullWidth
                            onChange={this.handleEmailChange}
                        />
                        <TextField
                            margin="dense"
                            id="message"
                            label="Message"
                            type="text"
                            fullWidth
                            onChange={this.handleMessageChange}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleSubmit} color="primary">
                            Send
                        </Button>
                    </DialogActions>
                </Dialog>
            </ViewContainer>
        );
    }
}

export default withRouter(ProjectBeneficiaryDetail);
