import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";

const utils = new ViewUtils();

class CustomReportsV2 extends Component {
    state = {
        UniqueID: null,
        CustomReportingToolPath: null
    };

    componentDidMount() {
        let url = "api/sims/customreports/retrieveUniqueID";
        utils.invokeUrl(url, (data) => {
            this.setState({ UniqueID: data.UniqueID, CustomReportingToolPath: data.CustomReportingToolPath });
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError", clearGrid: true });
        });
    }

    render() {

        const URL = window.location.origin
            ? window.location.origin + '/' + this.state.CustomReportingToolPath + '/?UniqueID=' + this.state.UniqueID
            : window.location.protocol + '/' + window.location.host + '/' + this.state.CustomReportingToolPath + '/?UniqueID=' + this.state.UniqueID;

        return (
            <ViewContainer title="">
            <p className="breadcrumbs">
                {"Custom Reports"}
            </p>
                <div style={{ height: '500px', padding: '20px' }}>
                    {this.state.UniqueID !== null && <a href={URL} target="_blank">View Custom Reports</a>}
                </div>
            </ViewContainer>
        )
    }
}

export default withRouter(CustomReportsV2);