import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../../widgets/Toolbar';
import "./../../../../Grid.css";
import "./../../../../Form.css";
import "./../../../../App.css";
import '../Style.css'
import { ArtisanForm } from './ArtisanForm';
import ViewUtils from '../../../../ViewUtils';
import AlertItem from "../../../../widgets/AlertItem";
import Button from '@material-ui/core/Button';
import EnhancedTable from '../../../../widgets/ResponsiveTable';
import { ViewContainer } from "./../../../../View.jsx";
import Prompt from '../../../../widgets/Prompt';


const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to DG Forms', tooltip: '' }
            ]
        }
    ]
};

const formRef = React.createRef();

const headCells = [
    { id: 'Trade', numeric: false, disablePadding: true, label: 'Trade', widthRatio: .055 },
    { id: 'NoOfLearners', numeric: false, disablePadding: true, label: 'Number of Learners', widthRatio: .035 },
    { id: 'WorksiteWorkPlace', numeric: false, disablePadding: true, label: 'Worksite WorkPlace', widthRatio: .035 },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date', widthRatio: .035 },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date', widthRatio: .035 },
    { id: 'Mentor', numeric: false, disablePadding: true, label: 'Mentor', widthRatio: .035 },
    { id: 'TrainingProvider', numeric: false, disablePadding: true, label: 'Training Provider', widthRatio: .035 },
    { id: 'QualificationAddressVacancy', numeric: false, disablePadding: true, label: 'SSP', widthRatio: .035 },
    { id: 'HardToFillVacancyReason', numeric: false, disablePadding: true, label: 'Reason', widthRatio: .035 },
    { id: 'HardToFillVacancyReasonOther', numeric: false, disablePadding: true, label: 'Other (Reason)', widthRatio: .035 },
    { id: 'HardToFillVacancyList', numeric: false, disablePadding: true, label: 'Vacancy', widthRatio: .035 },
    { id: 'Province', numeric: false, disablePadding: true, label: 'Province', widthRatio: .035 },
    { id: 'Municipality', numeric: false, disablePadding: true, label: 'Municipality', widthRatio: .035 },
    { id: 'AfricanMale', numeric: false, disablePadding: true, label: 'African Male', widthRatio: .035 },
    { id: 'AfricanFemale', numeric: false, disablePadding: true, label: 'African Female', widthRatio: .035 },
    { id: 'AfricanDisabled', numeric: false, disablePadding: true, label: 'African Disabled', widthRatio: .035 },
    { id: 'ColouredMale', numeric: false, disablePadding: true, label: 'Coloured Male', widthRatio: .035 },
    { id: 'ColouredFemale', numeric: false, disablePadding: true, label: 'Coloured Female', widthRatio: .035 },
    { id: 'ColouredDisabled', numeric: false, disablePadding: true, label: 'Coloured Disabled', widthRatio: .035 },
    { id: 'IndianMale', numeric: false, disablePadding: true, label: 'Indian Male', widthRatio: .035 },
    { id: 'IndianFemale', numeric: false, disablePadding: true, label: 'Indian Female', widthRatio: .035 },
    { id: 'IndianDisabled', numeric: false, disablePadding: true, label: 'Indian Disabled', widthRatio: .035 },
    { id: 'WhiteMale', numeric: false, disablePadding: true, label: 'White Male', widthRatio: .035 },
    { id: 'WhiteFemale', numeric: false, disablePadding: true, label: 'White Female', widthRatio: .035 },
    { id: 'WhiteDisabled', numeric: false, disablePadding: true, label: 'White Disabled', widthRatio: .035 },
    { id: 'TotalMale', numeric: false, disablePadding: true, label: 'Total Male', widthRatio: .035 },
    { id: 'TotalFemale', numeric: false, disablePadding: true, label: 'Total Female', widthRatio: .035 },
    { id: 'TotalDisabled', numeric: false, disablePadding: true, label: 'Total Disabled', widthRatio: .035 }

];

class Artisan extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        DGApplicationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.DGApplicationID)) ? this.props.location.state.DGApplicationID : null,
        DGFormID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.DGFormID)) ? this.props.location.state.DGFormID : null,
        DGFormName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.DGFormName)) ? this.props.location.state.DGFormName : null,
        RecordID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RecordID)) ? this.props.location.state.RecordID : null,
        FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null,
        OFOCode: null,
        open: null,
        searchParameters: [{ Name: "DGApplicationID", Value: this.props.location.state.DGApplicationID }, { Name: "DGFormID", Value: this.props.location.state.DGFormID }],
        adddisabled: false,
        removedisabled: true,
        removealldisabled: true,
        editdisabled: true,
        updatedisabled: true,
        canceldisabled: true,
        EditFormData: null,
        clearSelectedRow: false,
        promptOpen: false,
        promptTitle: "",
        promptContent: "",
        removeAll: false,
        formPermission: "Enabled",
        addDefaultObject: {
            AfricanMale: 0,
            AfricanFemale: 0,
            AfricanDisabled: 0,
            ColouredMale: 0,
            ColouredFemale: 0,
            ColouredDisabled: 0,
            IndianMale: 0,
            IndianFemale: 0,
            IndianDisabled: 0,
            WhiteMale: 0,
            WhiteFemale: 0,
            WhiteDisabled: 0
        }
    };

    add = () => {

        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            const RaceGender = this.CheckRaceGenderRules(formValue);

            if (RaceGender) {
                formValue.DGApplicationID = this.state.DGApplicationID;
                formValue.DGFormID = this.state.DGFormID;

                const url = `api/sims/DGApplicationForms2022/InsertArtisanEU`;
                utils.saveForm(this, formRef, url, (response) => {

                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
            }
        }
    }

    CheckRaceGenderRules = (formValue) => {
        let RaceGender = true;
        let ErrorMessage = "";

        if (parseInt(formValue.AfricanMale) + parseInt(formValue.AfricanFemale) +
            parseInt(formValue.ColouredMale) + parseInt(formValue.ColouredFemale) +
            parseInt(formValue.IndianMale) + parseInt(formValue.IndianFemale) +
            parseInt(formValue.WhiteMale) + parseInt(formValue.WhiteFemale) === 0) {
            ErrorMessage = "Race and Gender Breakdown: Cannot be zero.";
            RaceGender = false;
        }
        if ((parseInt(formValue.AfricanMale) < 0) || (parseInt(formValue.AfricanFemale) < 0) || (parseInt(formValue.AfricanDisabled) < 0) ||
            (parseInt(formValue.ColouredMale) < 0) || (parseInt(formValue.ColouredFemale) < 0) || (parseInt(formValue.ColouredDisabled) < 0) ||
            (parseInt(formValue.IndianMale) < 0) || (parseInt(formValue.IndianFemale) < 0) || (parseInt(formValue.IndianDisabled) < 0) ||
            (parseInt(formValue.WhiteMale) < 0) || (parseInt(formValue.WhiteFemale) < 0) || (parseInt(formValue.WhiteDisabled) < 0)) {
            ErrorMessage = "Race and Gender Breakdown: Negative numbers are not allowed.";
            RaceGender = false;
        }

        if ((parseInt(formValue.AfricanMale) + parseInt(formValue.AfricanFemale)) < parseInt(formValue.AfricanDisabled) ||
            (parseInt(formValue.ColouredMale) + parseInt(formValue.ColouredFemale)) < parseInt(formValue.ColouredDisabled) ||
            (parseInt(formValue.IndianMale) + parseInt(formValue.IndianFemale)) < parseInt(formValue.IndianDisabled) ||
            (parseInt(formValue.WhiteMale) + parseInt(formValue.WhiteFemale)) < parseInt(formValue.WhiteDisabled)) {
            ErrorMessage = "Race and Gender Breakdown: Total count of disabled records may not be greater than male plus female.";
            RaceGender = false;
        }

        if ((parseInt(formValue.AfricanMale) + parseInt(formValue.AfricanFemale) +
            parseInt(formValue.ColouredMale) + parseInt(formValue.ColouredFemale) +
            parseInt(formValue.IndianMale) + parseInt(formValue.IndianFemale) +
            parseInt(formValue.WhiteMale) + parseInt(formValue.WhiteFemale)) !=
            parseInt(formValue.NoOfLearners)) {
            ErrorMessage = "Race and Gender Breakdown: Total count of males plus females does not equal the total number of learners.";
            RaceGender = false;
        }

        if (!RaceGender) { this.setState({ message: ErrorMessage, messageStyle: "warning" }) }

        return RaceGender;
    }

    edit = () => {

        utils.invokeUrl("api/sims/DGApplicationForms2022/GetArtisanEUFind?DGArtisanEUID=" + this.state.RecordID + "&DGFormID=" + this.state.DGFormID, (response) => {
            response.DGApplicationID = this.state.DGApplicationID;
            response.DGFormID = this.state.DGFormID;
            this.setState({
                RecordID: JSON.parse(JSON.stringify(this.state.RecordID)),
                adddisabled: true,
                removedisabled: true,
                removealldisabled: true,
                editdisabled: true,
                updatedisabled: false,
                canceldisabled: false,
                EditFormData: response,
                message: "",
                messageStyle: ""
            });

        }, this.error);
    }

    remove = () => {
        this.setState({
            adddisabled: true,
            removedisabled: false,
            editdisabled: false,
            updatedisabled: true,
            canceldisabled: false,
            promptOpen: true,
            removeAll: false,
            promptTitle: "Remove DG Form Record",
            promptContent: "Are you sure you want to Remove this DG Form Record?",
            message: "",
            messageStyle: ""
        });
    }

    removeAll = () => {
        this.setState({
            adddisabled: false,
            removedisabled: true,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            promptOpen: true,
            removeAll: true,
            promptTitle: "Remove ALL DG Form Record",
            promptContent: "Are you sure you want to Remove ALL records from this DG Form?",
            message: "",
            messageStyle: ""
        });
    }

    cancel = () => {
        this.setState({
            adddisabled: false,
            removedisabled: true,
            removealldisabled: false,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            message: "",
            messageStyle: ""
        });
    }

    update = () => {

        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            const RaceGender = this.CheckRaceGenderRules(formValue);

            if (RaceGender) {

                formValue.DGApplicationID = this.state.DGApplicationID;
                formValue.DGFormID = this.state.DGFormID;
                formValue.ID = this.state.RecordID;

                const url = `api/sims/DGApplicationForms2022/updateArtisanEU`;
                utils.updateForm(this, formRef, url, (response) => {
                    //this.setState({ message: "Letter updated successfully", messageStyle: "SUCCESS" });

                    utils.showMessage(this, response.Message, response.MessageType);
                    if (response.MessageType === "SUCCESS") {
                        this.successCallback(response);
                    } else {
                        this.setState({
                            message: response.Message,
                            messageStyle: "WARNING"
                        })
                    }
                }, this.error);
            }
        }
    }

    removeRecord() {
        this.setState({ promptOpen: false })

        let url = "api/sims/DGApplicationForms2022/removeArtisanEU";
        let fullUrl = `${url}?DGApplicationID=${this.state.DGApplicationID}&DGFormID=${this.state.DGFormID}&DGArtisanEUID=${this.state.RecordID}`;

        if (this.state.removeAll === true) {
            url = "api/sims/DGApplicationForms2022/removeAllArtisanEU";
            fullUrl = `${url}?DGApplicationID=${this.state.DGApplicationID}&DGFormID=${this.state.DGFormID}`;
        }

        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                adddisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                clearSelectedRow: true,
                EditFormData: null,
                RecordID: null, message: response.Message, messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ message: response.Message, messageStyle: response.MessageType.toLowerCase() })
        }
    }

    closePrompt = () => {
        this.setState({
            adddisabled: false,
            removedisabled: true,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            promptOpen: false
        })
    }

    successCallback(response) {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                addDefaultObject: JSON.parse(JSON.stringify({
                    AfricanMale: 0,
                    AfricanFemale: 0,
                    AfricanDisabled: 0,
                    ColouredMale: 0,
                    ColouredFemale: 0,
                    ColouredDisabled: 0,
                    IndianMale: 0,
                    IndianFemale: 0,
                    IndianDisabled: 0,
                    WhiteMale: 0,
                    WhiteFemale: 0,
                    WhiteDisabled: 0
                })),
                message: response.Message, messageStyle: response.MessageType,
                adddisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                clearSelectedRow: true,
                EditFormData: null
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    onChangeProvinceIDHandler = (form, id, event) => {
        let newModel = { ...form.values }
        newModel.MunicipalityID = null;
        if (this.state.EditFormData === null) {
            this.setState({ addDefaultObject: newModel, clearOptions: true })
        }
        else {
            this.setState({ EditFormData: newModel, clearOptions: true })
        }
    }

    clearOptionsHandler = () => {
        this.setState({ clearOptions: false });
    }

    handleFormValueChange(values) {
        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleFormValueChangeAdd(values) {

        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {

            this.setState({
                RecordID: selection[0].Id, Message: "",
                adddisabled: true,
                removedisabled: false,
                editdisabled: false,
                updatedisabled: true,
                canceldisabled: false,
                clearSelectedRow: false,
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                Message: "",
                adddisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                clearSelectedRow: true
            });
        }

        if (this.state.formPermission !== "Enabled") {
            this.setState({
                adddisabled: true,
                removedisabled: true,
                removealldisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true
            })
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }


    close() {
        this.props.history.push({
            pathname: '/dgForms',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber,
                DGID: this.state.DGApplicationID,
                FinancialYearID: this.state.FinancialYearID
            }
        });
    }

    dataArrivedHandler = (values) => {
        console.log(values);
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }

    componentDidMount() {

        this.getFormHead()
        this.GetWSPATRFormPermissionCheck()
    }

    getFormHead() {
        let url = "api/sims/dgapplication/GetFindHeader?DGApplicationID=" + this.state.DGApplicationID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    GetWSPATRFormPermissionCheck() {
        let url = "api/sims/dgapplication/GetDGFormPermissionCheck?DGApplicationID=" + this.state.DGApplicationID + "&DGFormsID=" + this.state.DGFormID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "Enabled") {
                this.setState({
                    adddisabled: true,
                    removedisabled: true,
                    removealldisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    formPermission: response.Result
                })
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    onDataArrive = (results) => {
        if (results.TotalNumberOfRows === 0)
        {
            this.setState({
                removealldisabled: true
            });
        }

        else if (results.TotalNumberOfRows > 0 && this.state.formPermission === "Enabled")
        {
            this.setState({
                removealldisabled: false
            });
        }
        
    }

    render() {

        let CaptureFormEdit = <ArtisanForm
            ref={formRef}
            DGApplicationID={this.state.DGApplicationID}
            valueChangeHandler={(values) => this.handleFormValueChange(values)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)}
            data={this.state.EditFormData}
            onChangeProvinceIDHandler={this.onChangeProvinceIDHandler}
            clearOptions={this.state.clearOptions}
            clearOptionsHandler={this.clearOptionsHandler}
        />;

        let CaptureFormAdd = <ArtisanForm
            ref={formRef}
            DGApplicationID={this.state.DGApplicationID}
            valueChangeHandler={(values) => this.handleFormValueChangeAdd(values)}
            data={this.state.addDefaultObject}
            onChangeProvinceIDHandler={this.onChangeProvinceIDHandler}
            clearOptions={this.state.clearOptions}
            clearOptionsHandler={this.clearOptionsHandler}
        />;

        if (this.state.EditFormData === null) {
            CaptureFormEdit = null;
        }
        else {
            CaptureFormAdd = null;
        }
        if (this.state.formPermission !== "Enabled") { CaptureFormAdd = null; CaptureFormEdit = null; }

        return (
            <ViewContainer mode={this.props.mode} title="">
                <div className="view w-100 h-100">
                    <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <br />
                    <table width="100%" class="FormsTableHeader">
                        <tbody>
                            <tr>
                                <td>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                                </td>
                                <td align="center">
                                    <b>{this.state.DGFormName}</b><br />
                                    <b>{this.state.FinancialYear}</b>
                                </td>
                                <td align="right">
                                    <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td colSpan="2" className="formContainer">
                                    {CaptureFormAdd}
                                    {CaptureFormEdit}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.adddisabled} onClick={this.add}>Add</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.removedisabled} onClick={this.remove}>Remove</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.removealldisabled} onClick={this.removeAll}>Remove All</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnEdit" color="#01579B" hidden={this.state.editdisabled} onClick={this.edit}>Edit</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnUpdate" color="#01579B" hidden={this.state.updatedisabled} onClick={this.update}>Update</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnCancel" color="#01579B" hidden={this.state.canceldisabled} onClick={this.cancel}>Cancel</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                    <br />
                    <div className="searchResults">
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/DGApplicationForms2022/GetSearchArtisanEU`}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            onDataArrive={(results) => { this.onDataArrive(results) }}
                            makeRowBold={true}
                            searchParameters={this.state.searchParameters} paged={true} clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}  />
                    </div>
                </div>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => {this.removeRecord() }}
                    closePrompt={this.closePrompt}
                /> 
            </ViewContainer>
        );
    }
}

export default withRouter(Artisan);