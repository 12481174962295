import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import ExperientialLearningDialog from "./ExperientialLearningDialog";
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import TabPanel from '../../widgets/TabPanel';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
const InterSetaTransferEditForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [experientFileName, setExperientFileName] = React.useState('');
    const [ELdocumentsDialogOpen, setELdocumentsDialogOpen] = React.useState(false);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const [open, setOpen] = React.useState(props.open);
    const [filename, setFilename] = React.useState("");
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [entityId, setEntityId] = React.useState(props.EntityID);

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }



    
    return (
        <EntityForm ref={ref}
            dataURL='api/sims/InterSetaTransfer/getfind'
            values={props.data}
            entityLookupId={props.id}
            entityLookupController={props.entityLookupController} org="saseta"
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="InterSetaTransferform">

                    <div className="row w-100">
                        <div className="col w-100">
                            <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                                <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                    <Tab label="Inter Seta Transfer Details" {...a11yProps(0)} />
                                </Tabs>
                            </AppBar>
                            <TabPanel value={tabValue} index={0} className='providerAccreditationDetailsPanel'>
                                <table cellPadding="2" className="w-100">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl={'api/sims/InterSetaTransfer/GetISTTransferTypeByRole?parameter=[{"Name":"ID","Value":' + props.id + '}]'}
                                                    id="ISTTransferTypeID"
                                                    key={tabValue}
                                                    label="IST Transfer Type"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />

                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/InterSetaTransfer/GetLookupList?listId=ISTTransferStatus"
                                                    id="ISTTransferStatusID"
                                                    key={tabValue}
                                                    label="IS Transfer Status"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomDatePicker
                                                    id="DateApplicationSubmitted"
                                                    key={tabValue}
                                                    label="Date Application Submitted"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <CustomDatePicker
                                                    id="DateOrganisationSignedIST"
                                                    key={tabValue}
                                                    label="Date IST Signed by 
                                                    Organisation"
                                                    className="w-100"
                                                    form={form}

                                                />
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/InterSetaTransfer/GetLookupList?listId=Title"
                                                    id="RequestingPersonTitleID"
                                                    key={tabValue}
                                                    label="Requestion Person Title"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="RequestingPersonName"
                                                    key={tabValue}
                                                    label="Requesting Person Name"
                                                    className="w-100"
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <CustomTextField
                                                    id="RequestingPersonSurname"
                                                    key={tabValue}
                                                    label="Requesting Person Surname"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="RequestingPersonCapacity"
                                                    key={tabValue}
                                                    label=" Requesting Person Capacity"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="FaxNumber"
                                                    key={tabValue}
                                                    label="Fax Number"
                                                    validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                    className="w-100"
                                                    form={form}
                                                    required
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="EMail"
                                                    key={tabValue}
                                                    label="E Mail"
                                                    validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                    className='w-100'
                                                    form={form}

                                                />
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <CustomTextField
                                                    id="TelephoneNumber"
                                                    key={tabValue}
                                                    label="Telephone Number"
                                                    className='w-100'
                                                    validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/InterSetaTransfer/GetLookupList?listId=SETA"
                                                    id="CurrentSetaID"
                                                    key={tabValue}
                                                    label="Current SETA"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/InterSetaTransfer/GetLookupList?listId=SETA"
                                                    id="NewSetaID"
                                                    key={tabValue}
                                                    label="SETA to move to"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/InterSetaTransfer/GetLookupList?listId=ISTChangeReason"
                                                    id="ChangeReasonID"
                                                    key={tabValue}
                                                    label="Reason for Change"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>

                                            <td>
                                                <CustomTextField
                                                    id="TransferMotivation"
                                                    key={tabValue}
                                                    label="Motivation for Transfer"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <SelectItem
                                                    dataUrl="api/sims/InterSetaTransfer/GetLookupList?listId=SICCode"
                                                    id="NewSICCodeID"
                                                    key={tabValue}
                                                    label="New SIC Code"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomDatePicker
                                                    id="DateAdvisementLetterSent"
                                                    key={tabValue}
                                                    label="Date letter sent to 
                                               advise company to 
                                               contact new SETA"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="FollowUpComments"
                                                    key={tabValue}
                                                    label="Comments for Follow 
                                               up"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomDatePicker
                                                    id="DateSentToDoL"
                                                    key={tabValue}
                                                    label="Date sent to DHET by 
                                               other SETA"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                            <td>
                                                <CustomDatePicker
                                                    id="DateCheckHugeFileISTIN"
                                                    key={tabValue}
                                                    label="Date Check Huge File 
                                               for IST In"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <CustomCheckbox
                                                    id="ISTSuccessfulPosted"
                                                    key={tabValue}
                                                    label="IST Successful Posted"
                                                    className='w-100'
                                                    form={form}
                                                />


                                            </td>

                                            <td>
                                                <CustomDatePicker
                                                    id="DateISTSuccessfullyProcessed"
                                                    key={tabValue}
                                                    label="Date IST Successfully Processed"
                                                    className='w-100'
                                                    required
                                                    form={form}
                                                />

                                            </td>
                                        </tr>


                                    </tbody>
                                </table>

                            </TabPanel>

                        </div>

                    </div>

                </form>
            }
        </EntityForm>
    );
})

export default InterSetaTransferEditForm;