import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom'
import { Button } from "react-bootstrap";
import * as actions from '../../store/actions/auth';
import "./EmployerSelect.css";
import { withAlert } from 'react-alert';
import LoadingIndicatorLogin from '../LoadingIndicatorLogin';
import ViewUtils from "../ViewUtils";

//const handler = (state) => {
//    return {
//        userRoles: state.security.userRoles,
//        userDetails: state.security.userDetails,
//        isLoading: state.security.isLoading,
//        roleSet: state.security.roleSet,
//        error: state.security.error
//    };
//};

const utils = new ViewUtils();

class EmployerSelect extends Component {

    state = {
        selectedEmployer: "-1",
        message: "",
        loading: true
    };

    showAlert(msg, msgType) {
        this.props.alert.show(msg, {
            timeout: 3000, // custom timeout just for this one alert
            type: msgType,
            onOpen: () => {
                //console.log('open')
            }, // callback that will be executed after this alert open
            onClose: () => {
                //console.log('closed')
            } // callback that will be executed after this alert is removed
        })
    }

    //validateForm() {
    //    return this.state.selectedRole !== null;
    //}

    componentDidMount() {
    }

    componentDidUpdate(prevProps) {
        if (prevProps.roleSet !== this.props.roleSet && this.props.roleSet) {
            clearTimeout(this.props.userLoginTimer);
            this.props.onGetMenuPermissions();
            //this.props.history.push('/home');
        }

        if (prevProps.menuPermissions !== this.props.menuPermissions && this.props.menuPermissions) {
            //this.props.history.push('/home');
            this.props.history.push(this.props.dashboardURL);
        }

        if (prevProps.loginRedirectToLogin !== this.props.loginRedirectToLogin && this.props.loginRedirectToLogin) {
            //this.props.history.push('/login');
            this.props.history.push(this.props.loginRedirectToLoginPath);
        }

        if (prevProps.loginErrorMessage !== this.props.loginErrorMessage && this.props.loginErrorMessage) {
            this.showAlert(this.props.loginErrorMessage, 'error');
        }

        if (prevProps.loginMessage !== this.props.loginMessage && this.props.loginMessage) {
            this.showAlert(this.props.loginMessage, 'error');
        }
    }

    back() {
        clearTimeout(this.props.userLoginTimer);
        this.props.history.push('/role');
    }

    select() {
        this.setState({ message: "" });
        this.props.onSelectEmployer(this.props.userDetails.UserKey, this.props.userDetails.RoleKey, this.state.selectedEmployer);
    }

    render() {
        return (
            <div className="role-container">
                <div className="RoleSelect">
                    <p className="errorMessage">{this.state.message}</p>
                    <p>Select a Employer</p>
                    <form className="style-2" id="scroll">
                        {this.props.userEmployers.map(userEmployer => (
                            <div className="form-check" key={userEmployer.Id}>
                                <label>
                                    <input type="radio"
                                        value={userEmployer.Id}
                                        checked={this.state.selectedEmployer === userEmployer.Id.toString()}
                                        onChange={(ev) => this.setState({ selectedEmployer: ev.target.value })}
                                    />
                                    <span className="label-text"> {userEmployer.Name} </span>
                                </label>
                            </div>
                        ))}
                    </form>
                    <table>
                        <tbody>
                            <tr>
                                <td>
                                    <Button
                                        block
                                        onClick={this.back.bind(this)}
                                        type="button"
                                    >
                                        BACK
                                    </Button>
                                </td>
                                <td>
                                    <Button
                                        block
                                        onClick={this.select.bind(this)}
                                        className="role-btn"
                                        disabled={this.state.selectedEmployer === "-1" || this.props.isLoading}
                                        type="button"
                                    >
                                        NEXT
                                    </Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br />
                </div>
                <LoadingIndicatorLogin show={this.props.isLoading} />
            </div>
        );
    }
}

//export default withRouter(
//    connect(
//        handler,
//        dispatch => bindActionCreators(actionCreators, dispatch)
//    )(RoleSelect)
//);

const mapStateToProps = state => {
    return {
        isLoading: state.auth.isLoading,
        userDetails: state.auth.userDetails,
        authenticated: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Authenticated : false,
        otpSent: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.OTPSent : false,
        loginMessage: !utils.isNull(state.auth.userDetails) ? state.auth.userDetails.Message : null,
        userEmployers: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Employers) ? state.auth.userDetails.Employers : [] : [],
        loginErrorMessage: state.auth.errorMessage,
        loginRedirectToLogin: state.auth.redirectToLogin,
        userLoginTimer: state.auth.userLoginTimer,
        roleSet: !utils.isNull(state.auth.userDetails) ? !utils.isNull(state.auth.userDetails.Token) ? true : false : false,
        menuPermissions: !utils.isNull(state.auth.menuPermissionsDetails) ? true : false,
        loginRedirectToLoginPath: state.auth.loginRedirectToLoginPath,
        dashboardURL: !utils.isNull(state.auth.menuPermissionsDetails) ? state.auth.menuPermissionsDetails.DashboardInfo.DashboardURL : null
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onSelectEmployer: (UserKey, RoleKey, EmployerKey) => dispatch(actions.authEmployerSelect(UserKey, RoleKey, EmployerKey)),
        onGetMenuPermissions: () => dispatch(actions.authMenuPermission())
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withAlert()(EmployerSelect)));
