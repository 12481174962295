import React from 'react';
import { CustomTextField } from '../widgets/CustomTextField';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';
import { CustomDatePicker } from '../widgets/CustomDatePicker';
import TabPanel, { FormTabContainer } from '../widgets/TabPanel';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const ProjectDetailsForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="projectdetails" values={props.data} dataURL='api/sims/projects/GetFind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProjectDetailsForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Project Details" wrapped {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel className="w-100" value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ProjectID"
                                                key={tabValue}
                                                label="Project ID"
                                                className="w-100"
                                                form={form}
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ProjectName"
                                                key={tabValue}
                                                label="Project Name"
                                                className="w-100"
                                                form={form}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="ProjectStartDate"
                                                key={tabValue}
                                                label="Project Start Date"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="ProjectEndDate"
                                                key={tabValue}
                                                label="Project End Date"
                                                className='{classes.textField} w-100'
                                                form={form}
                                                disabled
                                            />
                                        </td>
                                    </tr>
                                    {props.PType !== "OTHER" &&
                                        <tr>
                                            <td>
                                                <CustomDatePicker
                                                    id="ProjectExtendedDate"
                                                    key={tabValue}
                                                    label="Project Extended Date"
                                                    className='{classes.textField} w-100'
                                                    form={form}
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="ProgrammeNumber"
                                                    key={tabValue}
                                                    label="Programme Number"
                                                    className="w-100"
                                                    form={form}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                    }
                                    {props.PType !== "OTHER" &&
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id="ProgrammeStructure"
                                                    key={tabValue}
                                                    label="Programme Structure"
                                                    className="w-100"
                                                    form={form}
                                                    disabled
                                                />
                                            </td>
                                            <td>
                                                <CustomTextField
                                                    id="Target"
                                                    key={tabValue}
                                                    label="Target"
                                                    className="w-100"
                                                    form={form}
                                                    disabled
                                                />
                                            </td>
                                        </tr>
                                    }
                                    <tr>
                                        <td>
                                            {props.PType !== "OTHER" ? (
                                                <CustomTextField
                                                    id="Achievement"
                                                    key={tabValue}
                                                    label="Achievement"
                                                    className="w-100"
                                                    form={form}
                                                    disabled
                                                />
                                            ) : (
                                                <CustomDatePicker
                                                    id="ProjectExtendedDate"
                                                    key={tabValue}
                                                    label="Project Extended Date"
                                                    className='{classes.textField} w-100'
                                                    form={form}
                                                    disabled
                                                />
                                            )}
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ProjectManager"
                                                key={tabValue}
                                                label="Project Manager"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ProjectTeam"
                                                key={tabValue}
                                                label="Project Team"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ProjectDescription"
                                                key={tabValue}
                                                label="Project Description"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ProjectScope"
                                                key={tabValue}
                                                label="Project Scope"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})

