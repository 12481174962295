import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import ViewUtils from "./../../ViewUtils";
import { ViewContainer } from "./../../View.jsx";
import Button from "@material-ui/core/Button";
import AlertItem from "../../widgets/AlertItem";


const utils = new ViewUtils();
//var RoleID = !utils.isNull(this.props.RoleID) ? this.props.RoleID : 0;
var RoleID = 0;


class HelpDeskReport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchParameters: [{ Name: "RoleID", Value: null },
            { Name: "ExportToExcel", Value: null }]
        }
    }



    handleExecuteReport = () => {
        let searchParameters = [];

        searchParameters.push({ Name: "RoleID", Value: this.RoleID })
        searchParameters.push({ Name: "ExportToExcel", Value: "Yes" })


        utils.invokeUrl("api/sims/HelpDeskReports/GetHelpDeskReportExcel?request=" + utils.doGetRequestJson(0, 0, searchParameters, true), (data) => {
            var result;
            if (typeof data === "string") {
                result = JSON.parse(data);
            } else {
                result = data;
            }

            var blob = new Blob([this.base64ToArrayBuffer(result.Base64)], { type: result.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = result.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ message: "Unable to execute report. A system error has occurred. Please contact your system administrator", messageStyle: "ERROR" });
        });
    }



    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };


    render() {
        
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    Help Desk Report
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>                        
                        <tr>
                            <td style={{ paddingTop: "10px" }}>
                                <Button size="small" color="primary" onClick={this.handleExecuteReport}>
                                    Execute Report
                                </Button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(HelpDeskReport);
