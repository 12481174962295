import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../../View.css";
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import "./Survey.css";
import SearchView from "../../widgets/SearchView";
import SurveySearchForm from './SurveySearchForm';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";
import { NotificationForm } from './NotificationForm';
import FormDialog from "../FormDialog";
import AlertItem from "../../widgets/AlertItem";


const toolbarRef = React.createRef();
const utils = new ViewUtils();
const notificationFormRef = React.createRef();

const headCells = [
    { id: 'SurveyNumber', numeric: false, disablePadding: true, label: 'Survey Number' },
    { id: 'SurveyTitle', numeric: false, disablePadding: true, label: 'Survey Title' },
    { id: 'NumberOfQuestions', numeric: false, disablePadding: true, label: 'Number of Questions' },
    { id: 'DateCreated', numeric: false, disablePadding: true, label: 'Date Created' },
    { id: 'CreatedBy', numeric: false, disablePadding: true, label: 'Created By' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' },
    { id: 'NumberOfSurveysSent', numeric: false, disablePadding: true, label: 'Number of Surveys sent' },
    { id: 'NumberOfResponses', numeric: false, disablePadding: true, label: 'Number of Responses' },
    { id: 'SurveyDueDate', numeric: false, disablePadding: true, label: 'Due Date' }
];

const filterParameters = [
    { dataField: "SurveyNumber", text: "SurveyNumber" },
    { dataField: "SurveyStatusID", text: "SurveyStatusID" }
]

class SurveySearch extends Component {

    constructor(props) {
        super(props)


        this.state = {
            model: {
                SurveyNumber: "",
                SurveyStatusID: 0
            },
            notificationmodel: {
                StakeholderEmails: ""
            },
            keyUpSwitch: false,
            selectedSurvey: null,
            notifyDialogOpen: false,
            surveyData: null,
            SurveyTitle: "",
            SurveyDueDate: "",
            Message: "",
            MessageStyle: ""
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', disabled: false, visible: this.props.menuPermissionItem.Add },
                        { id: 'editSurvey', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove Survey", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete },
                        { id: "view", label: "", tooltip: "Preview Survey", disabled: false },
                        { id: "notify", label: "NOTIFY", tooltip: "Send Out Survey Notifications", disabled: true },
                        { id: "data", label: "DATA", tooltip: "Download Survey Data", disabled: true },
                        { id: "duplicate", label: "DUPLICATE", tooltip: "Duplicate a Survey", disabled: true },
                        { id: 'downloadtemplate', label: 'DOWNLOAD TEMPLATE', tooltip: 'Download the bulk email template format to import', disabled: false },
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedSurvey: selection[0].Id, SurveyTitle: selection[0].SurveyTitle, SurveyDueDate: selection[0].SurveyDueDate })

            const currentDate = new Date();
            const surveyDueDate = new Date(Date.parse(selection[0].SurveyDueDate));

            if (selection[0].Status == "Approved") {
                toolbarRef.current.setButtonDisabled("editSurvey", true);
                toolbarRef.current.setButtonDisabled("notify", surveyDueDate < currentDate);
                toolbarRef.current.setButtonDisabled("data", false);
                toolbarRef.current.setButtonDisabled("duplicate", false);
            }
            else {
                toolbarRef.current.setButtonDisabled("editSurvey", false);
                toolbarRef.current.setButtonDisabled("notify", true);
                toolbarRef.current.setButtonDisabled("data", true);
            }
        } else {
            this.setState({ selectedSurvey: null, SurveyTitle: null, SurveyDueDate: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/AddSurvey',
                state: {
                    selection: null
                }
            })
        } else if (buttonId === "editSurvey") {
            this.props.history.push({
                pathname: '/AddSurvey',
                state: {
                    selection: this.state.selectedSurvey
                }
            })
        } else if (buttonId === "view") {
            if (!utils.isNull(this.state.selectedSurvey)) {
                this.fetchSurveyData(this.state.selectedSurvey)
            }
        } else if (buttonId === "notify") {
            if (!utils.isNull(this.state.selectedSurvey)) {
                this.setState({ notifyDialogOpen: true, message: "" })
            }
        } else if (buttonId === "data") {
            if (!utils.isNull(this.state.selectedSurvey)) {
                this.getSurveyDataReport()
            }
        } else if (buttonId === "duplicate") {
            if (!utils.isNull(this.state.selectedSurvey)) {
                this.duplicateSurveyConfiguration()
            }
        } else if (buttonId === "downloadtemplate") {
            this.downloadTemplate()
        }
    }

    downloadTemplate() {
        utils.invokeUrl("api/sims/survey/DownloadEmailImportTemplate", (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }
        );
    }

    duplicateSurveyConfiguration() {
        let url = "api/sims/survey/DuplicateSurveyConfiguration?SurveyID=" + this.state.selectedSurvey;
        utils.invokeUrl(url, (response) => {
            this.setState({
                Message: response.Message,
                MessageStyle: "success"
            });
        },
            (response) => {
                this.setState({
                    Message: response.Message,
                    MessageStyle: "warning"
                });
            },
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
        toolbarRef.current.setButtonDisabled("generate", true);
    }

    fetchSurveyData(SurveyID) {
        let url = "api/sims/survey/GetSurveyData?SurveyID=" + SurveyID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                surveyData: response
            })
            this.props.history.push({
                pathname: '/ViewSurvey',
                state: {
                    selection: this.state.selectedSurvey,
                    surveyData: this.state.surveyData,
                    surveyTitle: this.state.SurveyTitle,
                    viaDashboard: false
                }
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )


    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                notifyDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                notifyDialogOpen: false
            })
        }
    }

    getSurveyDataReport = () => {

        let searchParameters = [];
        searchParameters.push({ Name: "SurveyID", Value: this.state.selectedSurvey }) 
        searchParameters.push({ Name: "SurveyTitle", Value: this.state.SurveyTitle })

        utils.invokeUrl("api/sims/survey/GetSurveyDataReportExcel?request=" + utils.doGetRequestJson(0, 0, searchParameters, true), (data) => {
            var result;
            if (typeof data === "string") {
                result = JSON.parse(data);
            } else {
                result = data;
            }

            var blob = new Blob([this.base64ToArrayBuffer(result.Base64)], { type: result.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = result.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ message: "Unable to execute report. A system error has occurred. Please contact your system administrator", messageStyle: "ERROR" });
        });
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {
        return (
            <ViewContainer title="">
                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/Survey/GetSurveyApplications"}
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig} searchParameters={this.searchParameters}
                    title='Survey Dashboard'
                    handleObject={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange} descriptionProp="SurveyNumber"
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} deleteUrl={"api/sims/survey/RemoveSurvey"} useDeletePost={true}
                    promptTitle={"Remove Survey"} promptContent={"Are you sure you want to remove this survey?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <SurveySearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }} />
                </SearchView>

                <FormDialog
                    open={this.state.notifyDialogOpen}
                    dialogTitle={"Send Out Survey Notifications"}
                    viewRef={this}
                    entityId={this.state.selectedSurvey}
                    formRef={notificationFormRef}
                    entityInfo={{ SurveyTitle: this.state.SurveyTitle, SurveyDueDate: this.state.SurveyDueDate }}
                    usePost={true}
                    saveUrl={"api/sims/survey/SendSurveyNotifications"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <NotificationForm ref={notificationFormRef} id={this.state.selectedSurvey} controllerName='survey' model={this.state.notificationmodel}
                        data={this.state.notificationmodel}  />
                </FormDialog>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(SurveySearch));

