import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../../widgets/Toolbar';
import "./../../../../Grid.css";
import "./../../../../Form.css";
import "./../../../../App.css";
import '../../Style.css'
import { PlannedBeneficiariesTrainingForm } from './PlannedBeneficiariesTrainingForm';
import ViewUtils from '../../../../ViewUtils';
import AlertItem from "../../../../widgets/AlertItem";
import Button from '@material-ui/core/Button';
import EnhancedTable from '../../../../widgets/ResponsiveTable';
import { ViewContainer } from "../../../../View.jsx";
import Prompt from '../../../../widgets/Prompt';
import BulkImportDialog from '../../Controls/WSPATRBulkImportDialog';


const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Forms', tooltip: '' }
            ]
        }
    ]
};

const formRef = React.createRef();

const headCells = [
    { id: 'OccupationalCategory', numeric: false, disablePadding: true, label: 'Occupational Category', widthRatio: .061 },
    { id: 'Occupation', numeric: false, disablePadding: true, label: 'Occupation', widthRatio: .154 },
    { id: 'Municipality', numeric: false, disablePadding: true, label: 'Occupation', widthRatio: .080 },
    { id: 'AfricanMale', numeric: false, disablePadding: true, label: 'African Male', widthRatio: .047 },
    { id: 'AfricanFemale', numeric: false, disablePadding: true, label: 'African Female', widthRatio: .047 },
    { id: 'AfricanDisabled', numeric: false, disablePadding: true, label: 'African Disabled', widthRatio: .047 },
    { id: 'ColouredMale', numeric: false, disablePadding: true, label: 'Coloured Male', widthRatio: .047 },
    { id: 'ColouredFemale', numeric: false, disablePadding: true, label: 'Coloured Female', widthRatio: .047 },
    { id: 'ColouredDisabled', numeric: false, disablePadding: true, label: 'Coloured Disabled', widthRatio: .047 },
    { id: 'IndianMale', numeric: false, disablePadding: true, label: 'Indian Male', widthRatio: .047 },
    { id: 'IndianFemale', numeric: false, disablePadding: true, label: 'Indian Female', widthRatio: .047 },
    { id: 'IndianDisabled', numeric: false, disablePadding: true, label: 'Indian Disabled', widthRatio: .047 },
    { id: 'WhiteMale', numeric: false, disablePadding: true, label: 'White Male', widthRatio: .047 },
    { id: 'WhiteFemale', numeric: false, disablePadding: true, label: 'White Female', widthRatio: .047 },
    { id: 'WhiteDisabled', numeric: false, disablePadding: true, label: 'White Disabled', widthRatio: .047 },
    { id: 'TotalMale', numeric: false, disablePadding: true, label: 'Total Male', widthRatio: .047 },
    { id: 'TotalFemale', numeric: false, disablePadding: true, label: 'Total Female', widthRatio: .047 },
    { id: 'TotalDisabled', numeric: false, disablePadding: true, label: 'Total Disabled', widthRatio: .047 }

];

class PlannedBeneficiariesTraining extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        WSPATRID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
        WSPATRFormsID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormsID)) ? this.props.location.state.WSPATRFormsID : null,
        WSPATRFormName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormName)) ? this.props.location.state.WSPATRFormName : null,
        RecordID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RecordID)) ? this.props.location.state.RecordID : null,
        OFOCode: null,
        open: null,
        searchParameters: [{ Name: "WSPATRID", Value: this.props.location.state.WSPATRID }, { Name: "WSPATRFormsID", Value: this.props.location.state.WSPATRFormsID }],
        adddisabled: false,
        bulkimportdisabled: false,
        removedisabled: true,
        removealldisabled: true,
        editdisabled: true,
        updatedisabled: true,
        canceldisabled: true,
        EditFormData: null,
        clearSelectedRow: false,
        promptOpen: false,
        promptTitle: "",
        promptContent: "",
        removeAll: false,
        bulkImportOpen: false,
        showReport: false,
        reportName: "",
        formPermission: "Enabled",
        addDefaultObject: {
            AfricanMale: 0,
            AfricanFemale: 0,
            AfricanDisabled: 0,
            ColouredMale: 0,
            ColouredFemale: 0,
            ColouredDisabled: 0,
            IndianMale: 0,
            IndianFemale: 0,
            IndianDisabled: 0,
            WhiteMale: 0,
            WhiteFemale: 0,
            WhiteDisabled: 0}
    };

    add = () => {

        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined')
        {
        const RaceGender = this.CheckRaceGenderRules(formValue);

        if (RaceGender) {
                formValue.WSPATRID = this.state.WSPATRID;
                formValue.WSPATRFormsID = this.state.WSPATRFormsID;

                const url = `api/sims/WSPATRForms2024/AddWSPATRPlannedBeneficiariesTraining`;
                utils.saveForm(this, formRef, url, (response) => {

                    utils.showMessage(this, response.Message, response.MessageType);
                    this.successCallback(response);
                }, this.error);
        }
    }
    }

    CheckRaceGenderRules = (formValue) => {
        let RaceGender = true;
        let ErrorMessage = "";

        if (parseInt(formValue.AfricanMale) + parseInt(formValue.AfricanFemale) +
            parseInt(formValue.ColouredMale) + parseInt(formValue.ColouredFemale) +
            parseInt(formValue.IndianMale) + parseInt(formValue.IndianFemale)+
            parseInt(formValue.WhiteMale) + parseInt(formValue.WhiteFemale) === 0) {
            ErrorMessage = "Race and Gender Breakdown: Cannot be zero.";
            RaceGender = false;
        }

        if ((parseInt(formValue.AfricanMale) < 0) || (parseInt(formValue.AfricanFemale) < 0) || (parseInt(formValue.AfricanDisabled) < 0) ||
            (parseInt(formValue.ColouredMale) < 0) || (parseInt(formValue.ColouredFemale) < 0) || (parseInt(formValue.ColouredDisabled) < 0) ||
            (parseInt(formValue.IndianMale) < 0) || (parseInt(formValue.IndianFemale) < 0) || (parseInt(formValue.IndianDisabled) < 0) ||
            (parseInt(formValue.WhiteMale) < 0) || (parseInt(formValue.WhiteFemale) < 0) || (parseInt(formValue.WhiteDisabled) < 0)) {
            ErrorMessage = "Race and Gender Breakdown: Negative numbers are not allowed.";
            RaceGender = false;
        }

        if ((parseInt(formValue.AfricanMale) + parseInt(formValue.AfricanFemale)) < parseInt(formValue.AfricanDisabled) ||
            (parseInt(formValue.ColouredMale) + parseInt(formValue.ColouredFemale)) < parseInt(formValue.ColouredDisabled) ||
            (parseInt(formValue.IndianMale) + parseInt(formValue.IndianFemale)) < parseInt(formValue.IndianDisabled) ||
            (parseInt(formValue.WhiteMale) + parseInt(formValue.WhiteFemale)) < parseInt(formValue.WhiteDisabled)) {
            ErrorMessage = "Race and Gender Breakdown: Total count of disabled records may not be greater than male plus female.";
            RaceGender = false;
        }

        if (!RaceGender) { this.setState({ message: ErrorMessage, messageStyle: "warning" }) }

        return RaceGender;
    }

    edit = () => {

        utils.invokeUrl("api/sims/WSPATRForms2024/GetWSPATRPlannedBeneficiariesTrainingFind?WSPATRPlannedBeneficiariesTrainingID=" + this.state.RecordID + "&WSPATRFormsID=" + this.state.WSPATRFormsID, (response) => {
            response.WSPATRID = this.state.WSPATRID;
            response.WSPATRFormsID = this.state.WSPATRFormsID;
            this.setState({
                RecordID: JSON.parse(JSON.stringify(this.state.RecordID)),
                adddisabled: true,
                bulkimportdisabled: true,
                removedisabled: true,
                removealldisabled: true,
                editdisabled: true,
                updatedisabled: false,
                canceldisabled: false,
                EditFormData: response,
                bulkImportOpen: false,
                showReport: false,
                reportName: "",
                message: "",
                messageStyle: ""
            });

        }, this.error);
    }

    remove = () => {
        this.setState({
            adddisabled: true,
            bulkimportdisabled: true,
            removedisabled: false,
            editdisabled: false,
            updatedisabled: true,
            canceldisabled: false,
            promptOpen: true,
            removeAll: false,
            promptTitle: "Remove WSP/ATR Form Record",
            promptContent: "Are you sure you want to Remove this WSP/ATR Form Record?",
            showReport: false,
            reportName: "",
            message: "",
            messageStyle: ""
        });
    }

    removeAll = () => {
        this.setState({
            adddisabled: false,
            bulkimportdisabled: false,
            removedisabled: true,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            promptOpen: true,
            removeAll: true,
            promptTitle: "Remove ALL WSP/ATR Form Record",
            promptContent: "Are you sure you want to Remove ALL records from this WSP/ATR Form?",
            showReport: false,
            reportName: "",
            message: "",
            messageStyle: ""
        });
    }

    bulkimport = () => {
        this.setState({
            adddisabled: false,
            bulkimportdisabled: false,
            removedisabled: true,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            bulkImportOpen: true,
            showReport: false,
            reportName: "",
            message: "",
            messageStyle: ""
        });
    }


    bulkimportImport(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    adddisabled: false,
                    bulkimportdisabled: false,
                    removedisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    EditFormData: null,
                    clearSelectedRow: true,
                    RecordID: null,
                    bulkImportOpen: false,
                    message: "Import Completed",
                    messageStyle: "success",
                    showReport: true,
                    reportName: response.Message
                });
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    adddisabled: false,
                    bulkimportdisabled: false,
                    removedisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    EditFormData: null,
                    clearSelectedRow: true,
                    RecordID: null,
                    bulkImportOpen: false,
                    message: response.Message,
                    messageStyle: "ERROR"
                });
            }
        }
        else if (cancel === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                adddisabled: false,
                bulkimportdisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                EditFormData: null,
                clearSelectedRow: true,
                RecordID: null,
                bulkImportOpen: false
            });
        }
    }

    cancel = () => {
        this.setState({
            adddisabled: false,
            bulkimportdisabled: false,
            removedisabled: true,
            removealldisabled: false,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            bulkImportOpen: false,
            showReport: false,
            reportName: "",
            message: "",
            messageStyle: ""
        });
    }

    update = () => {

        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {

        const RaceGender = this.CheckRaceGenderRules(formValue);

        if (RaceGender) {
                formValue.WSPATRID = this.state.WSPATRID;
                formValue.WSPATRFormsID = this.state.WSPATRFormsID;
                formValue.ID = this.state.RecordID;

                const url = `api/sims/WSPATRForms2024/UpdateWSPATRPlannedBeneficiariesTraining`;
                utils.updateForm(this, formRef, url, (response) => {
                    //this.setState({ message: "Letter updated successfully", messageStyle: "SUCCESS" });

                    utils.showMessage(this, response.Message, response.MessageType);
                    if (response.MessageType === "SUCCESS") {
                        this.successCallback(response);
                    } else {
                        this.setState({
                            message: response.Message,
                            messageStyle: "WARNING"
                        })
                    }
                }, this.error);
            }
        }
    }

    removeRecord() {
        this.setState({ promptOpen: false })

        let url = "api/sims/WSPATRForms2024/RemoveWSPATRPlannedBeneficiariesTraining";
        let fullUrl = `${url}?WSPATRID=${this.state.WSPATRID}&WSPATRFormsID=${this.state.WSPATRFormsID}&Id=${this.state.RecordID}`;
        
        if (this.state.removeAll === true)
        {
            url = "api/sims/WSPATRForms2024/RemoveAllWSPATRPlannedBeneficiariesTraining";
            fullUrl = `${url}?WSPATRID=${this.state.WSPATRID}&WSPATRFormsID=${this.state.WSPATRFormsID}`;
        }

        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                adddisabled: false,
                bulkimportdisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                clearSelectedRow: true,
                EditFormData: null,
                RecordID: null, message: response.Message, messageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ message: response.Message, messageStyle: response.MessageType.toLowerCase() })
        }
    }

    closePrompt = () => {
        this.setState({
            adddisabled: false,
            bulkimportdisabled: false,
            removedisabled: true,
            editdisabled: true,
            updatedisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            promptOpen: false,
            bulkImportOpen: false
        })
    }

    successCallback(response) {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                addDefaultObject: JSON.parse(JSON.stringify(this.state.addDefaultObject)),
                message: response.Message, messageStyle: response.MessageType,
                adddisabled: false,
                bulkimportdisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                clearSelectedRow: true,
                EditFormData: null
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    handleFormValueChange(values) {
        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleFormValueChangeAdd(values) {
        
        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            
            this.setState({
                RecordID: selection[0].Id, Message: "",
                adddisabled: true,
                bulkimportdisabled: true,
                removedisabled: false,
                editdisabled: false,
                updatedisabled: true,
                canceldisabled: false,
                clearSelectedRow: false,
                bulkImportOpen: false,
                showReport: false,
                reportName: "",
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                Message: "",
                adddisabled: false,
                bulkimportdisabled: false,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                clearSelectedRow: true,
                bulkImportOpen: false
            });
        }

        if (this.state.formPermission !== "Enabled") {
            this.setState({
                adddisabled: true,
                bulkimportdisabled: true,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true
            })
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }


    close() {
        this.props.history.push({
            pathname: '/WspAtrForms',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber,
                WSPATRID: this.props.location.state.WSPATRID
            }
        });
    }

    ReportDownload() {

        utils.invokeUrl("api/sims/WSPATRForms2024/BulkUploadGetReport?FileName=" + this.state.reportName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
                this.setState({ message: 'Report Download Failed', messageStyle: 'ERROR' });
        }
        );

    };

    base64ToArrayBuffer (base64){
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    dataArrivedHandler = (values) => {
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }

    componentDidMount() {

        this.getFormHead()
        this.GetWSPATRFormPermissionCheck()
    }

    getFormHead() {
        let url = "api/sims/wspatr/GetFindHeader?WSPATRID=" + this.state.WSPATRID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    GetWSPATRFormPermissionCheck() {
        let url = "api/sims/wspatr/GetWSPATRFormPermissionCheck?WSPATRID=" + this.state.WSPATRID + "&WSPATRFormsID=" + this.state.WSPATRFormsID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "Enabled") {this.setState({
                adddisabled: true,
                bulkimportdisabled: true,
                removedisabled: true,
                editdisabled: true,
                updatedisabled: true,
                canceldisabled: true,
                formPermission: response.Result
            })}
            
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    onDataArrive = (results) => {
        if (results.TotalNumberOfRows === 0) {
            this.setState({
                removealldisabled: true
            });
        }

        else if (results.TotalNumberOfRows > 0 && this.state.formPermission === "Enabled") {
            this.setState({
                removealldisabled: false
            });
        }

    }

    render() {

        let CaptureFormEdit = <PlannedBeneficiariesTrainingForm
            ref={formRef}
            WSPATRID={this.state.WSPATRID}
            valueChangeHandler={(values) => this.handleFormValueChange(values)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)}
            data={this.state.EditFormData} />;

        let CaptureFormAdd = <PlannedBeneficiariesTrainingForm
            ref={formRef}
            WSPATRID={this.state.WSPATRID}
            valueChangeHandler={(values) => this.handleFormValueChangeAdd(values)}
            data={this.state.addDefaultObject} />;

        if (this.state.EditFormData === null) {
            CaptureFormEdit = null;
        }
        else
        {
            CaptureFormAdd = null;
        }
        if (this.state.formPermission !== "Enabled") { CaptureFormAdd = null; CaptureFormEdit = null;}

        return (
            <ViewContainer mode={this.props.mode} title="">
            <div className="view w-100 h-100">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br />
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                            </td>
                            <td align="center">
                                <b>{this.state.WSPATRFormName}</b><br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td colSpan="2" className="formContainer">
                                {CaptureFormAdd}
                                {CaptureFormEdit}
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.adddisabled} onClick={this.add}>Add</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.bulkimportdisabled} onClick={this.bulkimport}>Bulk Import</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.removedisabled} onClick={this.remove}>Remove</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.removealldisabled} onClick={this.removeAll}>Remove All</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnEdit" color="#01579B" hidden={this.state.editdisabled} onClick={this.edit}>Edit</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnUpdate" color="#01579B" hidden={this.state.updatedisabled} onClick={this.update}>Update</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnCancel" color="#01579B" hidden={this.state.canceldisabled} onClick={this.cancel}>Cancel</Button>

                                <div style={{ height: "80%" }}>
                                    {this.state.showReport ? <a
                                        onClick={() => { this.ReportDownload() }}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download
                                        style={{ textDecoration: "underline" }}
                                    >Download Report</a> : null}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>
                    <br />
                    <div className="searchResults">
                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/WSPATRForms2024/GetSearchPlannedBeneficiariesTraining`}
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            onDataArrive={(results) => { this.onDataArrive(results) }}
                            makeRowBold={true}
                                searchParameters={this.state.searchParameters} paged={true} clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow} />
                    </div>
                </div>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeRecord() }}
                    closePrompt={this.closePrompt}
                />

                <BulkImportDialog
                    open={this.state.bulkImportOpen}
                    WSPATRID={this.state.WSPATRID}
                    closeHandler={(cancel, save, response) => this.bulkimportImport(cancel, save, response)}
                    viewRef={this}
                    WSPATRFormsID={this.props.location.state.WSPATRFormsID}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(PlannedBeneficiariesTraining);