import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import withMenuPermission from "../widgets/withMenuPermission";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import EnhancedToolbar from '../widgets/Toolbar';
import EnhancedTable from '../widgets/ResponsiveTable'; 
import { ProjectPopupSearchForm } from './ProjectPopupSearchForm';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const toolbarRef = React.createRef();
const utils = new ViewUtils();
const projectRef = React.createRef();

const headCells = [
    { id: 'Id', numeric: false, disablePadding: false, label: 'Record ID' },
    { id: 'TargetNumber', numeric: true, disablePadding: false, label: 'Target Number' },
    { id: 'ProgrammeStructure', numeric: false, disablePadding: false, label: 'Programme Structure' },
    { id: 'SubProgrammeStructureCode', numeric: true, disablePadding: false, label: 'Sub Programme Structure Code' },
    { id: 'SubProgrammeStructure', numeric: false, disablePadding: false, label: 'Sub Programme Structure' },
    { id: 'Purpose', numeric: false, disablePadding: false, label: 'Purpose' },
    { id: 'Outcome', numeric: false, disablePadding: false, label: 'Outcome' },
    { id: 'Output', numeric: false, disablePadding: false, label: 'Output' },
    { id: 'OutputCode', numeric: true, disablePadding: false, label: 'Output Code' },
    { id: 'APPIndicator', numeric: false, disablePadding: false, label: 'APP Indicator' },
    { id: 'Q1Target', numeric: true, disablePadding: false, label: 'Q1 Target' },
    { id: 'Q2Target', numeric: true, disablePadding: false, label: 'Q2 Target' },
    { id: 'Q3Target', numeric: true, disablePadding: false, label: 'Q3 Target' },
    { id: 'Q4Target', numeric: true, disablePadding: false, label: 'Q4 Target' },
    { id: 'MTEFPeriod', numeric: false, disablePadding: false, label: 'MTEF Period' },
    { id: 'ImportIdentity', numeric: false, disablePadding: false, label: 'Import Identity' },
    { id: 'FinancialYear', numeric: false, disablePadding: false, label: 'Financial Year' },
    { id: 'ProjectType', numeric: false, disablePadding: false, label: 'Project Type' },
];

class NewProject extends Component {

    constructor(props) {
        super(props)

        this.state = {
            keyUpSwitch: false,
            searchParameters: [{ Name: "ImportIdentityID", Value: this.props.location.state.projectID }],
            openImportModal: false,
            openNextModal: false,
            selectedFile: null,
            model: {
                ProjectNumber: null
            },
            message: "",
            showTable: true,
            messageType: "",
            key: Math.floor(100000000 + Math.random() * 900000000),
            FinancialYearID: this.props.location.state.FinancialYearID,
            FinancialYearDescription: this.props.location.state.FinancialYearDescription,
            selectedImportRecord: null,
            selectedProjectID: null,
            selectedProjectDescription: null,
            startingDate: null, 
            endingDate: null
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'downloadtemplate', label: 'DOWNLOAD TEMPLATE', tooltip: 'Download the template format to import', disabled: false },
                        { id: 'import', label: 'IMPORT TEMPLATE', tooltip: 'Import your populated template', disabled: false },
                        { id: 'next', label: 'NEXT', tooltip: 'create new project', disabled: true }
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "downloadtemplate") {
            this.downloadTemplate(this.state.projectType)
        }
        else if (buttonId === "import") {
            this.setState({ openImportModal: true });
        }
        else if (buttonId === "next") {
            this.setState({ openNextModal: true });
        }
    }

    downloadTemplate(Ptype) {
        utils.invokeUrl("api/sims/projects/DownloadAppTemplate", (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            }
        );
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedImportRecord: selection[0] })
            console.log(selection[0])
            toolbarRef.current.setButtonDisabled("next", false);
        } else {
            this.setState({ selectedImportRecord: null })
            toolbarRef.current.setButtonDisabled("next", true);
        }
    }

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    handleOpenImportModal = () => {
        this.setState({ openImportModal: true });
    }

    handleCloseImportModal = () => {
        this.setState({ openImportModal: false, selectedFile: null });
    }

    handleOpenNextModal = () => {
        this.setState({ openNextModal: true });
    }

    handleCloseNextModal = () => {
        this.setState({ openNextModal: false, alertOpen: false, message: "", messageType: "" });
    }

    handleFileChange = (event) => {
        this.setState({ selectedFile: event.target.files[0] });
    }

    handleImport = () => {
        if (this.state.selectedFile) {

            const reader = new FileReader();
            reader.onloadend = () => {
                let base64String = reader.result;
                base64String = base64String.split(',').pop();

                let data = {};
                data.Document = {};
                data.Document.Name = this.state.selectedFile.name;
                data.Document.size = this.state.selectedFile.size;
                data.Document.type = this.state.selectedFile.type;
                data.Document.base64 = base64String;
                data.Key = this.state.key;
                data.PTypeID = this.props.location.state.projectTypeID;
                data.FinancialYearID = this.state.FinancialYearID;

                utils.invokeUrl(`api/sims/projects/AppImport`, (response) => {
                    if (response.Status == "Fail") {
                        var blob = new Blob([this.base64ToArrayBuffer(response.Document.Base64)], { type: response.Document.Type });
                        var url = URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.download = response.Document.Name;
                        link.target = '_blank';
                        link.click();
                        this.setState({ alertOpen: true, message: "Import completed with errors, please see the downloaded import results.", messageType: "error" });
                    } else if (response.Status == "Pass") {
                        this.setState({ alertOpen: true, message: "Import successful", messageType: "success", searchParameters: [{ Name: "ImportIdentityID", Value: this.state.key }] });
                    }  ;
                }, () => {  },
                    {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        },
                        body: JSON.stringify(data)
                    }
                );
            };

            reader.readAsDataURL(this.state.selectedFile);
        } else {
            this.handleAlertOpen();
        }
    }

    handleAlertOpen = () => {
        this.setState({ alertOpen: true, message: "Please select a file before trying to import", messageType: "error" });
    };

    handleAlertClose = () => {
        this.setState({ alertOpen: false, message: "", messageType: "", selectedProjectID: null, selectedProjectDescription: null });
    };

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            
        }
    }

    handleNext = () => {
        if (!utils.isNull(this.state.selectedProjectID)) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: {
                    selection: this.state.selectedImportRecord,
                    projectID: this.state.selectedProjectID,
                    projectIDDescription: this.state.selectedProjectID,
                    projectDescription: this.state.selectedProjectDescription,
                    startDate: this.state.startingDate,
                    endDate: this.state.endingDate,
                    IsAdd: true
                }
            })
        }
        else {
            this.setState({ alertOpen: true, message: "Please select a valid project to link to this setup", messageType: "error" });
        }
    }

    handleProjectSelect = (values) => {
        if (Array.isArray(values) && values.length !== 0) {
            this.setState({ selectedProjectID: values[0].Id, selectedProjectDescription: values[0].Description, startingDate: values[0].StartingDate, endingDate: values[0].EndDate });
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Create New Project > Import"}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/projects/GetImportDataSearch`}
                            height="70vh"
                            onSelectionChange={(selection) => { this.handleSelection(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <Dialog open={this.state.openImportModal} onClose={this.handleCloseImportModal}>
                    <DialogTitle>Import Excel File</DialogTitle>
                    <DialogContent>
                        <input type="file" accept=".xls,.xlsx" onChange={this.handleFileChange} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseImportModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleImport} color="primary">
                            Import
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openNextModal} onClose={this.handleCloseNextModal}>
                    <DialogTitle>Please select a project</DialogTitle>
                    <DialogContent>
                        <ProjectPopupSearchForm id={1} ref={projectRef} handleProjectSelect={(values) => this.handleProjectSelect(values)} fyFilter={this.state.FinancialYearDescription} viewRef={this} data={this.state.model}
                            valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseNextModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleNext} color="primary">
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={this.state.alertOpen} autoHideDuration={6000} onClose={this.handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={this.handleAlertClose} severity={this.state.messageType} >
                        {this.state.message}
                    </Alert>
                </Snackbar>

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(NewProject));

