import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import withMenuPermission from "../widgets/withMenuPermission";
import EnhancedToolbar from '../widgets/Toolbar';
import EnhancedTable from '../widgets/ResponsiveTable'; 

const toolbarRef = React.createRef();
const utils = new ViewUtils();

const headCells = [
    { id: 'LegalName', numeric: false, disablePadding: false, label: 'Legal Name' },
    { id: 'SDLNumber', numeric: false, disablePadding: false, label: 'SDL Number' },
    { id: 'SiteVisitStartDate', numeric: false, disablePadding: false, label: 'Site Visit Start Date' },
    { id: 'SiteVisitEndDate', numeric: false, disablePadding: false, label: 'Site Visit End Date' },
    { id: 'SiteVisitReason', numeric: false, disablePadding: false, label: 'Site Visit Reason' },
    { id: 'ContactPersonName', numeric: false, disablePadding: false, label: 'Contact Person Name' },
    { id: 'ReportReceived', numeric: false, disablePadding: false, label: 'Report Received' },
];

class ProjectSiteVisit extends Component {

    constructor(props) {
        super(props)

        this.state = {
            keyUpSwitch: false,
            searchParameters: [{ Name: "ProjectID", Value: this.props.location.state.projectID }],
            message: "",
            showTable: true,
            messageType: "",
            selectSiteVisit: null,
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        { id: 'create', label: 'Capture Site Visit', tooltip: 'create new site visit', disabled: false },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: false },
                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "create") {
            this.props.history.push({
                pathname: '/CaptureSiteVisit',
                state: {
                    projectID: this.props.location.state.projectID,
                }
            })
        }
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/CaptureSiteVisit',
                state: {
                    projectID: this.props.location.state.projectID,
                    sitevisitID: this.state.selectSiteVisit.Id
                }
            })
        }
        else if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: {
                    projectID: this.props.location.state.projectID,
                    IsAdd: false
                }
            })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectSiteVisit: selection[0] })
        } else {
            this.setState({ selectSiteVisit: null })
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Project Site Visits"}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/projectsitevisit/GetProjectSiteVisits`}
                            height="70vh"
                            onSelectionChange={(selection) => { this.handleSelection(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProjectSiteVisit));

