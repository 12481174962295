import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./ModuleSetup.css";
import SearchView from "../../widgets/SearchView";
import ModuleSetupSearchForm from './ModuleSetupSearchForm';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import CardLayout from '../../widgets/Card';
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'QCTOModuleID', numeric: false, disablePadding: true, label: 'QCTO Module ID' },
    { id: 'QCTOModuleTitle', numeric: false, disablePadding: true, label: 'QCTO Module Title' }
];

const modelTemplate = [
    { Name: "QCTOModuleID", Value: "" },
    { Name: "QCTOModuleTitle", Value: "" }
];



const utils = new ViewUtils();

class ModuleSetupSearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = !utils.isNull(this.props.programmeId) ? [
            { Name: "QCTOModuleID", Value: "" },
            { Name: "QCTOModuleTitle", Value: "" },
            { Name: "ProgrammeID", Value: this.props.programmeId },
            { Name: "ProgrammeType", Value: this.props.programmeType },
            { Name: "ModuleType", Value: this.props.moduletype }
        ]
            :
            [
                { Name: "QCTOModuleID", Value: "" },
                { Name: "QCTOModuleTitle", Value: "" }
            ];

        let model = !utils.isNull(this.props.programmeId) ?
            {
                QCTOModuleID: "",
                QCTOModuleTitle: "",
                ProgrammeID: this.props.programmeId,
                ProgrammeType: this.props.programmeType,
                ModuleType: this.props.moduletype
            }
            :
            {
                QCTOModuleID: "",
                QCTOModuleTitle: ""
            }

        if (!utils.isNull(props.exclusions)) {
            model.Exclusions = props.exclusions;
            this.searchParameters.push({ Name: "Exclusions", Value: props.exclusions });
        }

        if (!utils.isNull(props.personId)) {
            model.PersonID = props.personId;
            this.searchParameters.push({ Name: "PersonID", Value: props.personId })
        }

        this.state = !utils.isNull(this.props.programmeId) ?
            {
                model: model,
                keyUpSwitch: false
            }
            :
            {
                model: model,
                keyUpSwitch: false
            };  

        //TODO: Add url for Module removal
        this.deleteUrl = ""

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: '', tooltip: 'Remove Module', icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete}
                    ]
                }
            ]
        };
    }

    clearSearch(e) {
        this.setState({
            model: {
                QCTOModuleID: "",
                QCTOModuleTitle: "",
                ProgrammeID: this.props.programmeId,
                ProgrammeType: this.props.programmeType,
                ModuleType: this.props.moduletype
            }
        });
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

  

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <SearchView headCells={headCells} dataUrl={utils.isNull(this.props.dataUrl) ? "api/sims/ModuleSetup/getsearch" : this.props.dataUrl}
                    deleteUrl={"api/sims/ModuleSetup/RemoveModule"} useDeletePost={true}
                    entityViewPath='/ModuleSetup' customMessage={this.props.customMessage} clearPress={(e) => { this.clearSearch(e) }}
                    model={this.state.model} toolbarConfig={this.toolbarConfig} modelClearTemplate={modelTemplate}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="QCTOModuleID" mode={this.props.mode} allowClear={this.props.allowClear}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} 
                    checkRefreshToken={!this.props.IsExternal}
                    title='Modules' 
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    promptTitle={"Remove Module"}
                    promptContent={"Removing the module is a permanent action and the Module will not be available going forward. Are you sure you want to remove this Module?"}
                >
                    <ModuleSetupSearchForm
                        data={this.state.model} 
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>  
            </ViewContainer>    
        );
    }
}


export default withRouter(withMenuPermission(ModuleSetupSearch));