import React from 'react';
import { Col } from 'react-bootstrap';
import "./widgets.css";

const styles = {
    color:"red"
}

const FooterPage = (props) => {
    return (
        <Col sm="12" className="footer">
            <a href="http://172.16.201.101/FOODBEVSETAIndicium/Account/Login.aspx" target="_blank" style={{ color: props.color }}>{props.children} </a>
            <span style={{ color: '#000' }}>Copyright &copy; {new Date().getFullYear()} | Developed by SoluGrowth</span>
        </Col>
    );
}

export default FooterPage;