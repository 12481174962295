import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import Item from "antd/lib/list/Item";
import AlertItem from "../../widgets/AlertItem";
import './Style.css'
import { SelectItem } from '../../widgets/SelectItem';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import IconButton from '@material-ui/core/IconButton';
import WebIcon from '@material-ui/icons/Web';
import AppBar from '@material-ui/core/AppBar';
import CloseIcon from '@material-ui/icons/Close';
import { Typography } from "antd";
import EnhancedTable from '../../widgets/ResponsiveTable';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import LinkDialog from "../../etqa/LinkDialog";
import LetterReasonDialog from "./Controls/LetterReasonDialog.jsx";

const utils = new ViewUtils();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Approval Dashboard', tooltip: '' }
            ]
        }
    ]
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
    },
    body: {
        fontSize: 11,
    },
}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    body: {
        fontSize: 14,
        fontWeight: 500
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const headCells = [
    { id: 'Occupation', numeric: false, disablePadding: true, label: 'Occupation', widthRatio: .154 },
    { id: 'AfricanMale', numeric: false, disablePadding: true, label: 'African Male', widthRatio: .047 },
    { id: 'AfricanFemale', numeric: false, disablePadding: true, label: 'African Female', widthRatio: .047 },
    { id: 'AfricanDisabled', numeric: false, disablePadding: true, label: 'African Disabled', widthRatio: .047 },
    { id: 'ColouredMale', numeric: false, disablePadding: true, label: 'Coloured Male', widthRatio: .047 },
    { id: 'ColouredFemale', numeric: false, disablePadding: true, label: 'Coloured Female', widthRatio: .047 },
    { id: 'ColouredDisabled', numeric: false, disablePadding: true, label: 'Coloured Disabled', widthRatio: .047 },
    { id: 'IndianMale', numeric: false, disablePadding: true, label: 'Indian Male', widthRatio: .047 },
    { id: 'IndianFemale', numeric: false, disablePadding: true, label: 'Indian Female', widthRatio: .047 },
    { id: 'IndianDisabled', numeric: false, disablePadding: true, label: 'Indian Disabled', widthRatio: .047 },
    { id: 'WhiteMale', numeric: false, disablePadding: true, label: 'White Male', widthRatio: .047 },
    { id: 'WhiteFemale', numeric: false, disablePadding: true, label: 'White Female', widthRatio: .047 },
    { id: 'WhiteDisabled', numeric: false, disablePadding: true, label: 'White Disabled', widthRatio: .047 },
    { id: 'Age1', numeric: false, disablePadding: true, label: 'Age < 35', widthRatio: .047 },
    { id: 'Age2', numeric: false, disablePadding: true, label: 'Age 35 - 55', widthRatio: .047 },
    { id: 'Age3', numeric: false, disablePadding: true, label: 'Age > 55', widthRatio: .047 },
    { id: 'TotalMale', numeric: false, disablePadding: true, label: 'Total Male', widthRatio: .047 },
    { id: 'TotalFemale', numeric: false, disablePadding: true, label: 'Total Female', widthRatio: .047 },
    { id: 'TotalDisabled', numeric: false, disablePadding: true, label: 'Total Disabled', widthRatio: .047 }
];

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class WSPATRApprovalMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formObject: null,
            lookupArray: null,
            lookupVArray: null,
            FinancialYear: '',
            OrganisationName: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
            VerificationPermission: "",
            searchParameters: [{ Name: "WSPATRID", Value: this.props.location.state.WSPATRID }, { Name: "WSPATRFormsID", Value: 0 }],
            OpenDialog: false,
            formViewURL: "",
            formName: "",
            linkDialogOpen: false,
        };
    }

    componentDidMount() {

        this.getForms(this.state.id)
        this.getlookup(this.state.id)
        this.CheckVerificationPermissions(this.state.id)
    }
    getForms(id) {
        let url = "api/sims/WSPATRApproval/GetWSPATRApproval?Id=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                formObject: response,
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    getlookup(id) {
        let url = "api/sims/WSPATRApproval/GetLookupList?listId=YesNo";
        utils.invokeUrl(url, (response) => {
            this.setState({ lookupArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )

        let urlV = "api/sims/WSPATRApproval/GetLookupListWSPATRApprovalStatus";
        utils.invokeUrl(urlV, (response) => {
            this.setState({ lookupVArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    CheckVerificationPermissions(id) {
        let url = "api/sims/WSPATRApproval/GetWSPATRApprovalPermissionCheck?WSPATRID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                VerificationPermission: response.Result
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleChecklistArrayCI = (event, index) => {
        const values = [...this.state.formObject.WSPATRApprovalChecklist];
        const indexObject = { ...values[index] };
        indexObject.ConfirmInformationYesNoID = { Id: event.target.value };
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, WSPATRApprovalChecklist: values }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayIO = (event, index) => {
        const values = [...this.state.formObject.WSPATRApprovalChecklist];
        const indexObject = { ...values[index] };
        indexObject.InformationOutstandingYesNoID = { Id: event.target.value };
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, WSPATRApprovalChecklist: values }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayC = (event, index) => {
        const values = [...this.state.formObject.WSPATRApprovalChecklist];
        const indexObject = { ...values[index] };
        indexObject.Comments = event.target.value;
        values[index] = indexObject;
        const finalObject = { ...this.state.formObject, WSPATRApprovalChecklist: values }
        this.setState({ formObject: finalObject });
    }

    handleStatus = (event) => {
        const finalObject = { ...this.state.formObject, WSPATREvaluationApprovalStatusID: { Id: event.target.value } }
        this.setState({ formObject: finalObject });
    }

    SaveChecklist = () => {
        let url = "api/sims/WSPATRApproval/UpdateApprovalChecklist";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(this.state.formObject)
            }
        )
    }

    CheckStatus = () => {
        if (this.state.formObject.WSPATREvaluationApprovalStatusID.Id === "4" || this.state.formObject.WSPATREvaluationApprovalStatusID.Id === 4) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else { this.SaveVerification(null) }

    }

    SaveVerification = (selection) => {

        let RejectionReasonsList = "";
        if (selection !== null) {
            for (let i = 0; i < selection.length; i++) {
                if (i === 0) { RejectionReasonsList += selection[i].Id; }
                else { RejectionReasonsList += "," + selection[i].Id; }

            }
        }

        let SaveObject = { ...this.state.formObject, RejectionReasons: RejectionReasonsList };
        this.setState({ formObject: SaveObject });
        //console.log(SaveObject);
        let url = "api/sims/WSPATRApproval/UpdateFinalApproval";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(SaveObject)
            }
        )
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {




            this.setState({
                linkDialogOpen: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        this.componentDidMount();
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: './WSPATRApproval'
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            //this.save();
        } else {
            this.close();
        }
    }

    buttonSubmitClickHandler() {
        this.submit();
    }

    FileDownload(id, DocumentType) {
        utils.invokeUrl("api/sims/WSPATRApproval/DownloadWSPATRDocument?DocumentID=" + id + "&DocumentTypeID=" + DocumentType, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    OpenForm(id, formUrl, fName) {
        console.log(formUrl);
        let newsearchParameters = [...this.state.searchParameters];
        let temp_element = { ...newsearchParameters[1] };
        temp_element.Value = id
        newsearchParameters[1] = temp_element;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            OpenDialog: true,
            formViewURL: formUrl,
            formName: fName
        });
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            formViewURL: "",
            formName: ""
        });
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    render() {

        const { classes } = this.props;
        const tablerows = !utils.isNull(this.state.formObject) ? this.state.formObject.WSPATRApprovalChecklist.map((item, index) => (
            item.LinkInformation.split("|")[0] !== "Calculation" ?
                <StyledTableRow key={item.Number}>
                    <StyledTableCell component="th" scope="row">
                        {item.Number}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.CheckList}</StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row" >
                        <TextField className="w-30" id={"ConfirmInformationYesNoID" + item.Number} value={item.ConfirmInformationYesNoID !== null ? item.ConfirmInformationYesNoID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayCI(values, index)}>
                            <MenuItem value={0} key={0} >  </MenuItem>
                            {this.state.lookupArray.map((lookupitem, index) => (

                                <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>

                            ))}
                        </TextField>
                    </StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row" >
                        <TextField className="w-30" id={"InformationOutstandingYesNoID" + item.Number} value={item.InformationOutstandingYesNoID !== null ? item.InformationOutstandingYesNoID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayIO(values, index)} >
                            <MenuItem value={0} key={0} >  </MenuItem>
                            {this.state.lookupArray.map((lookupitem, index) => (
                                <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>
                            ))}
                        </TextField>
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row" >
                        <TextField id={"Comments" + item.Number} multiline rows={2} value={item.Comments} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayC(values, index)}> </TextField>
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row" >
                        {item.LinkInformation.split("|")[0] !== "Document" && item.LinkInformation.split("|")[0] !== "Form" ? null :
                            item.LinkInformation.split("|")[0] === "Document" ?
                                <IconButton variant="text" color="primary" onClick={() => this.FileDownload(item.LinkInformation.split("|")[1], item.CheckListTypeID.Id)} ><CloudDownloadIcon /></IconButton>
                                :
                                <IconButton variant="text" color="primary" onClick={() => this.OpenForm(item.LinkInformation.split("|")[1], item.DataURL, item.CheckList)} ><WebIcon /></IconButton>
                        }
                    </StyledTableCell>
                </StyledTableRow >
                :
                <StyledTableRow key={item.Number}>
                    <StyledTableCell component="th" scope="row">
                        {item.Number}
                    </StyledTableCell>
                    <StyledTableCell align="left">{item.CheckList}</StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row" >
                        {item.LinkInformation.split("|")[2]}
                    </StyledTableCell>
                    <StyledTableCell align="left" component="th" scope="row" >
                        {item.LinkInformation.split("|")[3]}
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row" >
                        <TextField id={"Comments" + item.Number} multiline rows={2} value={item.Comments} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayC(values, index)}> </TextField>
                    </StyledTableCell>
                    <StyledTableCell align="center" component="th" scope="row" >
                        {item.LinkInformation.split("|")[1]}
                    </StyledTableCell>
                </StyledTableRow >
        ))
            :
            null;



        return (
            <ViewContainer topPadding="0" title="">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br />
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                            </td>
                            <td align="center">
                                <b>WSP/ATR Approval</b><br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            <StyledTableCell align="left">#</StyledTableCell>
                            <StyledTableCell>Checklist</StyledTableCell>
                            <StyledTableCell>Please check and confirm that the information has been completed by the organisation Y/N?</StyledTableCell>
                            <StyledTableCell>Information Submitted (Y/N)</StyledTableCell>
                            <StyledTableCell>Comments</StyledTableCell>
                            <StyledTableCell>Links</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {tablerows}
                    </TableBody>
                </Table>
                <br />
                <br />
                <Button variant="outlined" size="small" id="btnSaveChecklist" color="#01579B" disabled={this.state.VerificationPermission === "ViewOnly" ? true : false} onClick={this.SaveChecklist}>Save Checklist</Button>&nbsp;
                <br />
                <br />
                <TableContainer component={Paper}>
                    <table className={classes.table} aria-label="simple table" size="small">
                        <tbody>
                            <TableRow>
                                <td align="left">
                                    <b>Board Status</b><br />
                                </td>
                                <td align="left">
                                    <TextField className="w-30" id="ApprovalStatusID" disabled={this.state.VerificationPermission === "VerificationEnabled" ? false : true} value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.WSPATREvaluationApprovalStatusID) ? this.state.formObject.WSPATREvaluationApprovalStatusID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleStatus(values)}>
                                        <MenuItem value={0} key={0} >  </MenuItem>
                                        {!utils.isNull(this.state.lookupVArray) ? this.state.lookupVArray.map((lookupVitem, index) => (

                                            <MenuItem value={lookupVitem.Id} key={lookupVitem.Id} >{lookupVitem.Description}</MenuItem>

                                        )) : null}
                                    </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Approver</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="Verifier" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.Approver : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Approval Date</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="DateApproved" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.DateApproved : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                        </tbody>
                    </table>
                </TableContainer>
                <br />
                <Button variant="outlined" size="small" id="btnSave" color="#01579B" disabled={this.state.VerificationPermission === "VerificationEnabled" ? false : true} onClick={this.CheckStatus}>Save</Button>&nbsp;

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {this.state.formName}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={headCells}
                        autoHeader={true}
                        dataUrl={this.state.formViewURL}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters} paged={true} autoFetchData={true} />
                </Dialog >



                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title="Rejection Reasons"
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle="Rejection Reasons"
                    linkName={"Rejection"} linkHandler={(selection) => this.SaveVerification(selection)}
                    selectionId={this.state.id}
                    buttonName="Save"
                >
                    <LetterReasonDialog
                        dataUrl={"api/sims/WSPATRApproval/GetWSPATRRejectionLetterReasons"}
                        multiSelect={true} showTitle={false}
                        WspAtrId={this.state.id}
                        mode="lookup"
                        LetterType="WSP"
                    />
                </LinkDialog>
            </ViewContainer>

        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(WSPATRApprovalMain)));