import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Skills.css";
import { SkillsLetterCreationForm } from './SkillsLetterCreationForm';

import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";
import { ViewContainer } from "../../View";
import withMenuPermission from "../../widgets/withMenuPermission";
const utils = new ViewUtils();


const userRef = React.createRef();

class SkillsLetterCreation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            model: {
                BodyACharactersLeft: "2500",
                BodyBCharactersLeft: "2500"
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,

        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close' },
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
                    ]
                },
            ]
        };

    }

    componentDidMount() {
        this.getDefaultFinancialYear();
    }

    getDefaultFinancialYear = () => {
        if (utils.isNull(this.state.id)) {
            let url = "api/sims/skillslettercreationdetails/GetDefaultFinancialYear";
            utils.invokeUrl(url, (response) => {;
                this.setState({ model: { ...this.state.model, FinancialYearID: response } });
            },
                null,
                {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close()
        } else if ("save" === buttonId) {
            this.save()
        }
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {

                if (!utils.isNull(newValues.BodyA)) {
                    newValues.BodyACharactersLeft = 2500 - values["BodyA"].length;
                }
                else {
                    newValues.BodyACharactersLeft = 2500;
                }
                if (!utils.isNull(newValues.BodyB)) {
                    newValues.BodyBCharactersLeft = 2500 - values["BodyB"].length;
                }
                else {
                    newValues.BodyBCharactersLeft = 2500;
                }

                this.setState({
                    Name: newValues.Name,
                    model: newValues,
                });
            }
        }
    }


    save() {
        let formValue = userRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {
            let isAdd = true;
            //if (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) {
            //    formValue.id = this.props.location.state.selection;
            //    isAdd = false;
            //}
            if (!utils.isNull(this.state.id)) {
                formValue.id = this.state.id;
                isAdd = false;
            }
            //let data = JSON.stringify(formValue);

            if (isAdd) {
                const url = `api/sims/skillslettercreationdetails/insertlettercreation`;
                utils.saveForm(this, userRef, url, (response) => {
                    this.setState({ message: "Letter added successfully", messageStyle: "SUCCESS", id: response.EntityId });
                }, this.error);
            } else {
                const url = `api/sims/skillslettercreationdetails/updatelettercreation`;
                utils.updateForm(this, userRef, url, (response) => {
                    this.setState({ message: "Letter updated successfully", messageStyle: "SUCCESS" });
                }, this.error);
            }
        }



    }

    successCallback(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }
    close() {
        this.props.history.push('/SkillsLetterCreationSearch');
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={utils.getEntityViewTitle(this.props.location.state, "Letter Creation", this.state.Name)}>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />


                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Letter Creation" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    enabled={true} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <SkillsLetterCreationForm ref={userRef}
                                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                    id={this.state.id}
                                    editPermission={this.props.menuPermissionItem.Edit}
                                    data={this.state.model}
                                    model={this.state.model}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>

            </ViewContainer>
        );
    }
}
export default withRouter(withMenuPermission(SkillsLetterCreation));
// export default withRouter(SkillsLetterCreation);
