import React, { useEffect } from 'react';
import { EntityForm } from '../widgets/Forms';
import ProjectSearch from '../etqa/grants/grantsetup/ProjectSearch';
import { LookupField } from '../widgets/LookupField';
import ViewUtils from "../ViewUtils";


const utils = new ViewUtils();
export const ProjectPopupSearchForm = React.forwardRef((props, ref) => { 

    useEffect(() => {

    }, [])

    return (
        <EntityForm ref={ref} viewId="GrantSetup" values={props.data} entityLookupId={props.id}
            useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProjectPopupSearchForm">
                    <tr>
                        <td>
                            <LookupField
                                id="ProjectNumber"
                                label="Project"
                                className="w-100"
                                form={form}
                            >
                                <ProjectSearch showTitle={true} mode='lookup' fyFilter={props.fyFilter} dataUrl="api/sims/projects/BCRetrieveProjectList"
                                    onLookupValueChange={(value) => {
                                        form.setLookupSelection("ProjectNumber", value)
                                    }} handleProjectSelect={(value) => { props.handleProjectSelect(value) }} autoFetchData={false} selection={props.selection} />
                            </LookupField>
                        </td>
                    </tr>
                    <br>
                    </br>
                </form>
            }
        </EntityForm>
);
})