import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import AlertItem from "../widgets/AlertItem";
import EnhancedTable from '../widgets/ResponsiveTable';
import EnhancedToolbar from '../widgets/Toolbar';
import FormDialog from "../etqa/FormDialog";
import { ProjectUploadForm } from "./ProjectUploadForm";
import Prompt from '../widgets/Prompt';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const uploadheadcells = [
    { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Date Uploaded' },
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name' },
    { id: 'UploadedBy', numeric: false, disablePadding: true, label: 'Uploaded by' }
];

const toolbarRef = React.createRef();
const utils = new ViewUtils();
const documentFormRef = React.createRef();

class ProjectUpload extends Component {

    constructor(props) {
        super(props)
        this.state = {
            projectID: this.props.location.state.projectID,
            showTable: true,
            updateDocumentDialogOpen: false,
            message: "",
            propmtOpen: false,
            pageNumber: 1,
            documentUrl: null,
            documentType: null,
            uploadDialogMessage: "Upload must be a minimum of 5MB",
            uploadDialogMessageStyle: "warning",
            ProjectUploadID: null,
            promptTitle: "Remove project upload record",
            promptContent: "Are you sure you want to remove this project upload record?",
            messageStyle: "",
            searchParameters: [{ Name: "ProjectID", Value: this.props.location.state.projectID }]
        }

        this.onDocumentLoadSuccess = this.onDocumentLoadSuccess.bind(this);

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        { id: 'uploadDocument', label: 'UPLOAD DOCUMENT', tooltip: 'Upload Document' },
                        { id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove project upload document', disabled: utils.isNull(this.state.ProjectUploadID) },
                        { id: 'download', label: '', tooltip: 'Download document', disabled: utils.isNull(this.state.ProjectUploadID), icon: 'DownloadIcon' },
                        { id: 'viewupload', label: '', tooltip: 'View Upload', disabled: utils.isNull(this.state.ProjectUploadID), icon: 'ViewIcon' },
                    ]
                }
            ]
        };

    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("uploadDocument" === buttonId) {
            this.setState({ updateDocumentDialogOpen: true, message: "" })
        }
        else if (buttonId === "close") {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: {
                    projectID: this.props.location.state.projectID,
                    IsAdd: false
                }
            })
        }
        else if ("download" === buttonId) {
            this.downloadDocument(this.state.ProjectUploadID);
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("viewupload" === buttonId) {
            this.openDocument(this.state.ProjectUploadID);
        }
    }

    downloadDocument(selectedProjectUploadID) {
        let url = "api/sims/projectupload/getDocument?documentID=" + selectedProjectUploadID;
        utils.invokeUrlExternal(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    successCallback(response) {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
        });
    }

    errorCallback(response) {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ ProjectUploadID: selection[0].Id });
        } else {
            this.setState({ ProjectUploadID: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("download", selection === null || selection.length === 0);
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                ProjectUploadID: null, updateDocumentDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                updateDocumentDialogOpen: false
            })
        }
    }

    removeProjectUpload(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("download", true);
        })

        let fullUrl = `${url}?id=${this.state.ProjectUploadID}`;
        utils.invokeUrlDelete(fullUrl, this.successfulProjectUploadRemoval, this.unsuccessfulProjectUploadRemovalCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    successfulProjectUploadRemoval = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ ProjectUploadID: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulProjectUploadRemovalCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    dialogUploadActionHandler(isCancel, added) {
        if (isCancel === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                ProjectUploadID: null, updateDocumentDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
    }

    openDocument(selectedProjectUploadID) {
        let url = "api/sims/projectupload/getDocument?documentID=" + selectedProjectUploadID;
        utils.invokeUrlExternal(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const extension = response.Name.split('.').pop();

            let documentType = '';
            switch (extension) {
                case 'jpeg':
                case 'jpg':
                    documentType = 'image/jpeg';
                    break;
                case 'png':
                    documentType = 'image/png';
                    break;
                case 'pdf':
                    documentType = 'application/pdf';
                    break;
                default:
                    documentType = response.Type; // fallback to the original type
                    break;
            }

            this.setState({
                documentUrl: url,
                documentType: documentType
            });
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        });
    };

    render() {

        let documentDisplay = null;

        if (this.state.documentUrl !== null) {
            if (this.state.documentType.includes("image")) {
                documentDisplay = <img src={this.state.documentUrl} alt="Document" style={{ maxWidth: '100%', maxHeight: '100%' }} />
            } else if (this.state.documentType === "application/pdf") {
                documentDisplay = (
                    <Document
                        file={this.state.documentUrl}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                        <Page pageNumber={this.state.pageNumber} />
                    </Document>
                )
            }
        }

        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Projects Upload"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        ref={toolbarRef}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />

                        <EnhancedTable
                            headCells={uploadheadcells}
                            dataUrl={`api/sims/projectupload/GetProjectUploads`}
                            height="50vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.updateDocumentDialogOpen}
                    dialogTitle={"Upload Document"}
                    viewRef={this}
                    entityId={this.state.projectID}
                    formRef={documentFormRef}
                    hideSave={true}
                    defaultAlertMessage={this.state.uploadDialogMessage}
                    defaultAlertMessageStyle={this.state.uploadDialogMessageStyle}
                    usePut={true}
                    saveUrl={"api/sims/projectupload/UpdateProjectUpload"}
                    closeHandler={(isCancelled, linked) => this.dialogUploadActionHandler(isCancelled, linked)}
                >
                    <ProjectUploadForm ref={documentFormRef} id={this.state.projectID} controllerName='projectupload' />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeProjectUpload("api/sims/projectupload/RemoveProjectUpload") }}
                    closePrompt={this.closePrompt}
                />

                {documentDisplay}

            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProjectUpload)
);
