import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();
export const StatusForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="StatusForm" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="StatusForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                {
                                    (props.hasOutComeReportFlag === true) ?
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=researchvisitstatus"}
                                                id="ResearchVisitStatusID"
                                                key={tabValue}
                                                label="Research Visit Status"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                        :
                                        ""
                                }
                                {
                                    (props.hasOutComeReportFlag === false) ?
                                        <td>
                                            <SelectItem
                                                dataUrl={"api/sims/" + props.controllerName + "/GetLookupList?listId=researchvisitstatus"}
                                                id="ResearchVisitStatusID"
                                                key={tabValue}
                                                label="Research Visit Status"
                                                className="w-100"
                                                form={form}
                                                filterOptions={option => {
                                                    return option.Id == 1 && option.Description == "Visit Pending";
                                                }}
                                            />
                                        </td>
                                        :
                                        ""
                                }
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
