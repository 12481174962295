import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import LinkDialog from "../LinkDialog";
import Prompt from '../../widgets/Prompt';
import ProviderSearch from '../provider/ProviderSearch';
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

const headCells = [
    { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
    { id: "TradeName", numeric: false, disablePadding: true, label: "Trade Name" },
    { id: "LegalName", numeric: false, disablePadding: true, label: "Legal Name" },
    { id: "ProAccNumber", numeric: false, disablePadding: true, label: "Accreditation Number" },
    { id: "AssessorAssessmentEndDate", numeric: false, disablePadding: true, label: "Assessor Assessment End Date" },
]

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class AssessorProviderSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                AssessorID: this.props.location.state.AssessorID
            },
            searchParameters: [
                { Name: "AssessorID", Value: this.props.location.state.AssessorID }
            ],
            linkDialogOpen: false,
            selectedProvider: null,
            propmtOpen: false,
            promptTitle: "Unlink Training Provider From Assessor",
            promptContent: "Are you sure you want to unlink this training provider from the assessor?",
            message: "",
            messageStyle: "",
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'link', label: '', tooltip: 'Link Provider/s to the assessor', icon: 'LinkIcon' },
                        { id: 'unlink', label: '', tooltip: 'Un-link the selected provider from the assessor', disabled: utils.isNull(this.state.selectedProvider), icon: 'LinkOffIcon' }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/assessor',
                state: { selection: this.props.location.state.AssessorID }
            });
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, message: "" })
        }
        else if ("unlink" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    successCallback = (response) => {
        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedProvider: null });
            //Force a refresh
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase(),
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            })
        }
        else if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    unsuccessfulUnlinkCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    unlinkAssessorProvider(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("unlink", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedProvider}&assessorId=${this.props.location.state.AssessorID}`
        utils.invokeUrlDelete(fullUrl, this.successCallback, this.unsuccessfulUnlinkCallback);
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                linkDialogOpen: false, selectedProvider: null
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({
                selectedProvider: selection[0].Id,
                message: "",
                messageStyle: ""
            });
        } else {
            this.setState({
                selectedProvider: null,
                message: "",
                messageStyle: ""
            });
        }
        toolbarRef.current.setButtonDisabled("unlink", selection === null || selection.length === 0);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Assessor > Providers" + (this.props.location.state !== null ? " > " + this.props.location.state.AssessorName + " - " + this.props.location.state.AssessorIDNo : "> Add")}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/assessorprovider/GetAssessorProvider`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.props.location.state.AssessorName}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={`Link Training Provider to Assessor: ${this.props.location.state.AssessorName}`}
                    linkName={"Providers"} postUrl={"api/sims/assessorprovider/LinkAssessorProvider"} usePost={true}
                    selectionId={this.props.location.state.AssessorID}
                >
                    <ProviderSearch
                        dataUrl={"api/sims/assessorprovider/GetSearchLinkableProviders"}
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.AssessorID} programmeType="Q"
                        mode="lookup"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.unlinkAssessorProvider(`api/sims/assessorprovider/RemoveAssessorProvider`)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AssessorProviderSearch)
);