import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import EnhancedTable from '../../widgets/ResponsiveTable';
import ViewUtils from '../../ViewUtils';
import { QCTOAssessmentHeaderForm } from './QCTOAssessmentHeaderForm';
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'QCTO Module Code' },
    { id: "ProgrammeTitle", numeric: false, disablePadding: true, label: "QCTO Module Title" },
    { id: "Credits", numeric: false, disablePadding: true, label: "Credits" },
    { id: "ModuleType", numeric: false, disablePadding: true, label: "Module Type" },
    { id: "RPL", numeric: false, disablePadding: true, label: "RPL" },
    { id: "Assessor", numeric: false, disablePadding: true, label: "Assessor" },
    { id: "AssessmentDate", numeric: false, disablePadding: true, label: "Assessment Date" },
    { id: "Moderator", numeric: false, disablePadding: true, label: "Moderator" },
    { id: "ModerationDate", numeric: false, disablePadding: true, label: "Moderation Date" },
    { id: "Status", numeric: false, disablePadding: true, label: "Status" },
    { id: "CertificateNumber", numeric: false, disablePadding: true, label: "Certificate Number" },
    { id: "CertificateDate", numeric: false, disablePadding: true, label: "Certificate Date" },
]


const utils = new ViewUtils();
const toolbarRef = React.createRef();
const learnerRef = React.createRef();

class ViewModuleLearnershipAssessment extends Component {

    constructor(props) {
        super(props)
        this.state = {
            model: {
                AssessmentID: null
            },
            selectedUnitStandards: null,
            keyUpSwitch: false,
            updateUSd: false,
            messsageAlert: null,
            messageStyle: null,
            showTable: true,
            searchParameters: [{ Name: "AssessmentID", Value: this.props.location.state.ModuleQualificationID  }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'cancel', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'updateUS', label: 'UPDATE', tooltip: 'UPDATE SELECTED MODULE/S', icon: 'UpdateIcon' },
                        { id: 'remove', label: '', tooltip: 'REMOVE SELECTED ASSESSMENTS', icon: "DeleteIcon" },
                        { id: 'closeoff', label: 'CLOSE OFF', tooltip: 'CLOSE OFF', disabled: true },

                    ]
                }
            ]
        };
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("updateUS" === buttonId) {
            this.updateUSDocuments();
        }
         else if ("cancel" === buttonId) {
            this.props.history.push({
                pathname: '/LearnerQCTOLearnershipAssessments',
                state: {
                    learnerID: this.props.location.state.LearnerID,
                    learnerNames: this.props.location.state.learnerNames
                }
            });
        } else if ("remove" === buttonId) {
            this.removeAssessments();

        } else if ("closeoff" === buttonId) {
            this.closeOff();

        } 
    }

    handleToolbarButtonClickClose = (buttonId) => {
        if ("cancel" === buttonId) {
           
        }
    }

    removeAssessments() {
        const UnitStandardIDs = this.state.selectedUnitStandards.map(e => e.Id);
        let data = {
            UnitStandardIDs: UnitStandardIDs,
            ...this.state.model
        }
        let input = JSON.stringify(data);
        utils.invokeUrl("api/sims/LearnerQCTOLearnershipAssessment/RemoveLearnerQCTOLearnershipAssessment", this.successCallback, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: input
        });
    }

    successCallback = (response) => {
        this.setState({
            showTable: false,
            message: response.Message, messageStyle: response.MessageType
        }, () => {
            this.setState({ showTable: true, message: response.Message, messageStyle: response.MessageType })
        });
    }

    componentDidMount() {
        this.GetClosePermission();
    }

    GetClosePermission() {
        let url = "api/sims/LearnerQCTOLearnershipAssessment/GetClosePermission?AssessmentID=" + this.props.location.state.ModuleQualificationID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "1") {
                toolbarRef.current.setButtonDisabled("closeoff", true);
            }
            else {
                toolbarRef.current.setButtonDisabled("closeoff", false);
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    successCloseOffandQA = (response) => {
        this.setState({
            message: response.Message,
            messageStyle: response.MessageType,
            showTable: false
        }, () => {
            this.setState({
                message: response.Message,
                messageStyle: response.MessageType,
                showTable: true
            })
        });
    }

    closeOff() {
        let url = "api/sims/LearnerQCTOLearnershipAssessment/CloseOffAssessment?AssessmentID=" + this.props.location.state.ModuleQualificationID;
        utils.invokeUrl(url, (response) => {
            this.successCloseOffandQA(response)
            toolbarRef.current.setButtonDisabled("closeoff", true);
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    updateUSDocuments() {
            const UnitStandardIDs = this.state.selectedUnitStandards.map(e => e.Id);
            let data = {
                UnitStandardIDs: UnitStandardIDs,
                ...this.state.model
            }
            let input = JSON.stringify(data);
        utils.invokeUrl("api/sims/LearnerQCTOLearnershipAssessment/UpdateLearnerQCTOLearnershipAssessment", (response) => {
            this.successCallback(response);
        }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedUnitStandards: selection, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("updateUS", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("remove", !this.props.onMenuSpecificPermissionItem('/learnermodulequalificationassessment').Delete);
                }
            )
        } else {
            this.setState({ selectedUnitStandards: null, Message: "" },
                () => {
                    toolbarRef.current.setButtonDisabled("updateUS", selection.length === 0);
                    toolbarRef.current.setButtonDisabled("remove", selection.length === 0);
                }
            )
        }
    }

    handleFormValueChange = (values) => {
        let newValues = null;
        if (!utils.isNull(values)) {
            newValues = { ...values };
        }
        if (!utils.isNull(newValues)) {
            if (JSON.stringify(this.state.model) !== JSON.stringify(newValues)) {
                this.setState({
                    model: newValues,
                    Message: ""
                });
                toolbarRef.current.setButtonDisabled("updateUS", true);
                toolbarRef.current.setButtonDisabled("Cancel", true);
                toolbarRef.current.setButtonDisabled("remove", true);
            }
        }

        if (this.state.selectedUnitStandards.length > 0) {
            toolbarRef.current.setButtonDisabled("updateUS", false);
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {`Learner > Update QCTO Learnership Assessment > ${this.props.location.state.learnerNames}`}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                {this.state.showTable === true && (
                    <QCTOAssessmentHeaderForm
                    ref={learnerRef}
                    viewRef={this}
                    id={this.props.location.state.ModuleQualificationID}
                    valueChangeHandler={(values) => this.handleFormValueChange(values)}
                        controllerName={"LearnerQCTOLearnershipAssessment"}
                        dataURL='api/sims/LearnerQCTOLearnershipAssessment/GetLearnerQCTOLearnershipAssessmentHeaderDetails'   />
                )}
                <td>
                    <EnhancedToolbar
                        ref={toolbarRef}
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        config={this.toolbarConfig} />
                </td>
               
                <div>
                    {this.state.showTable === true && (
                    <EnhancedTable
                        multiSelect={true}
                        headCells={headCells}
                            dataUrl="api/sims/LearnerQCTOLearnershipAssessment/getLearnerQCTOLearnershipAssessmentDetails"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                    )}
                    </div>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ViewModuleLearnershipAssessment));