import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { CustomTextField } from '../../widgets/CustomTextField';
import * as XLSX from 'xlsx';
import Button from '@material-ui/core/Button';

const utils = new ViewUtils();
export const NotificationForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [stakeholderEmails, setStakeholderEmails] = React.useState(null);

    const handleFileChange = (event, form) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (evt) => {
                const bstr = evt.target.result;
                const wb = XLSX.read(bstr, { type: 'binary' });

                const wsname = wb.SheetNames[0];
                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

                const emails = data.slice(3).map(row => row[0]).join(';');
                form.values["StakeholderEmails"] = emails;
                setStakeholderEmails(form.values["StakeholderEmails"]);
            };
            reader.readAsBinaryString(file);
        }
    }

    return (
        <EntityForm ref={ref} viewId="NotificationForm" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="NotificationForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                            <tr>
                                <td>
                                    <CustomCheckbox
                                        id="Assessors"
                                        key={tabValue}
                                        form={form}
                                        InputProps={{
                                            'aria-label': 'primary checkbox'

                                        }}
                                        label="Assessors"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckbox
                                        id="Moderators"
                                        key={tabValue}
                                        form={form}
                                        InputProps={{
                                            'aria-label': 'primary checkbox'

                                        }}
                                        label="Moderators"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckbox
                                        id="TrainingProvider"
                                        key={tabValue}
                                        form={form}
                                        InputProps={{
                                            'aria-label': 'primary checkbox'

                                        }}
                                        label="Training Providers"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckbox
                                        id="SkillsDevelopmentFacilitators"
                                        key={tabValue}
                                        form={form}
                                        InputProps={{
                                            'aria-label': 'primary checkbox'

                                        }}
                                        label="Skills Development Facilitators"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckbox
                                        id="Chambers"
                                        key={tabValue}
                                        form={form}
                                        InputProps={{
                                            'aria-label': 'primary checkbox'

                                        }}
                                        label="Chambers"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomCheckbox
                                        id="KeyStakeholders"
                                        key={tabValue}
                                        form={form}
                                        InputProps={{
                                            'aria-label': 'primary checkbox'

                                        }}
                                        label="Key Stakeholders"
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="StakeholderEmails"
                                        label="Stakeholder Email List"
                                        className="w-100"
                                        value={stakeholderEmails}
                                        InputProps={{
                                            readOnly: props.disableFields,
                                        }}
                                        form={form}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <input
                                        accept=".xlsx"
                                        id="upload-emails"
                                        type="file"
                                        style={{ display: 'none' }}
                                        onChange={(event) => handleFileChange(event, form)}
                                    />
                                    <label htmlFor="upload-emails">
                                        <Button variant="contained" component="span">
                                            Import Email Addresses
                                        </Button>
                                    </label>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
