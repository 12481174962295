
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import { EmployerAddResearchVisitForm } from "../employer/EmployerAddResearchVisitForm";
import FormDialog from "../FormDialog";
import { StatusForm } from '../employer/StatusForm';
import { ResearchOutcomeReportForm } from "../../ResearchVisitDashboard/ResearchOutcomeReportForm";

const headCells = [
    { id: 'ScheduledDate', numeric: false, disablePadding: true, label: 'Scheduled Date and Time' },
    { id: 'ContactPersonName', numeric: false, disablePadding: true, label: 'Contact Person Name' },
    { id: 'ContactPersonSurname', numeric: false, disablePadding: true, label: 'Contact Person Surname' },
    { id: 'ContactPersonEmail', numeric: false, disablePadding: true, label: 'Contact Person E-mail' },
    { id: 'ContactPersonCellPhoneNumber', numeric: false, disablePadding: true, label: 'Contact Person Cell Phone Number' },
    { id: 'FoodBevSETAContact', numeric: false, disablePadding: true, label: 'FoodBev SETA Contact' },
    { id: 'FoodBevSETAEmail', numeric: false, disablePadding: true, label: 'FoodBev SETA Email' },
    { id: 'DocumentUploaded', numeric: false, disablePadding: true, label: 'Document Uploaded' },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();
const documentFormRef = React.createRef();
const statusFormRef = React.createRef();


class EmployerResearchVisitsSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            linkDialogOpen: false,
            formDialogOpen: false,
            selectedResearchVisit: null,
            propmtOpen: false,
            promptTitle: "Remove research visit schedule from employer",
            promptContent: "Are you sure you want to remove this research visit schedule from the employer?",
            unlinkMessage: "",
            unlinkMessageStyle: "",
            updateDialogOpen: false,
            searchParameters: [{ Name: "EmployerID", Value: this.props.location.state.selection }],
            updateDocumentDialogOpen: false,
            showTable: true,
            hasOutcomeReport: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', icon: 'AddIcon', tooltip: 'Add new research visit schedule to employer' },
                        { id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove research visit schedule from employer', disabled: utils.isNull(this.state.selectedResearchVisit) },
                        { id: 'uploadDocument', label: 'UPLOAD OUTCOME REPORT', tooltip: 'Upload Document', disabled: true },
                        { id: 'update', label: '', tooltip: 'Update the status of this research visit', disabled: utils.isNull(this.state.selectedResearchVisit), icon: 'UpdateIcon' },
                        { id: 'download', label: '', tooltip: 'Download document', disabled: utils.isNull(this.state.selectedResearchVisit), icon: 'DownloadIcon' },
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/employer',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {
            console.log(addFormRef)
            this.setState({ formDialogOpen: true })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        } 
        else if ("update" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("download" === buttonId) {
            this.downloadDocument(this.state.selectedResearchVisit);
        }
        else if ("uploadDocument" === buttonId) {
            this.setState({ updateDocumentDialogOpen: true, message: "" })
        }
    }

    downloadDocument(selectedResearchVisitID) {
        let url = "api/sims/employerresearchvisit/getDocument?documentID=" + selectedResearchVisitID;
        utils.invokeUrlExternal(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    dialogActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, selectedResearchVisit: null,
                linkDialogOpen: false, updateDocumentDialogOpen: false
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                updateDialogOpen: false,
                updateDocumentDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            toolbarRef.current.setButtonDisabled("remove", false);
            if (selection[0].DocumentUploaded == '' || utils.isNull(selection[0].DocumentUploaded)) {
                this.setState({
                    selectedResearchVisit: selection[0].Id,
                    message: "",
                    messageStyle: "",
                    hasOutcomeReport: false
                });
                toolbarRef.current.setButtonDisabled("download", true);
            } else {
                this.setState({
                    selectedResearchVisit: selection[0].Id,
                    message: "",
                    messageStyle: "",
                    hasOutcomeReport: true
                });
                toolbarRef.current.setButtonDisabled("download", false);
            }

            if (selection[0].Status == "Visit Confirmed") {
                toolbarRef.current.setButtonDisabled("uploadDocument", false);
                toolbarRef.current.setButtonDisabled("update", false);
            }
            else if (selection[0].Status == "Visit Pending") {
                toolbarRef.current.setButtonDisabled("uploadDocument", false);
                toolbarRef.current.setButtonDisabled("update", false);
            } else {
                toolbarRef.current.setButtonDisabled("uploadDocument", true);
                toolbarRef.current.setButtonDisabled("update", true);
            }

        } else {
            this.setState({
                selectedResearchVisit: null,
                message: "",
                messageStyle: "",
                hasOutcomeReport: false
            });

            toolbarRef.current.setButtonDisabled("uploadDocument", true);
            toolbarRef.current.setButtonDisabled("update", true);
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("download", true);
        }
    }

    successfulResearchVisitRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedResearchVisit: null, removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulResearchVisitRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ removeMessage: response.Message, removeMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeResearchVisit(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedResearchVisit}&employerId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulResearchVisitRemoveCallback, this.unsuccessfulResearchVisitRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    dialogUploadActionHandler(isCancel, added) {
        if (isCancel === true) {
            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                updateStatusDialogOpen: false, selectedResearchVisit: null, updateDocumentDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Employer > Research Visits" + (this.props.location.state !== null ? " > " + this.props.location.state.employerName + (this.props.location.state.employerSDLNo !== null ? " - " + this.props.location.state.employerSDLNo : "") : "> Add")}
                </p>
                <AlertItem message={this.state.unlinkMessage} alertStyle={this.state.unlinkMessageStyle} />
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />
                    {this.state.showTable === true && (
                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/employerresearchvisit/GetEmployerResearchVisits`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Update Research Visit Status"}
                    viewRef={this}
                    entityId={this.state.selectedResearchVisit}
                    formRef={statusFormRef}
                    usePut={true}
                    saveUrl={"api/sims/employerresearchvisit/updateResearchVisitStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <StatusForm
                        ref={statusFormRef}
                        id={this.state.selectedResearchVisit}
                        hasOutComeReportFlag={this.state.hasOutcomeReport}
                        controllerName={"employerresearchvisit"}
                        dataURL='api/sims/employerresearchvisit/GetFindResearchVisitStatus'
                    />
                </FormDialog>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Add employer research visit schedule"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityInfo={{ SDLNumber: this.props.location.state.employerSDLNo, EmployerName: this.props.location.state.employerName }}
                    saveUrl={"api/sims/employerResearchVisit/InsertEmployerResearchVisit"}
                    usePost={true}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <EmployerAddResearchVisitForm ref={addFormRef} />
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeResearchVisit("api/sims/employerresearchvisit/RemoveEmployerResearchVisit") }}
                    closePrompt={this.closePrompt}
                />
                <FormDialog
                    open={this.state.updateDocumentDialogOpen}
                    dialogTitle={"Upload Research Visit Document"}
                    viewRef={this}
                    entityId={this.state.selectedResearchVisit}
                    formRef={documentFormRef}
                    hideSave={true}
                    usePut={true}
                    saveUrl={"api/sims/employerresearchvisit/UpdateResearchVisitStatus"}
                    closeHandler={(isCancelled, linked) => this.dialogUploadActionHandler(isCancelled, linked)}
                >
                    <ResearchOutcomeReportForm ref={documentFormRef} id={this.state.selectedResearchVisit} controllerName='employerresearchvisit' />
                </FormDialog>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(EmployerResearchVisitsSearch)
);