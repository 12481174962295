import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";

const styles = makeStyles({
    TextArea: {
        '& .MuiOutlinedInput-inputMultiline': {
            height: "150px !important",
            width: "550px !important"
        }
    }
})

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const SkillsRejectionReasonForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (

        <EntityForm ref={ref}
                    viewId="queryreason"
                    dataURL='api/sims/skillsrejectionreasons/getfind'
                    values={props.data}
                    entityLookupId={props.id}
                    entityLookupController={props.entityLookupController} org="saseta"
                    useCustomSettings={false}
                    dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="SkillsRejectionReasonForm">
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Rejection Reason" {...a11yProps(0)} />
                            </Tabs>
                        </AppBar> 
                    <TabPanel value={tabValue} index={0}>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>

                                        <SelectItem
                                            dataUrl="api/sims/skillsrejectionreasons/GetFinancialYearLookupListSpecific"
                                            id="FinancialYearID"
                                            key={tabValue}
                                            label="Financial Year"
                                            // className={classes.MuiSelectSelectMenu}
                                            className="w-100"
                                            required={true}
                                            form={form}
                                            disabled={props.id}
                                        />

                                    </td>
                                </tr>
                            <tr>
                                <td>

                                        <SelectItem
                                            dataUrl="api/sims/skillsrejectionreasons/GetLookupList?listId=CRMLetterGrantTypeID"
                                            id="CRMLetterGrantTypeID"
                                            key={tabValue}
                                            label="CRM Letter Grant Type"
                                            // className={classes.MuiSelectSelectMenu}
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />

                                </td>
                            </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="Name"
                                            key={tabValue}
                                            label="Name"
                                            className="w-100"
                                            required={true}
                                            form={form}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="Description"
                                            key={tabValue}
                                            label="Description"
                                            className="w-100"
                                            required={true}
                                            multiline
                                            styles={styles()}
                                            form={form}
                                        />
                                    </td>
                                </tr>                               
                            </tbody>
                        </table>
                    </TabPanel>

                </form>
            }
            </EntityForm>
           
    );
})

