import React from 'react';
import { CustomTextField } from '../../../../widgets/CustomTextField';
import { EntityForm } from '../../../../widgets/Forms';
import ViewUtils from '../../../../ViewUtils';
import TabPanel from '../../../../widgets/TabPanel';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import OFOSearch from '../../Controls/OFOSearch';
import { LookupField } from '../../../../widgets/LookupField';
import Table from '@material-ui/core/Table';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

const styles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            minWidth: 50,
            textAlign: 'center',
        },
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 200,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
        minWidth: 200
    },
    inputForm: {
        minWidth: '200px',
    },
    table: {
        minWidth: 700,
    },
    inputCenter: {
        textAlign: 'center'
    },
    paper: {
        position: "absolute",
        width: 600,
        height: 450,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(0.1)
    }
}));

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
        height: 6
    },
    body: {
        fontSize: 11,
    },

}))(TableCell);

const StyledTableTextCell = withStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            // width: 50,
            textAlign: 'center'
        },
    },
    body: {
        fontSize: 14,
        fontWeight: 500
    }

}))(TableCell);


const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    table: {
        minWidth: 700,
    },
});

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 5;
    const left = 5;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`
    };
}

export const EmploymentNumberForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm
        ref={ref} 
        viewId={null}
        values={props.data}
        entityLookupController={props.entityLookupController}
        org="saseta"
        entityLookupId={props.id}
            useCustomSettings={false}
            dataArrivedHandler={props.dataArrivedHandler} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="EmploymentNumber">
                    <div className="row w-100">
                        <div className="col w-100">
                            <TabPanel value={tabValue} index={0} className=''>
                                <table cellPadding="2" width="80%">
                                    <tbody>
                                        <tr>
                                            <td width="40%">
                                                <LookupField
                                                    id="OFOOccupationID"
                                                    required
                                                    label="OFO Occupation"
                                                    className="w-100"
                                                    form={form}
                                                >
                                                    <OFOSearch showTitle={true} mode='lookup' dataUrl="api/sims/WSPATRForms2025/GetWSPATRFormOFOCodeSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("OFOOccupationID", value)
                                                        }} autoFetchData={false} selection={props.selection}
                                                        WSPATRID={props.WSPATRID} />
                                                </LookupField>
                                            </td>
                                            <td width="70%">&nbsp;</td>
                                        </tr>
                                    </tbody>
                                </table>

                                <Table className={styles.table} size="small" >
                                    <TableHead>

                                        <TableRow>
                                            <StyledTableCell align="center" colSpan={3}>African</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Coloured</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Indian/Asian</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>White</StyledTableCell>
                                            <StyledTableCell align="center" colSpan={3}>Age Group</StyledTableCell>
                                        </TableRow>

                                        <TableRow>
                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >M</StyledTableCell>
                                            <StyledTableCell align="center">F</StyledTableCell>
                                            <StyledTableCell align="center">D</StyledTableCell>

                                            <StyledTableCell align="center" >{"<"}35</StyledTableCell>
                                            <StyledTableCell align="center">35-55</StyledTableCell>
                                            <StyledTableCell align="center">{">"}55</StyledTableCell>
                                        </TableRow>

                                    </TableHead>
                                    <TableBody>
                                        <StyledTableRow color="primary">
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="AfricanDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="ColouredDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="IndianMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="IndianFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="IndianDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteMale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteFemale" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="WhiteDisabled" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                            <StyledTableTextCell align="center"><CustomTextField id="Age1" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="Age2" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>
                                            <StyledTableTextCell align="center"><CustomTextField id="Age3" variant="outlined" styles={{ minWidth: "45px" }} size="small" required validationRegex={/^[0-9]+$/g} form={form} /></StyledTableTextCell>

                                        </StyledTableRow>

                                    </TableBody>
                                </Table>
                                </TabPanel>
                        </div>  
                    </div>
                </form >
            }
        </EntityForm >
    );
})