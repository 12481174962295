import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, {FormTabContainer} from '../../widgets/TabPanel';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { CustomTextField } from '../../widgets/CustomTextField';
import { LookupField } from '../../widgets/LookupField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { EntityForm } from '../../widgets/Forms';
import ModuleSetupSearch from './ModuleSetupSearch';
import ViewUtils from '../../ViewUtils';

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}
const utils = new ViewUtils();
export const ModuleForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleReplacementChange = form => event => {
        if (!event.target.checked) {
            form.values['ReplacementModuleID'] = null;
            form.setLookupSelection("ReplacementModuleID", null);
        }

        form.updateValue('IsReplacement', event.target.checked);
    };

    const handleReRegisteredChange = form => event => {
        if (!event.target.checked) {
            form.values['NewRegistrationStartDate'] = null;
            form.values['NewRegistrationEndDate'] = null;
            form.values['NewLastEnrolmentDate'] = null;
            form.values['NewLastAchievementDate'] = null;
        }

        form.updateValue('IsReregistered', event.target.checked);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }
    const dateChange = (form, date) => {

            if (!utils.isNull(form.values.RegistrationStartDate)) {
                utils.autoSetDate(props.viewRef, ref, form.values.RegistrationStartDate, 5, 0, 1, "RegistrationEndDate", "RegistrationStartDate")
            }

            if (!utils.isNull(form.values.RegistrationEndDate)) {
                utils.autoSetDate(props.viewRef, ref, form.values.RegistrationStartDate, 6, 0, -1, "LastEnrolmentDate", "RegistrationStartDate")
            }
            if (!utils.isNull(form.values.LastEnrolmentDate)) {
                utils.autoSetDate(props.viewRef, ref, form.values.RegistrationStartDate, 9, 0, -1, "LastAchievementDate", "RegistrationStartDate")
            }
    }
    const newRegdateChange = (form, date) => {

            if (!utils.isNull(form.values.NewRegistrationStartDate)) {
                utils.autoSetDate(props.viewRef, ref, form.values.NewRegistrationStartDate, 5, 0, 1, "NewRegistrationEndDate", "NewRegistrationStartDate")
            }

            if (!utils.isNull(form.values.NewRegistrationEndDate)) {
                utils.autoSetDate(props.viewRef, ref, form.values.NewRegistrationStartDate, 6, 0, -1, "NewLastEnrolmentDate", "NewRegistrationStartDate")
            }

            if (!utils.isNull(form.values.NewLastEnrolmentDate)) {
                utils.autoSetDate(props.viewRef, ref, form.values.NewRegistrationStartDate, 9, 0, -1, "NewLastAchievementDate", "NewRegistrationStartDate")
            }
    }

    return (
        <EntityForm ref={ref} viewId="ModuleSetup"
            dataURL='api/sims/Modulesetupdetails/getfind'
            values={props.data}
            defaultHandlerObj={props.defaultHandlerObj}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="ModuleForm">
                   <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Module Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }}/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="QCTOModuleID"
                                                key={tabValue}
                                                parentId={0}
                                                label="QCTO Module Id"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={props.id}
                                                
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="QCTOModuleTitle"
                                                key={tabValue}
                                                label="QCTO Module Title"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/Modulesetupdetails/GetLookupList?listId=nqfLevel"
                                                id="NQFLevelID"
                                                key={tabValue}
                                                label="NQF Level"
                                                className="w-100"
                                                required
                                                form={form} 
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/Modulesetupdetails/GetLookupList?listId=moduletype"
                                                id="ModuleTypeID"
                                                key={tabValue}
                                                label="Module Type"
                                                className="w-100"
                                                required
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Credits"
                                                validationRegex={/^[0-9]+$/g}
                                                validationMessage="This field must be numeric"
                                                key={tabValue}
                                                label="Credits"
                                                className="w-100 "
                                                required
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />

                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationStartDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Registration Start Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                onChange={dateChange}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="RegistrationEndDate"
                                                validationGroup="registrationDates"
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.RegistrationStartDate)
                                                            && formValues.RegistrationStartDate.getTime() < formValues.RegistrationEndDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                key={tabValue}
                                                label="Registration End Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="LastEnrolmentDate"
                                                validationGroup="enrolmentDates"
                                                key={tabValue}
                                                label="Last Enrolment Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="LastAchievementDate"
                                                validationGroup="enrolmentDates"
                                                key={tabValue}
                                                label="Last Achievement Date"
                                                className='{classes.textField} w-100 '
                                                required
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.LastEnrolmentDate)
                                                            && formValues.LastEnrolmentDate.getTime() < formValues.LastAchievementDate.getTime()
                                                    }, message: "Last achievement date must be greater than last enrolment date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomCheckbox
                                                id="IsReregistered"
                                                key={tabValue}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                onChange={handleReRegisteredChange}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Is Re-registered?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/Modulesetupdetails/GetLookupList?listId=QualityAssuranceBody"
                                                id="QualityAssuranceBodyID"
                                                key={tabValue}
                                                label="Quality Assurance Body"
                                                className="w-100"
                                                required
                                                form={form}
                                                disabled={!props.editPermission} />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="NewRegistrationStartDate"
                                                validationGroup="newRegistrationDates"
                                                key={tabValue}
                                                label="New Registration Start Date"
                                                className='{classes.textField} w-100 '
                                                required={form.values.IsReregistered}
                                                disabled={!form.values.IsReregistered}
                                                InputProps={{
                                                    readOnly: true,
                                                    disabled: !form.values.IsReregistered
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                onChange={newRegdateChange}
                                            />
                                        </td> 
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="NewRegistrationEndDate"
                                                validationGroup="newRegistrationDates"
                                                key={tabValue}
                                                required={form.values.IsReregistered}
                                                disabled={!form.values.IsReregistered}
                                                label="New Registration End Date"
                                                className='{classes.textField} w-100 '
                                                validator={{
                                                    script: (formValues) => {
                                                        return !formValues.IsReregistered || (formValues.IsReregistered && !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.NewRegistrationStartDate)
                                                            && formValues.NewRegistrationStartDate.getTime() < formValues.NewRegistrationEndDate.getTime())
                                                    }, message: "New registration end date  must be greater than new registration start date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                    disabled: !form.values.IsReregistered
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="NewLastEnrolmentDate"
                                                validationGroup="newEnrolmentDates"
                                                key={tabValue}
                                                required={form.values.IsReregistered}
                                                disabled={!form.values.IsReregistered}
                                                label="New Last Enrolment Date"
                                                className='{classes.textField} w-100 '
                                                InputProps={{
                                                    readOnly: true,
                                                    disabled: !form.values.IsReregistered
                                                }}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="NewLastAchievementDate"
                                                validationGroup="newEnrolmentDates"
                                                key={tabValue}
                                                required={form.values.IsReregistered}
                                                disabled={!form.values.IsReregistered}
                                                label="New Last Achievement Date"
                                                className='{classes.textField} w-100 '
                                                validator={{
                                                    script: (formValues) => {
                                                        return !formValues.IsReregistered || (!utils.isNull(formValues)
                                                            && !utils.isNull(formValues.NewLastEnrolmentDate)
                                                            && formValues.NewLastEnrolmentDate.getTime() < formValues.NewLastAchievementDate.getTime())
                                                    }, message: "New achievement end date must be greater than new enrolment last enrolment date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                    disabled: !form.values.IsReregistered
                                                }}
                                                form={form}
                                                disabled={!props.editPermission}
                                            />
                                        </td>
                                        <td>
                                            <CustomCheckbox
                                                id="IsReplacement"
                                                key={tabValue}
                                                form={form} 
                                                disabled={!props.editPermission}
                                                onChange={handleReplacementChange}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Is Replacement?"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="ReplacementModuleID"
                                                label="Module To Be Replaced"
                                                className="w-100"
                                                required={form.values.IsReplacement}
                                                disabled={!form.values.IsReplacement}
                                                form={form} 
                                                disabled={!props.editPermission}
                                            >
                                                <ModuleSetupSearch showTitle={true} mode='lookup'
                                                    onLookupValueChange={(value) => form.setLookupSelection("ReplacementModuleID", value)} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                </tbody>
                                </table>
                            </TabPanel>
                        </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
