import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../crm/CRM.css";
import EnhancedToolbar from '../../widgets/Toolbar';
import ViewUtils from '../../ViewUtils';
import { EntityForm } from '../../widgets/Forms';
import { SelectItem } from '../../widgets/SelectItem';
import Comments from "../crm/Comments";
import Documents from "../crm/Documents";
import Emails from "../crm/Emails";
import Sms from "../crm/Sms";
import CommentsDialog from "../crm/CommentsDialog";
import DocumentsDialog from "../crm/DocumentsDialog";
import EmailDialog from "../crm/EmailDialog";
import SmsDialog from "../crm/SmsDialog";
import { ViewContainer } from "./../../View.jsx";
import CardLayout from "../../widgets/Card";
import { CustomTextField } from '../../widgets/CustomTextField';
import { IconButton } from "@material-ui/core";
import { SearchIcon } from "@material-ui/data-grid";
import TextField from '@material-ui/core/TextField';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const config = {
    moduleName: "Employer",

    dataUrls: {
        comments: "api/sims/employercrm/GetCRMComments",
        documents: "api/sims/employercrm/GetCRMDocuments",
        emails: "api/sims/employercrm/GetCRMEMail",
        sms: "api/sims/employercrm/GetCRMSMS"
    },

    postUrls: {
        comments: "api/sims/employercrm/AddCRMComment",
        documents: "api/sims/employercrm/AddCRMDocument",
        emails: "api/sims/employercrm/AddCRMEmail",
        sms: "api/sims/employercrm/AddCRMSMS"
    }
}

class EmployerCRM extends Component {

    state = {
        CommunicationType: "1",
        dialogues: {
            commentsDialogOpen: false,
            emailsDialogOpen: false,
            documentsDialogOpen: false,
            smsDialogOpen: false,

            // handling email Search
            emailAddress: '',
            emailSubject: '',
        },
        model: {}
    }
    search() {
        alert("Search Button Clicke")
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/employer',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("add" === buttonId) {

            if (this.state.CommunicationType === "1") {
                this.setState({
                    dialogues: {
                        commentsDialogOpen: true,
                        emailsDialogOpen: false,
                        documentsDialogOpen: false,
                        smsDialogOpen: false,
                    }
                })
            }
            else if (this.state.CommunicationType === "2") {
                this.setState({
                    dialogues: {
                        commentsDialogOpen: false,
                        emailsDialogOpen: false,
                        documentsDialogOpen: true,
                        smsDialogOpen: false,
                    }
                })
            }
            else if (this.state.CommunicationType === "3") {
                this.setState({
                    dialogues: {
                        commentsDialogOpen: false,
                        emailsDialogOpen: true,
                        documentsDialogOpen: false,
                        smsDialogOpen: false,
                    }
                })
            } else if (this.state.CommunicationType === "4") {
                this.setState({
                    dialogues: {
                        commentsDialogOpen: false,
                        emailsDialogOpen: false,
                        documentsDialogOpen: false,
                        smsDialogOpen: true,
                    }
                })
            }
        }
    }

    postAddHandler(submitted) {
        if (this.state.CommunicationType === "1") {
            this.setState({
                dialogues: {
                    commentsDialogOpen: submitted,
                    emailsDialogOpen: false,
                    smsDialogOpen: false,
                    documentsDialogOpen: false
                }
            })
        }
        else if (this.state.CommunicationType === "3") {
            this.setState({
                dialogues: {
                    commentsDialogOpen: false,
                    emailsDialogOpen: submitted,
                    documentsDialogOpen: false,
                    smsDialogOpen: false,
                }
            })
        } else if (this.state.CommunicationType === "4") {
            this.setState({
                dialogues: {
                    commentsDialogOpen: false,
                    emailsDialogOpen: false,
                    documentsDialogOpen: false,
                    smsDialogOpen: submitted
                }
            })
        }
    }

    handleCellSelect(rowid, headCell,section) {
        if (headCell.contentType === "document") {
            const link = document.createElement('a');
            link.href = '/api/indicium/Employer/DownloadCRM' + section + '?documentID=' + rowid;
            link.download = 'download';
            link.target = '_blank';
            link.click();
        }
    }


    FileDownload = (rowid, headCell, section) => {
        if (headCell.contentType === "document") {
            utils.invokeUrl("api/sims/employercrm/DownloadCRM" + section + "?documentID=" + rowid, (response) => {

                var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
            }
            );
        }
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    postUploadHandler(save, cancel) {
        //Successful upload
        if (save === true) {
            this.setState({ dialogues: { commentsDialogOpen: false, emailsDialogOpen: false, documentsDialogOpen: false } })
        }
        //Close dialog
        else if (cancel === true) {
            this.setState({ dialogues: { commentsDialogOpen: false, emailsDialogOpen: false, documentsDialogOpen: false } })
        }
        //Error on upload
        else {
            //TODO: set message that gets displayed to user after closing the dialog
            this.setState({ dialogues: { commentsDialogOpen: false, emailsDialogOpen: false, documentsDialogOpen: false } })
        }
    }


    SetCommunicationType(values) {
        if (!utils.isNull(values) && !utils.isNull(values.CommunicationType)) {
            let type = values.CommunicationType.Id;

            if (type !== this.state.CommunicationType) {
                this.setState({ CommunicationType: type });
            }
        }
    }

    render() {
        return (
            <CardLayout title="">

                <div >
                    <p className="breadcrumbs">
                        {"Employer > CRM" + (this.props.location.state !== null ? " > " + this.props.location.state.employerName + (this.props.location.state.employerSDLNo !== null ? " - " + this.props.location.state.employerSDLNo : "") : "> Add")}
                    </p>

                    {
                        !utils.isStringEmpty(this.props.saveMessage) ?
                            <p className={this.props.hasSaveError ? "errorMessage" : "message"}>
                                {this.props.saveMessage}
                            </p>
                            :
                            ""
                    }

                    <EntityForm viewId="learnerProgramIntervension" values={{}} useCustomSettings={false} valueChangeHandler={(values) => { this.SetCommunicationType(values) }}>
                        {form =>


                            <form className={form.classes.container} id="learnerProgramIntervensionForm">
                                <table>
                                    <tr>
                                        <td>
                                            <div className="col form-col">
                                                <SelectItem
                                                    dataUrl="api/sims/employercrm/GetCRMCommunicationTypeLookupList"
                                                    id="CommunicationType"
                                                    label="Communication Type"
                                                    className="{classes.select} w-100"
                                                    form={form} />
                                            </div>
                                        </td>
                                        {this.state.CommunicationType === "3" && (
                                            <React.Fragment>
                                                <td>
                                                    <div className="col form-col">

                                                        <TextField
                                                            size="small"
                                                            id="emailAddress"
                                                            label="Email"
                                                            className="w-100"
                                                            // value={this.state._emailSubject}
                                                            // onChange={e=>{this.setState({_emailSubject: e.target.value})}}
                                                            placeholder={'Email'}
                                                            multiline
                                                            form={form}
                                                            variant="outlined" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="col form-col">

                                                        <TextField
                                                            size="small"
                                                            id="emailSubject"
                                                            label="Subject"
                                                            className="w-100"
                                                            // value={this.state._emailSubject}
                                                            // onChange={e=>{this.setState({_emailSubject: e.target.value})}}
                                                            placeholder={'Subject'}
                                                            multiline
                                                            form={form}
                                                            variant="outlined" />
                                                    </div>
                                                </td>
                                                <td>
                                                    <IconButton
                                                        onClick={() => {
                                                            // Please leave it like this, i had to make sure state are updated
                                                            let email = document.getElementById('emailAddress').value
                                                            let subject = document.getElementById('emailSubject').value
                                                            this.setState({ emailAddress: email, emailSubject: subject }, () => {
                                                                this.setState({ emailAddress: email, emailSubject: subject })
                                                            })

                                                        }}
                                                        aria-label="search">
                                                        <SearchIcon />
                                                    </IconButton>
                                                </td>
                                            </React.Fragment>
                                        )}

                                    </tr>
                                </table>
                            </form>
                        }
                    </EntityForm>

                    {
                        this.state.CommunicationType === "1" ?
                            <div className="searchResults">
                                <p className="legend">
                                    Comments
                            </p>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={{
                                        items: [
                                            {
                                                type: "buttonGroup",
                                                buttons: [
                                                    { id: 'close', label: '', tooltip: 'Back to Employer Details', icon: 'CloseIcon' },
                                                ]
                                            },
                                            {
                                                type: "devider"
                                            },
                                            {
                                                type: "buttonGroup",
                                                buttons: [
                                                    { id: 'add', label: '', tooltip: 'Add a comment', icon: 'AddCommentIcon', visible: this.props.menuPermissionItem.Add }
                                                ]
                                            }
                                        ]
                                    }} />
                                <Comments commentsDataUrl={config.dataUrls.comments} moduleName={config.moduleName} selection={this.props.location.state.selection} />
                            </div>
                            :
                            ""
                    }

                    <div style={{ width: "100%", height: "76%", padding: "8px", overflow: "hidden!important" }}>



                        {
                            this.state.CommunicationType === "2" ?
                                <div className="searchResults">
                                    <p className="legend">
                                        Documents
                                    </p>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={{
                                        items: [
                                            {
                                                type: "buttonGroup",
                                                buttons: [
                                                    { id: 'close', label: '', tooltip: 'Back to Employer Details', icon: 'CloseIcon' },
                                                ]
                                            },
                                            {
                                                type: "devider"
                                            },
                                            {
                                                type: "buttonGroup",
                                                buttons: [
                                                    { id: 'add', label: '', tooltip: 'Upload Document', icon: 'UploadIcon', visible: this.props.menuPermissionItem.Add }
                                                ]
                                            }
                                        ]
                                    }} />
                                    <Documents handleCell={(rowid, headCell) => this.FileDownload(rowid, headCell,"Document")} documentsDataUrl={config.dataUrls.documents} moduleName={config.moduleName} selection={this.props.location.state.selection} />
                                </div>
                                :
                                ""
                        }

                        {
                            this.state.CommunicationType === "3" ?
                                <div className="searchResults">
                                    <p className="legend">
                                        Emails
                                    </p>      
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={{
                                        items: [
                                            {
                                                type: "buttonGroup",
                                                buttons: [
                                                    { id: 'close', label: '', tooltip: 'Back to Employer Details', icon: 'CloseIcon' },
                                                ]
                                            },
                                            {
                                                type: "devider"
                                            },
                                            {
                                                type: "buttonGroup",
                                                buttons: [
                                                    { id: 'add', label: '', tooltip: 'Send Email', icon: 'EmailIcon', visible: this.props.menuPermissionItem.Add }
                                                ]
                                            }
                                        ]
                                    }} />
                                    <Emails
                                        handleCell={(rowid, headCell) => this.FileDownload(rowid, headCell, "EmailDocument")}
                                        emailsDataUrl={config.dataUrls.emails}
                                        moduleName={config.moduleName}
                                        selection={this.props.location.state.selection}
                                        Email={this.state.emailAddress}
                                        Subject={this.state.emailSubject}
                                    />
                                </div>
                                :
                                ""
                        }

                        {
                            this.state.CommunicationType === "4" ?
                                <div className="searchResults">
                                    <p className="legend">
                                        SMS
                                    </p>       
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                config={{
                                    items: [
                                        {
                                            type: "buttonGroup",
                                            buttons: [
                                                { id: 'close', label: '', tooltip: 'Back to Employer Details', icon: 'CloseIcon' },
                                            ]
                                        },
                                        {
                                            type: "devider"
                                        },
                                        {
                                            type: "buttonGroup",
                                            buttons: [
                                                { id: 'add', label: '', tooltip: 'Send SMS', icon: 'SmsIcon', visible: this.props.menuPermissionItem.Add }
                                            ]
                                        }
                                    ]
                                }} />
                                    <Sms smsDataUrl={config.dataUrls.sms} moduleName={config.moduleName} selection={this.props.location.state.selection} />
                                </div>
                                :
                                ""
                        }

                        {
                            <div>
                                <CommentsDialog
                                    open={this.state.dialogues.commentsDialogOpen}
                                    closeHandler={(added) => this.postAddHandler(added)}
                                    postUrl={config.postUrls.comments}
                                    EntityID={this.props.location.state.selection} />
                                <DocumentsDialog
                                    open={this.state.dialogues.documentsDialogOpen}
                                    closeHandler={(save, cancel) => this.postUploadHandler(save, cancel)}
                                    postUrl={config.postUrls.documents}
                                    EntityID={this.props.location.state.selection}
                                />
                                <EmailDialog
                                    open={this.state.dialogues.emailsDialogOpen}
                                    closeHandler={(added) => this.postAddHandler(added)}
                                    postUrl={config.postUrls.emails}
                                    EntityID={this.props.location.state.selection}
                                />
                                <SmsDialog
                                    open={this.state.dialogues.smsDialogOpen}
                                    closeHandler={(added) => this.postAddHandler(added)}
                                    postUrl={config.postUrls.sms}
                                    EntityID={this.props.location.state.selection}
                                />
                            </div>
                        }

                    </div>









                </div>

            </CardLayout>
        );
    }
}
export default withRouter(withMenuPermission(EmployerCRM));
