import React, { Component, Fragment } from "react";
import { withRouter } from 'react-router-dom';
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import Button from '@material-ui/core/Button';
import withMenuPermission from "../../widgets/withMenuPermission";
import { withStyles, makeStyles } from '@material-ui/core/styles';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import './Style.css'
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import LinkDialog from "../../etqa/LinkDialog";
import DGLetterReasonDialog from "../application/Controls/DGLetterReasonDialog";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to DG Evaluation Dashboard', tooltip: '' }
            ]
        }
    ]
};

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#01579B',
        color: theme.palette.common.white,
        border: '1px solid white',
        fontSize: 11,
        padding: 5
    },
    body: {
        fontSize: 11,
        padding: 5
    },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
    root: {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
            height: theme.palette.action.main
        },
    },
}))(TableRow);

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    }
});

class DGEvaluationMain extends Component {
    constructor(props) {
        super(props)
        this.state = {
            objectProps: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.object)) ? this.props.location.state.object : null,
            formObject: null,
            lookupEArray: [],
            lookupQEArray: [],
            FinancialYear: '',
            OrganisationName: '',
            Status: '',
            model: {
                Name: "",
            },
            title: "",
            id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.DGApplicationID)) ? this.props.location.state.DGApplicationID : null,
            OrganisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.OrganisationID)) ? this.props.location.state.OrganisationID : null,
            FinancialYearID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.FinancialYearID)) ? this.props.location.state.FinancialYearID : null,
            EvaluationPermission: "",
            searchParameters: [{ Name: "DGApplicationID", Value: this.props.location.state.DGApplicationID }, { Name: "OrganisationID", Value: this.props.location.state.OrganisationID }, { Name: "FinancialYearID", Value: this.props.location.state.FinancialYearID }],
            OpenDialog: false,
            formViewURL: "",
            formName: "",
            linkDialogOpen: false,
        };
    }

    componentDidMount() {
        this.getHeaders(this.state.id)
        this.getEvaluation(this.state.id)
        this.getlookup(this.state.id)
        this.CheckEvaluationPermissions(this.state.id);
        this.getEvaluationStatusList(this.state.id)
    }

    getHeaders = (id) => {
        let url = "api/sims/dgevaluation/getfindheader?DGApplicationID=" + id;

        utils.invokeUrl(
            url,
            (response) => {
                this.setState({
                    FinancialYear: response.FinancialYear,
                    OrganisationName: response.OrganisationName,
                    SDLNumber: response.SDLNumber
                })
            },
            null,
            {
                method: "GET",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + localStorage.getItem("token"),
                },
            }
        );
    };

    getEvaluation(id) {
        let url = `api/sims/dgevaluation/GetDGEvaluation?request={"searchParameters":[{"Name":"DGApplicationID","Value":${this.state.id}}]}`;
        utils.invokeUrl(url, (response) => {
            this.setState({
                formObject: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    getEvaluationStatusList(id) {
        let url = `api/sims/dgEvaluation/GetDGEvaluationStatusList?request={"searchParameters":[{"Name":"DGApplicationID","Value":${this.state.id}},{"Name":"OrganisationID","Value":${this.state.OrganisationID}},{"Name":"FinancialYearID","Value":${this.state.FinancialYearID.Id}}]}`;
        utils.invokeUrl(url, (response) => {
            this.setState({
                EvaluationStatusListObject: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    getlookup(id) {

        let urlE = "api/sims/dgevaluation/GetLookupListDGEvaluationStatus";
        utils.invokeUrl(urlE, (response) => {
            this.setState({ lookupEArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )

        let urlQE = "api/sims/dgevaluation/GetLookupListDGQualifictionEvaluationStatus";
        utils.invokeUrl(urlQE, (response) => {
            this.setState({ lookupQEArray: response })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    CheckEvaluationPermissions(id) {
        let url = "api/sims/DGEvaluation/GetDGEvaluationPermissionCheck?DGApplicationID=" + this.state.id;
        utils.invokeUrl(url, (response) => {
            this.setState({
                EvaluationPermission: response.Result
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleChecklistArrayQN = (event, index) => {
        const values = [...this.state.formObject.DGEvaluationInterventionDto.DGEvaluationInterventionData];
        const indexObject = { ...values[index] };
        indexObject.NumberRecommendedForApproval = event.target.value;
        values[index] = indexObject;
        const newDGEvaluationInterventionDto = { ...this.state.formObject.DGEvaluationInterventionDto };
        newDGEvaluationInterventionDto.DGEvaluationInterventionData = values;
        const finalObject = { ...this.state.formObject, DGEvaluationInterventionDto: newDGEvaluationInterventionDto }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayQV = (event, index) => {
        const values = [...this.state.formObject.DGEvaluationInterventionDto.DGEvaluationInterventionData];
        const indexObject = { ...values[index] };
        indexObject.QualificationEvaluationStatusID = event.target.value;
        values[index] = indexObject;
        const newDGEvaluationInterventionDto = { ...this.state.formObject.DGEvaluationInterventionDto };
        newDGEvaluationInterventionDto.DGEvaluationInterventionData = values;
        const finalObject = { ...this.state.formObject, DGEvaluationInterventionDto: newDGEvaluationInterventionDto }
        this.setState({ formObject: finalObject });
    }

    handleChecklistArrayQC = (event, index) => {
        const values = [...this.state.formObject.DGEvaluationInterventionDto.DGEvaluationInterventionData];
        const indexObject = { ...values[index] };
        indexObject.QualificationEvaluationComments = event.target.value;
        values[index] = indexObject;
        const newDGEvaluationInterventionDto = { ...this.state.formObject.DGEvaluationInterventionDto };
        newDGEvaluationInterventionDto.DGEvaluationInterventionData = values;
        const finalObject = { ...this.state.formObject, DGEvaluationInterventionDto: newDGEvaluationInterventionDto }
        this.setState({ formObject: finalObject });
    }

    handleStatus = (event) => {
        const finalObject = { ...this.state.formObject, DGStatusID: { Id: event.target.value } }
        this.setState({ formObject: finalObject });
    }

    handleComment = (event) => {
        const finalObject = { ...this.state.formObject, Comments: event.target.value }
        this.setState({ formObject: finalObject });
    }

    CheckStatus = () => {
        if (this.state.formObject.DGStatusID.Id === "4" || this.state.formObject.DGStatusID.Id === 4) {
            this.setState({
                linkDialogOpen: true,
                linkDialogTitle: 'Query Reasons',
                LinkDialogName: 'QueryReasons',
                NewDGStatusID: 4,
                message: ""
            })
        }
        else if (this.state.formObject.DGStatusID.Id === "5" || this.state.formObject.DGStatusID.Id === 5) {
            this.setState({
                linkDialogOpen: true,
                linkDialogTitle: 'Rejection Reasons',
                LinkDialogName: 'RejectionReasons',
                NewDGStatusID: 5,
                message: ""
            })
        }
        else { this.SaveEvaluation(null) }

    }

    SaveEvaluation = (selection) => {

        let ReasonsList = "";
        if (selection !== null) {
            for (let i = 0; i < selection.length; i++) {
                if (i === 0) { ReasonsList += selection[i].Id; }
                else { ReasonsList += "," + selection[i].Id; }

            }
        }

        let SaveObject = { ...this.state.formObject, Reasons: ReasonsList };
        this.setState({ formObject: SaveObject });

        let url = "api/sims/dgevaluation/UpdateDGEvaluation";
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(SaveObject)
            }
        )
    }

    postLinkHandler(isCancel, linked) {
        if (linked === true) {

            this.setState({
                linkDialogOpen: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters))
            });
        }
        else if (isCancel === true) {
            this.setState({
                linkDialogOpen: false
            })
        }
    }

    successCallback(response) {

        if (response.MessageType === "WARNING") {
            let errResult = JSON.parse(response.Message);
            let message = "";
            errResult.map((item, index) => (
                message += item.Item1 + ' : ' + item.Item2 + '\n'
            ))
            this.setState({ message: message, messageStyle: response.MessageType });
        }
        else {
            this.setState({ message: response.Message, messageStyle: response.MessageType });
            this.getEvaluation(this.state.id)
            this.getEvaluationStatusList(this.state.id)
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
    }

    close() {
        this.props.history.push({
            pathname: '/DGEvaluationSearch',
            state: {
                organisationID: this.state.OrganisationID,
                DGApplicationID: this.state.DGApplicationID,
                FinancialYearID: this.state.FinancialYearID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber
            },
        });
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        }
    }

    render() {

        const { classes } = this.props;

        const inttableheader = !utils.isNull(this.state.formObject) ? this.state.formObject.DGEvaluationInterventionDto.FriendlyColumnNames.map((item, index) => (
            item === "ID" ? <StyledTableCell align="left">#</StyledTableCell> : <StyledTableCell>{item}</StyledTableCell>
        )) : null;

        const inttablerows = !utils.isNull(this.state.formObject) ? this.state.formObject.DGEvaluationInterventionDto.DGEvaluationInterventionData.map((item, index) => (
            <StyledTableRow key={item.ID}>
                {Object.keys(item).map((innerItem, indexObject) => (
                    indexObject === 0 ? <StyledTableCell component="th" scope="row">
                        {indexObject + 1}
                    </StyledTableCell> :
                        innerItem === "QualificationEvaluationStatusID" ?
                            <StyledTableCell align="left" component="th" scope="row" >
                                <TextField className="w-30" id={"QualificationEvaluationStatusID" + item.ID} value={item.QualificationEvaluationStatusID} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayQV(values, index)} >
                                    <MenuItem value={0} key={0} >  </MenuItem>
                                    {this.state.lookupQEArray.map((lookupitem, index) => (
                                        <MenuItem value={lookupitem.Id} key={lookupitem.Id} >{lookupitem.Description}</MenuItem>
                                    ))}
                                </TextField>
                            </StyledTableCell> :
                            innerItem === "QualificationEvaluationComments" ?
                                <StyledTableCell align="center" component="th" scope="row" >
                                    <TextField id={"QualificationEvaluationComments" + item.Number} multiline rows={2} value={item.QualificationEvaluationComments} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleChecklistArrayQC(values, index)}> </TextField>
                                </StyledTableCell> :
                                innerItem == "NumberRecommendedForApproval" ?
                                    <StyledTableCell align="center" component="th" scope="row" >
                                        <TextField id={"NumberRecommendedForApproval" + item.Number} type="number" inputProps={{ min: 0 }} value={item.NumberRecommendedForApproval} variant="outlined" style={{ width: "50px" }} onChange={(values) => this.handleChecklistArrayQN(values, index)}> </TextField>
                                    </StyledTableCell>
                                    : <StyledTableCell align="left">{item[innerItem]}</StyledTableCell>
                ))}
            </StyledTableRow >
        ))
            :
            null;

        const statuslistrows = !utils.isNull(this.state.EvaluationStatusListObject) && this.state.EvaluationStatusListObject.TotalNumberOfRows !== 0 ? this.state.EvaluationStatusListObject.Data.map((item, index) => (

            <StyledTableRow key={item.ID}>
                <StyledTableCell align="left">{item.Evaluator}</StyledTableCell>
                <StyledTableCell align="left">{item.EvaluationDate}</StyledTableCell>
                <StyledTableCell align="left">{item.DGStatus}</StyledTableCell>
            </StyledTableRow >
        ))
            :
            null;

        return (
            <ViewContainer topPadding="0" title="">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br />
                <table width="100%" class="FormsTableHeader">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                            </td>
                            <td align="center">
                                <b>DG Evaluation</b><br />
                                <b>{this.state.FinancialYear}</b>
                            </td>
                            <td align="right">
                                <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <Table className={classes.table} size="small" >
                    <TableHead>
                        <TableRow>
                            {inttableheader}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {inttablerows}
                    </TableBody>
                </Table>
                <br />
                <br />
                <TableContainer component={Paper}>
                    <table className={classes.table} aria-label="simple table" size="small">
                        <tbody>
                            <TableRow>
                                <td align="left">
                                    <b>Status</b><br />
                                </td>
                                <td align="left">
                                    <TextField className="w-30" id="DGStatusID" value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.DGStatusID) ? this.state.formObject.DGStatusID.Id : 0} select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }}
                                        onChange={(values) => this.handleStatus(values)}>
                                        <MenuItem value={0} key={0} >  </MenuItem>
                                        {!utils.isNull(this.state.lookupEArray) ? this.state.lookupEArray.map((lookupVitem, index) => (

                                            <MenuItem value={lookupVitem.Id} key={lookupVitem.Id} >{lookupVitem.Description}</MenuItem>

                                        )) : null}
                                    </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Verification Comments</b><br />
                                </td>
                                <td align="left">
                                    <TextField id="VerificationComments" disabled multiline rows={4} value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.VerificationComments) ? this.state.formObject.VerificationComments : ""} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }}> </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Evaluation Comments</b><br />
                                </td>
                                <td align="left">
                                    <TextField id="Comments" multiline rows={4} value={!utils.isNull(this.state.formObject) && !utils.isNull(this.state.formObject.Comments) ? this.state.formObject.Comments : ""} variant="outlined" style={{ minWidth: "300px", width: "100% !important" }} onChange={(values) => this.handleComment(values)}> </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Evaluator</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="Evaluator" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.EvaluatedBy : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                            <TableRow>
                                <td align="left">
                                    <b>Evaluation Date</b><br />
                                </td>
                                <td align="left">

                                    <TextField id="DateEvaluated" disabled={true} value={!utils.isNull(this.state.formObject) ? this.state.formObject.EvaluationDate : ""} variant="outlined" margin="dense" style={{ minWidth: "300px", width: "100% !important" }} > </TextField>
                                </td>
                            </TableRow>
                        </tbody>
                    </table>
                </TableContainer>
                {!utils.isNull(this.state.EvaluationStatusListObject) && this.state.EvaluationStatusListObject.TotalNumberOfRows !== 0 ?
                    <Fragment>
                        <br />
                        <br />
                        <Table className={classes.table} size="small" >
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Updated By</StyledTableCell>
                                    <StyledTableCell>Date Updated</StyledTableCell>
                                    <StyledTableCell>DG Status</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {statuslistrows}
                            </TableBody>
                        </Table>
                    </Fragment>
                    : null}
                <br />
                <Button variant="outlined" size="small" id="btnSave" color="#01579B" disabled={this.state.EvaluationPermission === "ViewOnly" ? true : false} onClick={this.CheckStatus}>Save</Button>&nbsp;

                <LinkDialog open={this.state.linkDialogOpen} type={this.state.selectedLinkType}
                    title={this.state.linkDialogTitle}
                    closeHandler={(isCancelled, linked) => this.postLinkHandler(isCancelled, linked)}
                    dialogTitle={this.state.linkDialogTitle}
                    linkName={this.state.linkDialogName} linkHandler={(selection) => this.SaveEvaluation(selection)}
                    selectionId={this.state.id}
                    buttonName="Save"
                >
                    <DGLetterReasonDialog
                        dataUrl={"api/sims/dgevaluation/GetDGLetterReasons"}
                        multiSelect={true} showTitle={false}
                        DGApplicationID={this.state.id}
                        NewDGStatusID={this.state.NewDGStatusID}
                        mode="lookup"
                    />
                </LinkDialog>

            </ViewContainer>

        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(DGEvaluationMain)));