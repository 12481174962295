import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { ViewContainer } from "./../View.jsx";
import ViewUtils from '../ViewUtils';
import withMenuPermission from "../widgets/withMenuPermission";
import EnhancedToolbar from '../widgets/Toolbar';
import EnhancedTable from '../widgets/ResponsiveTable'; 


const headCells = [
    { id: 'LNumber', numeric: false, disablePadding: true, label: 'L number' },
    { id: 'EmployerName', numeric: false, disablePadding: true, label: 'Employer Name' },
    { id: 'TaskNumber', numeric: false, disablePadding: true, label: 'Task Number' },
    { id: 'LearnersAwarded', numeric: false, disablePadding: true, label: '# Learners Awarded' },
    { id: 'AmountAwarded', numeric: false, disablePadding: true, label: 'Amount Awarded' },
    { id: 'ContractStartDate', numeric: false, disablePadding: true, label: 'Contract Start Date' },
    { id: 'ContractEndDate', numeric: false, disablePadding: true, label: 'Contract End Date' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();

class ProjectBeneficiaries extends Component {
    constructor(props) {
        super(props);

        this.state = {
            model: {},
            showTable: true,
            selectedBeneficiary: null,
            searchParameters: [{ Name: "ProjectID", Value: this.props.location.state.projectID }],
            keyUpSwitch: false
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View', icon: 'ViewIcon' }
                    ]
                }
            ]
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: { projectID: this.props.location.state.projectID }
            });
        }
        else if (buttonId === "view") {
            this.props.history.push({
                pathname: '/ProjectBeneficiaryDetail',
                state: {
                    projectID: this.props.location.state.projectID,
                    selectedBeneficiary: this.state.selectedBeneficiary
                }
            })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedBeneficiary: selection[0] })
        } else {
            this.setState({ selectedBeneficiary: null })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="breadcrumbs">
                    {"Project Beneficiaries"}
                </p>
                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/ProjectBeneficiaries/GetSearch`}
                            height="70vh"
                            onSelectionChange={(selection) => { this.handleSelection(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />
                    )}
                </div>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProjectBeneficiaries));

