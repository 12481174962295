import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../widgets/Toolbar';
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { ProjectDetailsForm } from './ProjectDetailsForm';
import { ViewContainer } from "../View";
import ViewUtils from "./../ViewUtils";
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";


const utils = new ViewUtils();


const projectRef = React.createRef();

class ProjectDetail extends Component {
   
    state = {
        model: this.props.location.state.IsAdd ? {
            ProjectName: this.props.location.state.projectDescription,
            ProjectID: this.props.location.state.projectIDDescription,
            ProgrammeStructure: this.props.location.state.selection ? this.props.location.state.selection.ProgrammeStructure : null,
            Target: this.props.location.state.selection ? this.props.location.state.selection.TargetNumber : null,
            ProjectStartDate: this.props.location.state.startDate,
            ProjectEndDate: this.props.location.state.endDate
        } : {},
        importRecordID: !utils.isNull(this.props.location.state.selection) ? this.props.location.state.selection.Id : this.props.location.state.projectID,
        PType: this.props.location.state.PType,
        FinancialYearDesc: !utils.isNull(this.props.location.state.FinancialYearDesc) ? this.props.location.state.FinancialYearDesc: null,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Go back to the project search module', icon: 'CloseIcon' },
                        
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon'},
                        { id: 'milestones', label: 'PROJECT MILESTONES', tooltip: '' },
                        {
                            contextMenu: [
                                { id: "budget", description: "Project Budget" },
                                { id: "grants", description: "Project Grants" }
                            ],
                            id: 'financials', label: 'PROJECT FINANCIALS', tooltip: '' ,
                        },
                        { id: 'beneficiaries', label: 'PROJECT BENEFICIARIES', tooltip: '' },
                        { id: 'uploads', label: 'PROJECT UPLOADS', tooltip: '' },
                        { id: 'notifications', label: 'PROJECT NOTIFICATIONS', tooltip: '' },
                        { id: 'sitevisit', label: 'PROJECT SITE VISITS', tooltip: '' },
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ ProjectDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } 
        else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectsSearch',
                state: {
                }
            })
        } 
        else if ("milestones" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectMilestone',
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
        else if ("beneficiaries" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectBeneficiaries',
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
        else if ("uploads" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectUpload',
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
        else if ("notifications" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectNotification',
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
        else if ("sitevisit" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectSiteVisit',
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
    }

    handleToolbarButtonMenuClick(menu) {
        if ("budget" === menu.id) {
            this.props.history.push({
                pathname: '/ProjectBudget',         
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
        else if ("grants" === menu.id) {
            this.props.history.push({
                pathname: '/ProjectGrant',
                state: {
                    projectID: this.state.importRecordID,
                }
            })
        } 
    }

    close() {
        this.props.history.push('/projectSearch');
    }

    successCallback = (response) => {
        console.log(response)
        this.setState({ message: "Project setup updated successfully", messageStyle: "SUCCESS", importRecordID: response.EntityId });
    }

    save() {
        let formValue = projectRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {

            if (!utils.isNull(this.state.importRecordID)) {
                formValue.id = this.state.importRecordID;
            }
            if (!utils.isNull(this.state.FinancialYearDesc)) {
                formValue.FinancialYearDescription = this.state.FinancialYearDesc;
            }
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/projects/UpdateProject", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    render() {
       
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Project Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <ProjectDetailsForm ref={projectRef} id={this.props.location.state.IsAdd ? null : this.state.importRecordID} data={this.state.model} PType={this.state.PType}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ProjectDetail));
