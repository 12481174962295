import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../widgets/Toolbar';
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import ViewUtils from "./../ViewUtils";
import { ViewContainer } from "../View";
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";
import { ProjectGrantForm } from "./ProjectGrantForm";


const utils = new ViewUtils();
const ref = React.createRef();

class ProjectGrant extends Component {
    state = {
        message: "",
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: { projectID: this.props.location.state.projectID }
            });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={""}>

                <p className="breadcrumbs">
                    Project Grant
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Grant" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    <ProjectGrantForm viewRef={this} ref={ref} id={this.props.location.state.projectID} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission={this.props.menuPermissionItem.Edit} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProjectGrant));

