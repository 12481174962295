import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../../widgets/Toolbar';
import "./../../../../Grid.css";
import "./../../../../Form.css";
import "./../../../../App.css";
import '../../Style.css'
import { ImpactAssessmentForm } from './ImpactAssessmentForm';
import ViewUtils from '../../../../ViewUtils';
import AlertItem from "../../../../widgets/AlertItem";
import Button from '@material-ui/core/Button';
import EnhancedTable from '../../../../widgets/ResponsiveTable';
import { ViewContainer } from "./../../../../View.jsx";
import Prompt from '../../../../widgets/Prompt';


const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: 'Back to WSP/ATR Forms', tooltip: '' }
            ]
        }
    ]
};

const formRef = React.createRef();

const headCells = [
    { id: 'Impact', numeric: false, disablePadding: true, label: 'Has the Impact of the Training Interventions been Measured? If Yes, what Methodology has been used and what was the Impact?', widthRatio: .100 },
    { id: 'AffectedCompany', numeric: false, disablePadding: true, label: 'Indicate how Training as Reported in the ATR, and PTR has Affected the Company', widthRatio: .100 },
    { id: 'Suggestions', numeric: false, disablePadding: true, label: 'Do you have any Suggestion on how the WSP/ATR Format can be Improved (Given the Stipulated Minimum Requirements from the Department)?', widthRatio: .100 },
    { id: 'Challenges', numeric: false, disablePadding: true, label: 'List the top 3 challenges encountered working with the SETA in the current financial year?', widthRatio: .100 },

];

class ImpactAssessment extends Component {

    state = {
        organisationDetails: {
            LegalName: "",
        },
        message: "",
        messageStyle: "",
        title: "",
        id: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.selection)) ? this.props.location.state.selection : null,
        organisationID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.organisationID)) ? this.props.location.state.organisationID : null,
        WSPATRID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRID)) ? this.props.location.state.WSPATRID : null,
        WSPATRFormsID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormsID)) ? this.props.location.state.WSPATRFormsID : null,
        WSPATRFormName: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.WSPATRFormName)) ? this.props.location.state.WSPATRFormName : null,
        RecordID: (!utils.isNull(this.props.location.state) && !utils.isNull(this.props.location.state.RecordID)) ? this.props.location.state.RecordID : null,
        NQFLevel: null,
        open: null,
        searchParameters: [{ Name: "WSPATRID", Value: this.props.location.state.WSPATRID }, { Name: "WSPATRFormsID", Value: this.props.location.state.WSPATRFormsID }],
        adddisabled: false,
        editdisabled: true,
        canceldisabled: true,
        EditFormData: null,
        formPermission: "Enabled",
        addDefaultObject: {
            WesternCape: 0,
            NorthernCape: 0,
            EasternCape: 0,
            FreeState: 0,
            Gauteng: 0,
            Mpumalanga: 0,
            Limpopo: 0,
            NorthWest: 0,
            KZN: 0,
            Total: 0
        }
    };

    add = () => {

        let formValue = formRef.current.getValue();

        if (formValue !== null && typeof formValue !== 'undefined') {

            formValue.WSPATRID = this.state.WSPATRID;
            formValue.WSPATRFormsID = this.state.WSPATRFormsID;

            const url = `api/sims/WSPATRForms2025/AddUpdateWSPATRImpactAssessment`;
            utils.saveForm(this, formRef, url, (response) => {

                utils.showMessage(this, response.Message, response.MessageType);
                this.successCallback(response);
                this.edit();
            }, this.error);
        }
    }

    //CheckImpactAssessmentRules = (formValue) => {
    //    let ImpactAssessment = true;
    //    let ErrorMessage = "";

    //    if (parseInt(formValue.WesternCape) + parseInt(formValue.NorthernCape) + parseInt(formValue.EasternCape) + parseInt(formValue.FreeState) +
    //        parseInt(formValue.Gauteng) + parseInt(formValue.Mpumalanga) + parseInt(formValue.Limpopo) + parseInt(formValue.NorthWest) + parseInt(formValue.KZN) === 0) {
    //        ErrorMessage = "Provincial Details: Cannot be zero.";
    //        ImpactAssessment = false;
    //    }

    //    if ((parseInt(formValue.WesternCape) < 0) || (parseInt(formValue.NorthernCape) < 0) || (parseInt(formValue.EasternCape) < 0) || (parseInt(formValue.FreeState) < 0) ||
    //        (parseInt(formValue.Limpopo) < 0) || (parseInt(formValue.NorthWest) < 0) || (parseInt(formValue.NorthWest) < 0) || (parseInt(formValue.KZN) < 0)) {
    //        ErrorMessage = "Provincial Details: Negative numbers are not allowed.";
    //        ImpactAssessment = false;
    //    }

    //    if (!ImpactAssessment) { this.setState({ message: ErrorMessage, messageStyle: "warning" }) }

    //    return ImpactAssessment;
    //}

    edit = () => {

        utils.invokeUrl("api/sims/WSPATRForms2025/GetWSPATRImpactAssessmentFind?WSPATRID=" + this.state.WSPATRID + "&WSPATRFormsID=" + this.state.WSPATRFormsID, (response) => {
            response.WSPATRID = this.state.WSPATRID;
            response.WSPATRFormsID = this.state.WSPATRFormsID;
            this.setState({
                RecordID: JSON.parse(JSON.stringify(this.state.RecordID)),
                adddisabled: false,
                editdisabled: true,
                canceldisabled: false,
                EditFormData: response
            });
            this.GetWSPATRFormPermissionCheck()

        }, this.error);
    }

    cancel = () => {
        this.setState({
            adddisabled: false,
            editdisabled: true,
            canceldisabled: true,
            EditFormData: null,
            clearSelectedRow: true,
            RecordID: null,
            message: "",
            messageStyle: ""
        });
    }

    successCallback(response) {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                addDefaultObject: JSON.parse(JSON.stringify(this.state.addDefaultObject)),
                message: response.Message, messageStyle: response.MessageType,
                adddisabled: false,
                editdisabled: true,
                canceldisabled: true,
                EditFormData: null
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }
    error(response) {
        this.setState({ message: response.Message, messageStyle: response.MessageType });
        this.GetWSPATRFormPermissionCheck()
    }

    handleFormValueChange(values) {
        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleFormValueChangeAdd(values) {

        this.setState({
            organisationDetails: {
                values
            }
        });
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {

            this.setState({
                RecordID: selection[0].Id, Message: "",
                adddisabled: false,
                editdisabled: false,
                canceldisabled: false,
                reportName: "",
                message: "",
                messageStyle: ""
            });
        }
        else {
            this.setState({
                Message: "",
                adddisabled: false,
                editdisabled: true,
                canceldisabled: true
            });
        }

        if (this.state.formPermission !== "Enabled") {
            this.setState({
                adddisabled: true,
                editdisabled: true,
                canceldisabled: true
            })
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else {
            this.close();
        }
    }


    close() {
        this.props.history.push({
            pathname: '/WspAtrForms',
            state: {
                organisationID: this.state.organisationID,
                organisationName: this.state.OrganisationName,
                sdlnumber: this.state.SDLNumber,
                WSPATRID: this.props.location.state.WSPATRID
            }
        });
    }


    dataArrivedHandler = (values) => {
        console.log(values);
        if (!utils.isNull(values["ID"]) && values["ID"] !== 0) {
            this.setState({ id: values["ID"] });
        }
    }

    componentDidMount() {

        this.getFormHead()
        this.edit()
    }

    getFormHead() {
        let url = "api/sims/wspatr/GetFindHeader?WSPATRID=" + this.state.WSPATRID;
        utils.invokeUrl(url, (response) => {
            this.setState({
                FinancialYear: response.FinancialYear,
                OrganisationName: response.OrganisationName,
                WSPStatus: response.WSPStatus,
                SDLNumber: response.SDLNumber
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }
    GetWSPATRFormPermissionCheck() {
        let url = "api/sims/wspatr/GetWSPATRFormPermissionCheck?WSPATRID=" + this.state.WSPATRID + "&WSPATRFormsID=" + this.state.WSPATRFormsID;
        utils.invokeUrl(url, (response) => {
            if (response.Result !== "Enabled") {
                this.setState({
                    adddisabled: true,
                    removedisabled: true,
                    removealldisabled: true,
                    editdisabled: true,
                    updatedisabled: true,
                    canceldisabled: true,
                    formPermission: response.Result
                })
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    render() {

        let CaptureFormEdit = <ImpactAssessmentForm
            ref={formRef}
            WSPATRID={this.state.WSPATRID}
            valueChangeHandler={(values) => this.handleFormValueChange(values)} dataArrivedHandler={(values) => this.dataArrivedHandler(values)}
            data={this.state.EditFormData} />;

        let CaptureFormAdd = <ImpactAssessmentForm
            ref={formRef}
            WSPATRID={this.state.WSPATRID}
            valueChangeHandler={(values) => this.handleFormValueChangeAdd(values)}
            data={this.state.addDefaultObject} />;

        if (this.state.EditFormData === null) {
            CaptureFormEdit = null;
        }
        else {
            CaptureFormAdd = null;
        }

        return (
            <ViewContainer mode={this.props.mode} title="">
                <div className="view w-100 h-100">
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                    <br />
                    <table width="100%" class="FormsTableHeader">
                        <tbody>
                            <tr>
                                <td>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={toolbarConfig} />
                                </td>
                                <td align="center">
                                    <b>{this.state.WSPATRFormName}</b><br />
                                    <b>{this.state.FinancialYear}</b>
                                </td>
                                <td align="right">
                                    <b>{this.state.OrganisationName} - {this.state.SDLNumber}</b>&nbsp;&nbsp;&nbsp;
                            </td>
                            </tr>
                        </tbody>
                    </table>
                    <table width="100%">
                        <tbody>
                            <tr>
                                <td colSpan="2" className="formContainer">
                                    {CaptureFormAdd}
                                    {CaptureFormEdit}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <Button variant="outlined" size="small" id="btnAdd" color="#01579B" hidden={this.state.adddisabled} onClick={this.add}>Save</Button>&nbsp;
                                <Button variant="outlined" size="small" id="btnCancel" color="#01579B" hidden={this.state.canceldisabled} onClick={this.cancel}>Cancel</Button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(ImpactAssessment);