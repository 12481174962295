import React from 'react';
import TabPanel, { FormTabContainer } from '../widgets/TabPanel';
import { SelectItem } from '../widgets/SelectItem';
import { CustomTextField } from '../widgets/CustomTextField';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';
import { CustomDatePicker } from '../widgets/CustomDatePicker';
import DependancySearch from "./DependancySearch";
import UserSearch from "../etqa/UserSearch";
import { LookupField } from '../widgets/LookupField';


const utils = new ViewUtils();

export const CaptureMilestoneForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="projectmilestone" values={props.data} org="saseta" dataURL='api/sims/projectmilestone/GetFind'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="CaptureMilestoneForm">
                    <FormTabContainer>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="TaskName"
                                            key={tabValue}
                                            label="Task Name"
                                            className="w-100"
                                            required
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form} 
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="Duration"
                                            key={tabValue}
                                            label="Duration"
                                            className="w-100"
                                            required
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomDatePicker
                                            id="StartDate"
                                            key={tabValue}
                                            validationGroup="registrationDates"
                                            label="Start Date"
                                            disabled={props.isEdit}
                                            className='{classes.textField} w-100'
                                            form={form}
                                            required
                                            minDate={new Date("2023-01-01")}  
                                        />
                                    </td>
                                    <td>
                                        <CustomDatePicker
                                            id="EndDate"
                                            key={tabValue}
                                            validationGroup="registrationDates"
                                            label="End Date"
                                            disabled={props.isEdit}
                                            className='{classes.textField} w-100'
                                            form={form}
                                            minDate={new Date("2023-01-01")}  
                                            validator={{
                                                script: (formValues) => {
                                                    return !utils.isNull(formValues)
                                                        && !utils.isNull(formValues.StartDate)
                                                        && formValues.StartDate.getTime() < formValues.EndDate.getTime()
                                                }, message: "Start date must be before the end date"
                                            }}
                                            required
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <LookupField
                                            id="DependencyID"
                                            label="Dependency"
                                            className="w-100"
                                            form={form}
                                        >
                                            <DependancySearch showTitle={false} mode='lookup' dataUrl="api/sims/projectmilestone/MileStoneDependencySearch"
                                                onLookupValueChange={(value) => {
                                                    form.setLookupSelection("DependencyID", value)
                                                }} autoFetchData={false} selection={props.selection} ProjectID={props.ProjectId} MileStoneID={props.id} />
                                        </LookupField>
                                    </td>
                                    <td>
                                        <LookupField
                                            id="ResourceID"
                                            label="Resource"
                                            className="w-100"
                                            form={form}
                                            required
                                        >
                                            <UserSearch showTitle={false} mode='lookup' dataUrl="api/sims/projectmilestone/MileStoneUserSearch"
                                                onLookupValueChange={(value) => {
                                                    form.setLookupSelection("ResourceID", value)
                                                }} autoFetchData={false} selection={props.selection} />
                                        </LookupField>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="Completion"
                                            key={tabValue}
                                            label="Completion"
                                            className="w-100"
                                            required
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                        />
                                    </td>
                                    <td>
                                        <SelectItem
                                            dataUrl="api/sims/projectmilestone/GetLookupList?listId=ProjectMilestoneStatus"
                                            id="StatusID"
                                            required={props.isEdit}
                                            disabled={!props.isEdit}
                                            key={tabValue}
                                            label="Status"
                                            className="w-100"
                                            form={form} />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>

    );
})
