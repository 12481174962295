import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from "../../../ViewUtils";
import ProjectSearch from './ProjectSearch';
import ContractSearch from './ContractSearch';
import ActivitySearch from './ActivitySearch';
import *  as InterventionSearch from './InterventionIndex';
import { CustomCheckbox } from '../../../widgets/CustomCheckbox';


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const AddEditTrancheForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function handleSelectionCallbackMode(value) {
        let objectReference = {};
        objectReference.Id = value[0].Id;
        objectReference.Description = value[0].Description;
        objectReference.toString = () => { return value[0].Description };
        return objectReference
    }

    function InterventionTypeChange(value, form) {
        if (typeof value !== 'undefined' &&  value !== null) {
            if (value.Description === "Learnership") {
                var type = 'LearnershipSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/LearnershipSearch" autoFetchData={false} selection={props.selection} />
            } else if (value.Description === "Skills Programme") {
                var type = 'SkillsProgramSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/SkillsProgrammeSearch" autoFetchData={false} selection={props.selection}/>
            } else if (value.Description === "AET") {
                var type = 'AETSetupSearch';
                const ComponentToRender = InterventionSearch[type];
                return <ComponentToRender showTitle={false} mode='lookup'
                    onLookupValueChange={(value) => form.setLookupSelection("InterventionID", value)} dataUrl="api/sims/GrantSetup/AETSearch" autoFetchData={false} selection={props.selection}/>
            }
        }
    }

    function LookupValidation(value) {
        if (value !== undefined && value !== null) {
            return false
        }
        else {
            return true
        }
    }

    function InterventionTypeRender(intervention, checkBValue) {
        if (intervention !== null && checkBValue !== null) {
            if (typeof intervention !== 'undefined' && intervention !== null) {
                if (intervention.Description === "Learnership" ||
                    intervention.Description === "Skills Programme" ||
                    intervention.Description === "AET") {
                    if (checkBValue == false) { return false }
                    else { return true }
                }
                else { return true; }
            }
            else { return true; }
        } else if (intervention !== null && (checkBValue == null || checkBValue == undefined)) {
            if (intervention.Description === "Learnership" ||
                intervention.Description === "Skills Programme" ||
                intervention.Description === "AET") {
                return false;
            } else { return true; } }
        else { return true; }
    }

    function manageReqFlag(intervention, checkBValue) {
        if (intervention !== null && checkBValue !== null) {
            if (checkBValue == false) {
                if (intervention.Description === "Learnership" ||
                    intervention.Description === "Skills Programme" ||
                    intervention.Description === "AET") {
                    return true;
                } else { return false; }
            }
            else { return false; }
        } else if (intervention !== null && (checkBValue == null || checkBValue == undefined)) {
            if (intervention.Description === "Learnership" ||
                intervention.Description === "Skills Programme" ||
                intervention.Description === "AET") {
                return true;
            } else { return false; } }
        else { return false; }
    }

    useEffect(() => {

    }, [])

    return (
        <EntityForm ref={ref} values={props.data} viewId="TrancheSetup" dataURL="api/sims/GrantSetup/GetTrancheSetupDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="addEditTrancheForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Setup Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ProjectNumber"
                                                label="Project"
                                                className="w-100"
                                                form={form}
                                            >
                                                <ProjectSearch showTitle={true} mode='lookup' dataUrl="api/sims/GrantSetup/DTSSServiceProject"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ProjectNumber", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="ContractNumber"
                                                label="Contract"
                                                className="w-100"
                                                disabled={LookupValidation(form.values["ProjectNumber"])}
                                                form={form}
                                            >
                                                <ContractSearch showTitle={true} mode='lookup' ProjID={form.values["ProjectNumber"]}
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("ContractNumber", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="ActivityNumber"
                                                label="Activity"
                                                className="w-100"
                                                disabled={LookupValidation(form.values["ContractNumber"])}
                                                form={form}
                                            >
                                                <ActivitySearch showTitle={true} mode='lookup' ProjID={form.values["ProjectNumber"]}
                                                    ContractID={form.values["ContractNumber"]}
                                                    onLookupValueChange={(value) => {
                                                        if (value !== null && value.length !== 0) {
                                                            form.setLookupSelection("ActivityNumber", handleSelectionCallbackMode(value))
                                                            form.values['VendorSDLNumber'] = value[0].VendorSDLNumber;
                                                            form.values['ActivityAgreementNumber'] = value[0].ActivityAgreementNumber;
                                                            form.values['NumberOfLearners'] = value[0].NumberOfLearners;
                                                            form.values['BudgetAmount'] = value[0].ContractBudgetAmount;
                                                        }
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="VendorSDLNumber"
                                                key={tabValue}
                                                label="Vendor SDL Number"
                                                className="w-100"
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="ActivityAgreementNumber"
                                                key={tabValue}
                                                label="Activity Agreement Number"
                                                className="w-100"
                                                disabled
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=VendorType"
                                                id="VendorTypeID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Vendor Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomCheckbox
                                                id="AllProgrammes"
                                                key={tabValue}
                                                form={form}
                                                inputProps={{
                                                    'aria-label': 'primary checkbox',
                                                }}
                                                label="Applicable to all programmes"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=DGType"
                                                id="ProgrammeType"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Programme Type"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                required={manageReqFlag(form.values["ProgrammeType"], form.values["AllProgrammes"])}
                                                id="InterventionID"
                                                label="Intervention"
                                                disabled={InterventionTypeRender(form.values["ProgrammeType"], form.values["AllProgrammes"])}
                                                className="w-100"
                                                form={form}
                                            >
                                                {InterventionTypeChange(form.values["ProgrammeType"], form)}
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />

                                        </td>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                dataUrl="api/sims/GrantSetup/GetLookupList?listId=YesNo"
                                                id="IsDisabled"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Is Disabled?"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfLearners"
                                                key={tabValue}
                                                label="Number Of Learners"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="NumberOfLearnersRemaining"
                                                key={tabValue}
                                                label="Number Of Learners Remaining"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="BudgetAmount"
                                                key={tabValue}
                                                label="Budget Amount"
                                                className="w-100 "
                                                disabled
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
