import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { AddressCodeItem } from '../../widgets/AddressCodeItem';
import { GPSCoordinatesAPI } from '../../widgets/GPSCoordinatesAPI';

export const OrganisationContactForm = React.forwardRef((props, ref) => {

    const [tabValue, setTabValue] = React.useState(0);
    const [physicalCodeValue, setPhysicalCodeValue] = React.useState(null);
    const utils = new ViewUtils();

    const updateGPSAddress = (address, form) => {
        console.log(address);
        form.values["GPSCoordinates"] = address.gpscoordinates.coordinate;
        form.values["PhysicalAddress1"] = address.streetnumberpar + ' ' + address.routepar;
        form.values["PhysicalAddress2"] = address.sublocalitylevel1par;
        form.values["PhysicalAddress3"] = address.localitypar;
        form.values["PhysicalCode"] = address.postalcodepar;
        setPhysicalCodeValue(form.values["PhysicalCode"]);
    }

    const dataArrivedHandler = (values) => {
        setPhysicalCodeValue(values["PhysicalCode"]);
    }

    return (
        <EntityForm ref={ref} viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler} dataArrivedHandler={dataArrivedHandler}>
            {form =>
                <form className={form.classes.container} id="organisationContactAddForm">

                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/dgorganisationcontact/GetLookupList?listId=Title"
                                                id="TitleID"
                                                key={tabValue}
                                                label="Title"
                                                className="w-100"
                                                form={form}
                                            // required
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="FirstName"
                                                key={tabValue}
                                                label="First Name"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="Surname"
                                                key={tabValue}
                                                label="Surname"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                        <td>
                                            {/*<CustomTextField*/}
                                            {/*    id="Designation"*/}
                                            {/*    key={tabValue}*/}
                                            {/*    label="Designation"*/}
                                            {/*    className="w-100"*/}
                                            {/*    form={form}*/}
                                            {/*//required*/}
                                            {/*/>*/}
                                            <SelectItem
                                                parentId={0}
                                                dataUrl="api/sims/dgorganisationcontact/GetLookupList?listId=DGContactDesignation"
                                                id="DGContactDesignationID"
                                                key={tabValue}
                                                label="Designation"
                                                className="w-100"
                                                form={form}
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="TelephoneNumber"
                                                key={tabValue}
                                                label="Telephone Number"
                                                validationRegex="^$|^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                //required
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="CellPhoneNumber"
                                                key={tabValue}
                                                label="Cellphone Number"
                                                validationRegex="^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="FaxNumber"
                                                key={tabValue}
                                                label="Fax Number"
                                                validationRegex="^$|\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="EMail"
                                                key={tabValue}
                                                label="Email"
                                                validationRegex="^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$"
                                                className="w-100"
                                                required
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <GPSCoordinatesAPI
                                                id="Address"
                                                key={tabValue}
                                                className="w-100"
                                                label="Address"
                                                form={form}
                                                updateGPSAddress={updateGPSAddress}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="GPSCoordinates"
                                                key={tabValue}
                                                label="GPS Coordinates"
                                                className="w-100"
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                parentId={1}
                                                id="PhysicalAddress1"
                                                required
                                                key={tabValue}
                                                label="Address 1"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                parentId={1}
                                                id="PhysicalAddress2"
                                                key={tabValue}
                                                required
                                                label="Address 2"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colSpan="2">
                                            <CustomTextField
                                                parentId={1}
                                                id="PhysicalAddress3"
                                                key={tabValue}
                                                label="Address 3"
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="2">
                                            <AddressCodeItem
                                                id="PhysicalCode"
                                                required={true}
                                                addressId=""
                                                value={physicalCodeValue}
                                                key={tabValue}
                                                validationRegex={/^[0-9]{4,5}$/g}
                                                validationMessage="The code must be 4 or 5 digits long"
                                                provinceId={!utils.isNull(form.values) ? form.values.ProvinceID : null}
                                                municipalityId={!utils.isNull(form.values) ? form.values.MunicipalityID : null}
                                                districtId={!utils.isNull(form.values) ? form.values.DistrictID : null}
                                                urbanRuralId={!utils.isNull(form.values) ? form.values.UrbanRuralID : null}
                                                suburbId={!utils.isNull(form.values) ? form.values.SuburbID : null}
                                                cityId={!utils.isNull(form.values) ? form.values.CityID : null}
                                                className="w-100"
                                                label="Code"
                                                hasMunicipalityID={true}
                                                hasProvinceID={true}
                                                hasCityID={true}
                                                hasDistrictID={false}
                                                hasUrbanRuralID={true}
                                                hasSuburbID={false}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form >
            }
        </EntityForm >
    );
})