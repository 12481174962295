import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import EnhancedToolbar from '../widgets/Toolbar';
import withMenuPermission from "../widgets/withMenuPermission";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


import PerGrantSubmissionStatusPieChart from '../MGDashboardCharts/PerGrantSubmissionStatusPieChart';
import PerGrantSubmissionStatusLineChart from '../MGDashboardCharts/PerGrantSubmissionStatusLineChart';
import PerGrantSubmissionStatusBarChart from '../MGDashboardCharts/PerGrantSubmissionStatusBarChart';
import PerRegionPieChart from '../MGDashboardCharts/PerRegionPieChart';
import PerRegionLineChart from '../MGDashboardCharts/PerRegionLineChart';
import PerRegionBarChart from '../MGDashboardCharts/PerRegionBarChart';
import PerCompanySizePieChart from '../MGDashboardCharts/PerCompanySizePieChart';
import PerCompanySizeLineChart from '../MGDashboardCharts/PerCompanySizeLineChart';
import PerCompanySizeBarChart from '../MGDashboardCharts/PerCompanySizeBarChart';
import SDFPerRegionPieChart from '../MGDashboardCharts/SDFPerRegionPieChart';
import SDFPerRegionLineChart from '../MGDashboardCharts/SDFPerRegionLineChart';
import SDFPerRegionBarChart from '../MGDashboardCharts/SDFPerRegionBarChart';
import { Row } from 'react-bootstrap';


const utils = new ViewUtils();


class MGDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pergrantsubmissionstatusshow: false,
            perregionshow: false,
            percompanysizeshow: false,
            sdfperregionshow: false
        };
    }

    componentDidMount() {
        utils.setLegend("MG Dashboard");
        this.setState({ pergrantsubmissionstatusshow: true });
    }


    handleReportNameChanged = (event) => {

        var divToShow = event.target.value;

        if (divToShow == 'divPerGrantSubmissionStatus') {

            this.setState({ pergrantsubmissionstatusshow: true });

            this.setState({ perregionshow: false });
            this.setState({ percompanysizeshow: false });
            this.setState({ sdfperregionshow: false });

        }
        else if (divToShow == 'divPerRegion') {
            this.setState({ perregionshow: true });  // !this.state.perregionshow });

            this.setState({ pergrantsubmissionstatusshow: false });
            this.setState({ percompanysizeshow: false });
            this.setState({ sdfperregionshow: false });
        }
        else if (divToShow == 'divPerCompanySize') {
            this.setState({ percompanysizeshow: true });  //!this.state.percompanysizeshow });

            this.setState({ pergrantsubmissionstatusshow: false });
            this.setState({ perregionshow: false });
            this.setState({ sdfperregionshow: false });

        }
        else if (divToShow == 'divSDFPerRegion') {
            this.setState({ sdfperregionshow: true });  //!this.state.sdfperregionshow });

            this.setState({ percompanysizeshow: false });
            this.setState({ pergrantsubmissionstatusshow: false });
            this.setState({ perregionshow: false });
        }
    }



    render() {
        return (

            <div className="container-fluid">
                <br />
                <Row>
                    <TextField className="w-30" id="ReportNameID" label="Report Name: " select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleReportNameChanged(values)}>
                        <MenuItem value="divPerGrantSubmissionStatus" key="1">WSP List Per Grant Submission Status</MenuItem>
                        <MenuItem value="divPerRegion" key="2">WSP List Per Region</MenuItem>
                        <MenuItem value="divPerCompanySize" key="3">WSP List Per Company Size</MenuItem>
                        <MenuItem value="divSDFPerRegion" key="4">SDF Per Region</MenuItem>
                    </TextField>
                </Row>

                <br />

                {
                    this.state.pergrantsubmissionstatusshow ?
                        <div id="divPerGrantSubmissionStatus" key="5" >
                            <Row>
                                <PerGrantSubmissionStatusPieChart />
                                <PerGrantSubmissionStatusLineChart />

                            </Row>
                            <Row>
                                <PerGrantSubmissionStatusBarChart />
                            </Row>
                        </div> : null
                }
              

                {
                    this.state.perregionshow ?
                        <div id="divPerRegion" key="6">
                            <Row>
                                <PerRegionPieChart />
                                <PerRegionLineChart />
                            </Row>
                            <Row>
                                <PerRegionBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.percompanysizeshow ?
                        <div id="divPerCompanySize" key="7">
                            <Row>
                                <PerCompanySizePieChart />
                                <PerCompanySizeLineChart />
                            </Row>
                            <Row>
                                <PerCompanySizeBarChart />
                            </Row>
                        </div> : null
                }


                {
                    this.state.sdfperregionshow ?
                        <div id="divSDFPerRegion" key="8">
                            <Row>
                                <SDFPerRegionPieChart />
                                <SDFPerRegionLineChart />
                            </Row>
                            <Row>
                                <SDFPerRegionBarChart />
                            </Row>
                        </div> : null
                }

            </div>
        );

    }
}

export default withRouter(withMenuPermission(MGDashboard));
