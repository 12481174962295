import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import EnhancedTable from '../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../widgets/Toolbar';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import ViewUtils from '../../ViewUtils';
import { ViewContainer } from "./../../View.jsx";
import FormDialog from "../FormDialog";
import { OutComeForm } from "../program/OutcomeForm";
import ProviderModuleQualificationSearchModule from "../program/ProviderModuleQualificationSearchModule";
import LinkDialog from "../LinkDialog";
import ProviderInterventionAudit from '../provider/ProviderInterventionAudit'; 
import ProviderQualificationModulesPopup from '../provider/ProviderQualificationModulesPopup';
import withMenuPermission from "../../widgets/withMenuPermission";

const headCells = [
    { id: 'QCTOQualificationID', numeric: false, disablePadding: false, label: 'QCTO Qualification ID' },
    { id: 'QCTOQualificationTitle', numeric: false, disablePadding: false, label: 'QCTO Qualification Title' },
    { id: 'Credits', numeric: false, disablePadding: false, label: 'Credits', widthRation: .05 },
    { id: 'RegistrationStartDate', numeric: false, disablePadding: false, label: 'Registration Start Date' },
    { id: 'RegistrationEndDate', numeric: false, disablePadding: false, label: 'Registration End Date' },
    { id: 'LastDateForEnrolment', numeric: false, disablePadding: false, label: 'Last Enrolment Date' },
    { id: 'LastDateForAchievement', numeric: false, disablePadding: false, label: 'Last Achievement Date' },
    { id: 'ProgramOutcome', numeric: false, disablePadding: false, label: 'Program Outcome' },
    { id: 'ModeOfDelivery', numeric: false, disablePadding: false, label: 'Mode Of Delivery' },
    { id: 'Specialisation', numeric: false, disablePadding: false, label: 'Specialisation' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProviderModuleQualificationsSearch extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedModuleQualification: null,
            linkDialogOpen: false,
            formDialogOpen: false,
            auditDialogOpen: false,
            searchParameters: [{ Name: "ProviderID", Value: this.props.location.state.selection }],
            propmtOpen: false,
            promptTitle: "Remove QCTO Qualification from Provider",
            promptContent: "Are you sure you want to remove this QCTO Qualification from the provider?",
            removeMessage: "",
            removeMessageStyle: "",
            selectedModuleQualificationTitle: null,
            showTable: true,
            viewModulesDialogOpen: false,
            moduleQualificationID: null,

        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "link", label: '', icon: 'LinkIcon', tooltip: "Link existing QCTO Qualification to Provider", visible: this.props.menuPermissionItem.Add },
                        {
                            id: 'view', label: '', icon: 'UpdateIcon', tooltip: 'Update Outcome and Mode of Delivery',
                            disabled: utils.isNull(this.state.selectedModuleQualification), visible: this.props.menuPermissionItem.Edit
                        },
                        {
                            id: 'audit', label: '', icon: 'AuditIcon', tooltip: 'View Record Audit Trail',
                            disabled: utils.isNull(this.state.selectedModuleQualification)
                        },
                        {
                            id: 'remove', label: '', icon: 'DeleteIcon', tooltip: 'Remove QCTO Qualification from Provider',
                            disabled: utils.isNull(this.state.selectedModuleQualification), visible: this.props.menuPermissionItem.Delete
                        }]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'modules', label: 'VIEW MODULES', tooltip: 'View modules linked to the providers qualification',
                            disabled: utils.isNull(this.state.selectedModuleQualification)
                        }
                    ]
                }
            ]
        };

    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/provider',
                state: { selection: this.props.location.state.selection }
            });
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
        else if ("view" === buttonId) {
            this.setState({ removeMessage: "" }, this.viewRecords());
        }
        else if ("audit" === buttonId) {
            this.setState({ auditDialogOpen: true, message: "" })
        }
        else if ("link" === buttonId) {
            this.setState({ linkDialogOpen: true, removeMessage: "" })
        }
        else if ("modules" === buttonId) {
            this.setState({ viewModulesDialogOpen: true, removeMessage: "" })
        }
    }

    dialogActionHandler(isCancel, added) {
        if (added === true) {

            this.setState({
                showTable: false,
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false, auditDialogOpen: false, selectedSiteVisit: null, linkDialogOpen: false, viewModulesDialogOpen: false
            }, () => {
                this.setState({ showTable: true })
            });
        }
        else if (isCancel === true) {
            this.setState({
                formDialogOpen: false,
                linkDialogOpen: false,
                auditDialogOpen: false,
                viewModulesDialogOpen: false
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedModuleQualification: selection[0].Id, moduleQualificationID: selection[0].QualificationID, removeMessage: "", selectedModuleQualificationTitle: selection[0].SAQAModuleQualificationTitle });
        } else {
            this.setState({ selectedModuleQualification: null, removeMessage: "", selectedModuleQualificationTitle: null, moduleQualificationID: null });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("audit", selection === null || selection.length === 0);
        toolbarRef.current.setButtonDisabled("modules", selection === null || selection.length === 0);
    }

    successfulRemoveCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({
                selectedModuleQualification: null,
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            });
            //Force a refresh
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({
                removeMessage: response.Message,
                removeMessageStyle: response.MessageType.toLowerCase()
            })
        }
    }

    removeModuleQualifications(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
            toolbarRef.current.setButtonDisabled("view", true);
            toolbarRef.current.setButtonDisabled("audit", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedModuleQualification}&providerId=${this.props.location.state.selection}`;
        utils.invokeUrlDelete(fullUrl, this.successfulRemoveCallback, this.unsuccessfulRemoveCallback);
    }

    viewRecords = () => {
        this.setState({ formDialogOpen: true })
    }


    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                    {"Provider > QCTO Qualifications " + (this.props.location.state.providerName !== null ? "> " + this.props.location.state.providerName + (this.props.location.state.providerSDLNumber !== null ? " - " + this.props.location.state.providerSDLNumber : "") : "> Add")}
                </p>

                <AlertItem message={this.state.removeMessage} alertStyle={this.state.removeMessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    {this.state.showTable === true && (
                        <EnhancedTable
                            headCells={headCells}
                            dataUrl={`api/sims/providerModuleQualification/GetProviderModuleQualifications`}
                            height="60vh"
                            onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                            searchParameters={this.state.searchParameters} paged={true} />

                    )}
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"Update Outcome and Mode of Delivery"}
                    viewRef={this}
                    entityId={this.state.selectedModuleQualification}
                    formRef={addFormRef}
                    saveUrl={"api/sims/providerModuleQualification/UpdateModuleQualificationModeAndDelivery"} usePut={true}
                    controllerName={"providerModuleQualification"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                >
                    <OutComeForm
                        ref={addFormRef}
                        id={this.state.selectedModuleQualification}
                        controllerName={"providerModuleQualification"}
                        dataURL='api/sims/providerModuleQualification/GetFindModuleQualificationMode'
                    />
                </FormDialog>

                <LinkDialog open={this.state.auditDialogOpen}
                    title={"Audit Trail"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Audit Trail"}
                    selectionId={this.props.location.state.selectedModuleQualification}
                >

                    <ProviderInterventionAudit dataUrl={"api/sims/providerModuleQualification/GetProviderModuleQualificationAudit"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.selectedModuleQualification}
                    />
                </LinkDialog>

                <LinkDialog
                    open={this.state.linkDialogOpen}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={`Link ModuleQualifications to Provider > ${this.props.location.state.providerName} - ${this.props.location.state.providerSDLNumber}`}
                    linkName={"ModuleQualifications"} postUrl={"api/sims/providerModuleQualification/LinkProviderModuleQualification"} usePost={true}
                    selectionId={this.props.location.state.selection}
                >
                    <ProviderModuleQualificationSearchModule
                        dataUrl="api/sims/providerModuleQualification/GetSearchLinkableModuleQualifications"
                        mode="lookup"
                        multiSelect={true} showTitle={false}
                        programmeId={this.props.location.state.selection} programmeType="Q"
                    />
                </LinkDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeModuleQualifications("api/sims/providerModuleQualification/RemoveProviderModuleQualification") }}
                    closePrompt={this.closePrompt}
                />

                <LinkDialog open={this.state.viewModulesDialogOpen}
                    title={"QCTO Qualification Modules"}
                    closeHandler={(isCancelled, linked) => this.dialogActionHandler(isCancelled, linked)}
                    dialogTitle={"Modules"}
                    selectionId={this.props.location.state.selectedModuleQualification}
                >

                    <ProviderQualificationModulesPopup dataUrl={"api/sims/providerModuleQualification/GetProviderModuleQualificationModules"}
                        multiSelect={false} showTitle={false}
                        Id={this.state.moduleQualificationID}
                    />
                </LinkDialog>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ProviderModuleQualificationsSearch));
