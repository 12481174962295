import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from '../../widgets/TabPanel';
import { CustomTextField } from '../../widgets/CustomTextField';
import AppBar from '@material-ui/core/AppBar';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from "../../ViewUtils";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const UserDetailForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <EntityForm ref={ref} viewId="Users"
            dataURL='api/sims/user/getfind'
            values={props.data}
            entityLookupController={props.entityLookupController} org="foodbev"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="UserForm">
                    <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                        <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                            <Tab label="General Details" {...a11yProps(0)} />
                        </Tabs>
                    </AppBar>
                    <TabPanel value={tabValue} index={0} >
                        <table cellPadding="2" width="100%">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="FirstName"
                                            key={tabValue}
                                            label="First Name"
                                            className="w-100"
                                            required
                                            form={form}
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="Surname"
                                            key={tabValue}
                                            label="Surname"
                                            className="w-100"
                                            required
                                            form={form}
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="IDNo"
                                            key={tabValue}
                                            label="ID Number"
                                            className="w-100"
                                            required
                                            form={form}
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="TelephoneNumber"
                                            key={tabValue}
                                            label="Telephone Number"
                                            className="w-100"
                                            form={form}
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="CellPhoneNumber"
                                            key={tabValue}
                                            label="CellPhone Number"
                                            className="w-100"
                                            form={form}
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="FaxNumber"
                                            key={tabValue}
                                            label="Fax Number"
                                            className="w-100"
                                            form={form}
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="EMail"
                                            key={tabValue}
                                            label="Email"
                                            className="w-100"
                                            required
                                            form={form}
                                        />
                                    </td>
                                    <td>
                                        <CustomCheckbox
                                            id="IsActive"
                                            key={tabValue}
                                            form={form}
                                            InputProps={{
                                                'aria-label': 'primary checkbox'

                                            }}
                                            label="IsActive"
                                            disabled={!utils.isNull(props.emailOnly) ? props.emailOnly : false}
                                        />
                                    </td>

                                </tr>
                            </tbody>
                        </table>
                    </TabPanel>
                </form>
            }
        </EntityForm>
    );
})

