import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import ViewUtils from '../../../ViewUtils';
import OnlineProviderDocuments from "./OnlineProviderDocuments";
import OnlineProviderDocumentDialog from "./OnlineProviderDocumentDialog";
import { SelectItem } from '../../../widgets/SelectItem';



const utils = new ViewUtils();
export const OnlineProviderDocumentsForm = React.forwardRef((props, ref) => {
    const [documentsDialogOpen, setdocumentsDialogOpen] = React.useState(false);
    const [providerFileName, setProviderFileName] = React.useState('');
    const [DocURL, setDocURL] = React.useState(null);
    const [showProviderDocDialog, setShowProviderDocDialog] = React.useState(false);
    const [showTable, setshowTable] = React.useState(true);
    
    const setDialogOpen = () => {
        setdocumentsDialogOpen(true);
    };

    const handleCellSelect = (rowid, headCell) => {
        let url = "api/sims/OnlineProviderRegistration/getDocument?documentID=" + rowid;
        utils.invokeUrlExternal(url, (response) => {
            var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const postUploadHandler = (save, cancel) => {
        //Successful upload
        if (save === true) {
            setshowTable(false);
            setdocumentsDialogOpen(false);
            setshowTable(true);
        }
        //Close dialog
        else if (cancel === true) {
            setdocumentsDialogOpen(false);
        }
        //Error on upload
        else {
            setdocumentsDialogOpen(false);
        }
        props.verifyDocumentCompletion();
    };
    

    return (
        <EntityForm ref={ref} viewId="provider" values={props.data} dataURL='api/sims/OnlineProviderRegistration/getfind'
            entityLookupController={props.entityLookupController} org="saseta" IsExternal={props.IsExternal}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ProviderForm">
                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td>
                                    <SelectItem
                                        dataUrl="api/sims/OnlineProviderRegistration/GetLookupList?listId=OnlineProviderRegistrationDocumentType"
                                        id="DocumentTypeID"
                                        label="Document Type"
                                        className="{classes.select} w-100"
                                        allowEmptyValue={true}
                                        checkRefreshToken={!props.IsExternal}
                                        form={form}
                                        InputProps={{
                                            readOnly: props.disableFields
                                        }}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br></br>
                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td >
                                <div>
                                    <OnlineProviderDocumentDialog
                                        open={documentsDialogOpen}
                                        closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                        postUrl="api/sims/OnlineProviderRegistration/AddOnlineProviderDocument"
                                        EntityID={props.tempKey}
                                        checkRefreshToken={!props.IsExternal}
                                        buttonLabel={"Upload Document"}
                                        DocumentType={form.values["DocumentTypeID"]}
                                        fileName={providerFileName}
                                        _setFilename={(name) => { setProviderFileName(name) }}
                                    />
                                    </div>
                                </td>
                                <br></br>
                                <br></br>
                            </tr>
                        </tbody>
                    </table>
                    <p></p>
                    {showTable == true && (
                        <OnlineProviderDocuments handleCell={(rowid, headCell) => handleCellSelect(rowid, headCell)} checkRefreshToken={!props.IsExternal}
                            documentsDataUrl="api/sims/OnlineProviderRegistration/GetOnlineProviderDocuments" moduleName="UG" selection={props.tempKey} />

                    )}
                </form>
            }
        </EntityForm>
    );
})

