import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import EnhancedTable from '../widgets/ResponsiveTable';
import EnhancedToolbar from '../widgets/Toolbar';
import AlertItem from "../widgets/AlertItem";
import Prompt from '../widgets/Prompt';
import ViewUtils from '../ViewUtils';
import FormDialog from "../etqa/FormDialog";
import { ProjectNotificationForm } from "./ProjectNotificationForm";
import { ViewContainer } from "./../View.jsx";

const headCells = [
    { id: 'NotificationNumber', numeric: false, disablePadding: true, label: 'Notification Number' },
    { id: 'NotificationName', numeric: false, disablePadding: true, label: 'Notification Name' },
    { id: 'NotificationMessage', numeric: false, disablePadding: true, label: 'Notification Message' },
    { id: 'NotificationTrigger', numeric: false, disablePadding: true, label: 'Notification Trigger' },
    { id: 'TaskName', numeric: false, disablePadding: true, label: 'Task Name (If Applicable)' }
];

const utils = new ViewUtils();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();

class ProjectNotification extends Component {

    constructor(props) {
        super(props)
        this.state = {

            formDialogOpen: false,
            selectedProjectNotification: null,
            propmtOpen: false,
            promptTitle: "Remove project notification",
            promptContent: "Are you sure you want to remove this notification?",
            Message: "",
            MessageStyle: "",
            searchParameters: [{ Name: "ProjectID", Value: this.props.location.state.projectID }]
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Create new notification', icon: 'AddIcon' },
                        { id: 'remove', label: '', tooltip: 'Remove notification from this project', icon: 'DeleteIcon', disabled: utils.isNull(this.state.selectedProjectNotification) }
                    ]
                }
            ]
        };

    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: {
                    projectID: this.props.location.state.projectID,
                    IsAdd: false
                }
            })
        }
        else if ("add" === buttonId) {
            this.setState({ formDialogOpen: true, Message: "" })
        }
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    postAddHandler(cancel, save) {
        if (save === true) {
            this.setState({
                searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                formDialogOpen: false,
                Message: "Project notification successfully saved",
                MessageStyle: "success"
            });
        }
        else if (cancel === true) {
            this.setState({
                formDialogOpen: false,
                Message: ""
            })
        }
        else {
            this.setState({
                formDialogOpen: false,
                Message: "A system error has occured, please try again later or contact your administrator",
                MessageStyle: "warning"
            })
        }
    }

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedProjectNotification: selection[0].Id, Message: "" });
        } else {
            this.setState({ selectedProjectNotification: null, Message: "" });
        }
        toolbarRef.current.setButtonDisabled("remove", selection === null || selection.length === 0);
    }

    successfulNotificationCallback = (response) => {

        if (!utils.isNull(response.EntityId) && response.EntityId === 0) {
            this.setState({ selectedProjectNotification: null, Message: response.Message, MessageStyle: response.MessageType.toLowerCase() });
            this.setState({ searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)) })
        }
    }

    unsuccessfulNotificationRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ Message: response.Message, MessageStyle: response.MessageType.toLowerCase() })
        }
    }

    removeContact(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedProjectNotification}`;
        utils.invokeUrlDelete(fullUrl, this.successfulNotificationCallback, this.unsuccessfulNotificationRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title="">

                <p className="breadcrumbs">
                   Project Notifications
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    <EnhancedToolbar
                        highlight={false}
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig} />

                    <EnhancedTable
                        headCells={headCells}
                        dataUrl={`api/sims/projectnotification/GetProjectNotifications`}
                        height="50vh"
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        searchParameters={this.state.searchParameters} paged={true} />
                </div>

                <FormDialog
                    open={this.state.formDialogOpen}
                    dialogTitle={"New Notification"}
                    viewRef={this}
                    formRef={addFormRef}
                    entityId={this.props.location.state.projectID}
                    saveUrl={"api/sims/projectnotification/InsertProjectNotification"}
                    usePost={true}
                    closeHandler={(cancel, save) => this.postAddHandler(cancel, save)}
                >
                    <ProjectNotificationForm ref={addFormRef}/>
                </FormDialog>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.removeContact("api/sims/projectnotification/RemoveProjectNotification") }}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(ProjectNotification)
);
