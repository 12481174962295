import React from "react";
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import ViewUtils from '../ViewUtils';
import EnhancedTable from '../widgets/ResponsiveTable';
import { Alert } from "react-bootstrap";
const utils = new ViewUtils();


export default function ModulesTable(props) {
    const [TotalCredits, setTotalCredits] = React.useState(0);
    const [Credits, setCredits] = React.useState(0);
    const [TotalElectiveCredits, setTotalElectiveCredits] = React.useState(0);

    const handleDataArrive = (data) => {
        console.log('is this getting hit?')
        console.log(data)
        setTotalCredits(data.TotalCredits);
        setCredits(data.Credits);
        setTotalElectiveCredits(data.TotalElectiveCredits);

        if (!utils.isNull(props.onDataArrive)) {
            props.onDataArrive(data);
        }
    }

    const creditFuc = (TotalCredits, Credits) => {
        if (parseInt(TotalCredits) < parseInt(Credits)) { 
            return '50vh';
        }
           return '40vh';
    }

    return (
        <div className="view w-100">
            <div style={{ paddingBottom: "8px" }}>
                {(parseInt(TotalCredits) < parseInt(Credits)) ?                   
                    <div style={{ color:'#ff0e25',fontSize:'13px'}}>
                Please note: The total credits of all modules selected must be equal to or more than the minimum qualification credits
                </div>              
                :null
                }
                <div> {Credits >= 0 ? 'Minimum Qualification Credits: ' + Credits : null} </div>           
                <div> {TotalCredits >= 0 ? 'Total Credits: ' + TotalCredits : null} </div>   
            </div>
            <EnhancedTable headCells={props.headCells} onSelectionChange={props.onSelectionChange}
                dataUrl={props.dataUrl} onDataArrive={(data) => { handleDataArrive(data) }}
                searchParameters={props.searchParameters} paged={!utils.isNull(props.paged) ? props.paged : false} />
        </div>
    );
}