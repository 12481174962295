import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import SearchView from "../../widgets/SearchView";
import OrganisationSearchForm from '../organisation/DGOrganisationSearchForm';
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from '../../ViewUtils';


const utils = new ViewUtils();
class StakeholderOrganisationAddSearch extends Component {

    constructor(props) {
        super(props);


        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Add', icon: 'AddIcon' },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true },
                        { id: "delete", label: "", tooltip: "Delete", icon: "Delete", disabled: true }
                    ]
                }
            ]
        }

        this.searchParameters = !utils.isNull(this.props.StakeholderID) ?
            [
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" },
                { Name: "StakeholderID", Value: this.props.StakeholderID },
            ]
            :
            [
                { Name: "LegalName", Value: "" },
                { Name: "SDLNumber", Value: "" },
                { Name: "TradeName", Value: "" },

            ]

        this.headCells = this.props.isLinkSearch ?
            [
                { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
                { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },
            ]
            :
            [
                { id: 'LegalName', numeric: false, disablePadding: true, label: 'Legal Name' },
                { id: 'TradeName', numeric: false, disablePadding: true, label: 'Trade Name' },
                { id: 'SDLNumber', numeric: false, disablePadding: true, label: 'SDL Number' },

            ]

        let model = !utils.isNull(this.props.StakeholderID) ?
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
                StakeholderID: this.props.StakeholderID
            }
            :
            {
                LegalName: "",
                SDLNumber: "",
                TradeName: "",
            }

        this.state = {
            model: model,
            keyUpSwitch: false,
            selectedOrganisation: null,
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedOrganisation: selection })
        } else {
            this.setState({ selectedOrganisation: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.setState({ selectedOrganisation: null })
        }
    }

    render() {
        return (

            <ViewContainer title={""}>
                <SearchView headCells={this.headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/skillsorganisation/getsearch"}
                    entityViewPath='/skillsOrganisation'
                    model={this.state.model}
                    title='DG Organisations'
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    mode={this.props.mode}
                    multiSelect={this.props.multiSelect}
                    onLookupValueChange={this.props.onLookupValueChange}
                    deleteUrl={"api/sims/skillsorganisation/RemoveOrganisation"} useDeletePost={true}
                    descriptionProp="LegalName"
                    selectionCallbackMode={this.props.selectionCallbackMode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    promptTitle={"Remove Organisation"}
                    promptContent={"Are you sure you want to remove this organisation?"}
                    checkRefreshToken={!this.props.IsExternal}
                >
                    <OrganisationSearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                        IsExternal={this.props.IsExternal}
                        LookupUrl={this.props.IsExternal != 'undefined' ? "api/sims/DGStakeholderRegistration/getlookuplist?listid=" : "api/sims/dgorganisation/getlookuplist?listid="}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(StakeholderOrganisationAddSearch)
);


