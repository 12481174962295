import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "./Moderator.css";
import { ViewContainer } from "../../../View.jsx";
import ViewUtils from '../../../ViewUtils';
import withMenuPermission from "../../../widgets/withMenuPermission";
import EnhancedTable from '../../../widgets/ResponsiveTable';
import EnhancedToolbar from '../../../widgets/Toolbar';


const utils = new ViewUtils();

const lowertoolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to moderator approval search', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to qualification scope', theme: true }
            ]
        }
    ]
};

const headCells = [
    { id: 'SAQAQualificationID', numeric: false, disablePadding: true, label: 'Qualification Code' },
    { id: 'SAQAQualificationTitle', numeric: false, disablePadding: true, label: 'Qualification Title' },
    { id: 'Credits', numeric: false, disablePadding: true, label: 'Credits' },
    { id: 'QualificationRegistrationStartDate', numeric: false, disablePadding: true, label: 'Registration Start Date' },
    { id: 'QualificationRegistrationEndDate', numeric: false, disablePadding: true, label: 'Registration End Date' }
];

class OnlineModeratorApprovalQualificationScope extends Component {

    constructor(props) {
        super(props)

        this.state = {
            showTable: false
        }
    }

    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            this.props.history.push({
                pathname: '/OnlineModeratorApprovalSkillsProgrammeScope',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        } else if ("back" === buttonId) {
            this.props.history.push({
                pathname: '/moderatorapproval',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        }
    }

    render() {
        return (
            <ViewContainer title="">
                <p className="breadcrumbs">
                    {"Moderator Approval > Qualification Scope"}
                </p>
                <br></br>
                <EnhancedTable autoFetchData={true} headCells={headCells} searchParameters={[{ Name: `OnlineModeratorAppID`, Value: this.props.location.state.selection }]} paged={false}
                    dataUrl="api/sims/OnlineModeratorApproval/GetQualificationScope" 
                />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={lowertoolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(OnlineModeratorApprovalQualificationScope));

