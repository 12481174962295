import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import { ViewSurveyForm } from './ViewSurveyForm';
import { ViewContainer } from "../../View";
import ViewUtils from "../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();
const surveydetailRef = React.createRef();
const toolbarRef = React.createRef();

class ViewSurvey extends Component {
    constructor(props) {

        super(props);
        this.state = {
            selectedSurvey: this.props.location.state.selection,
            surveyTitle: this.props.location.state.surveyTitle,
            answers: {},
            message: "",
            messageStyle: "",
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        ]
                    },
                    {
                        type: "devider"
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'save', label: 'SUBMIT', tooltip: 'Save' },
                        ]
                    }
                ]
            }
        };
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        } else if ("save" === buttonId) {
            this.save();
        }
    }

    close() {
        if (this.props.location.state.viaDashboard === false) {
            this.props.history.push('/SurveySearch');
        }
        else {
            this.props.history.push('/SurveyDashboardSearch');
        }
        
    }

    successCallback = () => {
        this.setState({ message: "Survey setup updated successfully", messageStyle: "SUCCESS" });
    }

    save() {

        const answersArray = Object.entries(this.state.answers).map(([questionId, answer]) => {
            if (typeof answer === 'object') {
                const checkedOptions = Object.entries(answer)
                    .filter(([option, checked]) => checked)
                    .map(([option]) => option)
                    .join(';');
                return { Id: parseInt(questionId), Answer: checkedOptions };
            } else {
                return { Id: parseInt(questionId), Answer: answer };
            }
        });

        let surveyDataSet = {};
        if (this.state.answers !== null) {
            surveyDataSet.AnswerSet = answersArray;
            surveyDataSet.id = this.props.location.state.selection;
            let input = JSON.stringify(surveyDataSet);

            let url = "api/sims/surveydashboard/saveSurveyData";

            utils.invokeUrlExternal(url, () => {
                this.setState({ message: "Thank you for completing this survey!", messageStyle: "SUCCESS" });
                toolbarRef.current.setButtonDisabled("save", true);
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: input
                }
            )
        }
    };

    handleChange = (questionId, inputType) => {
        return (event) => {
            if (inputType === 'Checkbox') {
                const { name, checked } = event.target;
                this.setState((prevState) => {
                    const questionAnswers = prevState.answers[questionId] || {};
                    return {
                        answers: {
                            ...prevState.answers,
                            [questionId]: {
                                ...questionAnswers,
                                [name]: checked,
                            },
                        },
                    };
                });
            } else {
                const value = event.target ? event.target.value : null;
                if (value !== null) {
                    this.setState((prevState) => ({
                        answers: {
                            ...prevState.answers,
                            [questionId]: value,
                        },
                    }));
                }
            }
        };
    };

    render() {

        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"My Surveys > Complete Survey > " + this.state.surveyTitle }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br></br>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Bulk Email Setup" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                     enabled={true} ref={toolbarRef} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "85vh", maxHeight: "85vh" }}>
                                    <ViewSurveyForm answers={this.state.answers} handleChange={this.handleChange} ref={surveydetailRef} id={this.props.location.state.selection} questions={this.props.location.state.surveyData.Data} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(ViewSurvey));
