import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../widgets/Toolbar';
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { CaptureSiteVisitForm } from './CaptureSiteVisitForm';
import ViewUtils from "./../ViewUtils";
import { ViewContainer } from "../View";
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";


const utils = new ViewUtils();
const ref = React.createRef();

class CaptureSiteVisit extends Component {
    state = {
        message: "",
        ProjectID: this.props.location.state.projectID,
        SiteVisitID: !utils.isNull(this.props.location.state.sitevisitID) ? this.props.location.state.sitevisitID : 0,
        showTable: true,
        model: {
            SiteVisitEndDate: new Date(),
            SiteVisitStartDate: new Date()
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' }
                    ]
                }
            ]
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ SiteVisitDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectSiteVisit',
                state: { projectID: this.props.location.state.projectID }
            });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    SiteVisitID: response.EntityId
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = ref.current.getValue();
        if (!utils.isNull(formValue)) {
            formValue.ID = this.state.SiteVisitID;
            formValue.ProjectID = this.props.location.state.projectID;

            let data = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/projectsitevisit/InsertProjectSiteVisit", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: data
            });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={""}>

                <p className="breadcrumbs">
                    Capture Site Visit
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Moderator" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <CaptureSiteVisitForm viewRef={this} ref={ref} id={this.state.SiteVisitID} valueChangeHandler={(values) => this.handleFormValueChange(values)} data={this.state.model} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(CaptureSiteVisit));

