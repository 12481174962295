import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../View.css";
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import SearchView from "../widgets/SearchView";
import DependancySearchForm from './DependancySearchForm';
import ViewUtils from '../ViewUtils';
import { ViewContainer } from "./../View.jsx";

const headCells = [
    { id: 'TaskName', numeric: false, disablePadding: true, label: 'Task Name' },
    { id: 'FirstName', numeric: false, disablePadding: true, label: 'First Name' },
    { id: 'Surname', numeric: false, disablePadding: true, label: 'Surname' },
    { id: "IDNo", numeric: false, disablePadding: true, label: "ID Number"}
];

const utils = new ViewUtils();

class DependancySearch extends Component {
    constructor(props) {
        super(props);

        this.searchParameters = 
            [
                { Name: "ProjectID", Value: this.props.ProjectID },
                { Name: "MileStoneID", Value: this.props.MileStoneID },            
                { Name: "TaskName", Value: "" },
                { Name: "FirstName", Value: "" },
                { Name: "Surname", Value: "" },
                { Name: "IDNo", Value: "" },
            ]

        this.state = {
            model: {
                FirstName: "",
                Surname: "",
                IDNo: "",
                ID: "",
                ProjectID: this.props.ProjectID,
                MileStoneID: this.props.MileStoneID,
                TaskName: ""
            },
            keyUpSwitch: false,
            selectedTask: null
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedTask: selection })
        } else {
            this.setState({ selectedTask: null })
        }
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <ViewContainer mode={this.props.mode} title={this.state.title}>
                <SearchView headCells={headCells} dataUrl={this.props.dataUrl}
                    model={this.state.model}
                    searchParameters={this.searchParameters} onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="TaskName" mode={this.props.mode}
                    history={this.props.history} multiSelect={this.props.multiSelect}
                    hasButtonClickHandler={true} buttonClickHandler={this.handleToolbarButtonClick}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                    keyUpSwitch={this.state.keyUpSwitch}
                    selectionCallbackMode={this.props.selectionCallbackMode}
                >
                    <DependancySearchForm
                        data={this.state.model}
                        onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
                                this.setState({ model: values });
                            }
                        }}
                    />
                </SearchView>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(DependancySearch)
);
