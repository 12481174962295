import React from 'react';
import TabPanel, { FormTabContainer } from '../widgets/TabPanel';
import { SelectItem } from '../widgets/SelectItem';
import { CustomTextField } from '../widgets/CustomTextField';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';
import { CustomDatePicker } from '../widgets/CustomDatePicker';


const utils = new ViewUtils();

export const ProjectGrantForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="ProjectGrant" values={props.data} org="saseta" dataURL='api/sims/projectGrant/GetFind'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="ProjectGrantForm">
                    <FormTabContainer>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrantsPaid"
                                            key={tabValue}
                                            label="Grants Paid"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form} 
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GrantsClaimed"
                                            key={tabValue}
                                            label="Grants Claimed"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrantsBatched"
                                            key={tabValue}
                                            label="Grants Batched"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GrantsProcessedtoBC"
                                            key={tabValue}
                                            label="Grants Processed to BC"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrantsDue"
                                            key={tabValue}
                                            label="Grants Due"
                                            className="w-100"
                                            InputProps={{
                                                readOnly: false,
                                            }}
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>

    );
})
