import React from 'react';
import 'date-fns';
import ViewUtils from '../ViewUtils';
import Grid from '@material-ui/core/Grid';
import { BaseField } from "./BaseField";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from '@material-ui/pickers';

const utils = new ViewUtils();
const CustomDateTimePickerComponent = React.forwardRef((props, ref) => {
    const base = props.base;
    const defaultDate = props.defaultDate || !utils.isNull(base.valueObject[base.valueProperty]) ? new Date(base.valueObject[base.valueProperty]) : null;
    const minDate = props.minDate || null;
    const [selectedDateTime, setSelectedDateTime] = React.useState(props.defaultDate || new Date());

    const handleDateTimeChange = (date) => {
        const formattedDateTime = new Intl.DateTimeFormat('en-ZA', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Africa/Johannesburg',
        }).format(date);

        console.clear()
        console.log(formattedDateTime)

        setSelectedDateTime(formattedDateTime);
        props.form.doHandleChange(props.id, new Date(formattedDateTime));

        if (!utils.isNull(props.onChange)) {
            props.onChange(props.form, formattedDateTime);
        }
    };

    React.useEffect(() => {
        setSelectedDateTime(defaultDate || props.defaultDate || new Date());
    }, [props.base.valueObject[props.base.valueProperty], props.defaultDate]);

    return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                    <KeyboardDateTimePicker
                        id={props.id}
                        InputProps={props.InputProps}
                        disabled={props.disabled}
                        ref={base.reference}
                        required={base.required}
                        format="dd/MM/yyyy hh:mm a"
                        ampm
                        label={props.label}
                        value={selectedDateTime}
                        className={props.className}
                        error={base.hasError}
                        helperText={base.errorMessage}
                        margin="normal"
                        minDate={minDate} 
                        inputVariant="outlined"
                        style={{ minWidth: "200px" }}
                        onChange={handleDateTimeChange}
                        size="small"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            'disabled': props.disabled
                        }}
                        readOnly={props.readOnly}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
    );
})

export function CustomDateTimePicker(props) {
    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}
            valueParser={(value) => {
                return !utils.isNull(value) && value.trim().length > 0 ? new Date(value) : null;
            }} form={props.form} required={props.required} id={props.id} className={props.className}>
            {base =>
                <CustomDateTimePickerComponent
                    id={props.id}
                    base={base}
                    InputProps={props.InputProps}
                    disabled={props.disabled}
                    required={props.required}
                    label={props.label}
                    onChange={props.onChange}
                    className={props.className}
                    readOnly={props.readOnly}
                    form={props.form}
                    defaultDate={props.defaultDate} 
                    minDate={props.minDate}>  // And this
                </CustomDateTimePickerComponent>
            }
        </BaseField>
    );
}