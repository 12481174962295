import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../widgets/TabPanel';
import { Col, Button } from "react-bootstrap";
import { SelectItem } from '../../widgets/SelectItem';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import AlertItem from "../../widgets/AlertItem";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();

export const SETMISFileForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [message, setMessage] = React.useState()
    const [messageStyle, setMessageStyle] = React.useState()
    const [populateRequestCheck, setPopulateRequestCheck] = React.useState(false)
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    React.useEffect(() => {
        PopulateCheck()
    }, []);

    const populate = (form) => {
        let url = "api/sims/SETMIS/PopulateFiles";

        if (!utils.isNull(form.values.SETMISSnapshotTimeID)) {
            let formObject = form.values.SETMISSnapshotTimeID.Id;
            utils.invokeUrl(url, (response) => {
                successCallback(response);
            },
                (response) => {
                    error(response);
                },
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: JSON.stringify(formObject)
                }
            )
        }
        else {
            setMessageStyle("Warning");
            setMessage("Please select a value");
        }
    }

    const successCallback = (response) => {
        setMessageStyle(response.MessageType);
        setMessage(response.Message);
    }
    const error = (response) => {
        setMessageStyle(response.MessageType);
        setMessage(response.Message);
    }

    const download = (form) => {
        if (!utils.isNull(form.values.SETMISSnapshotTimeFileID)) {
            utils.invokeUrl("api/sims/SETMIS/DownloadFile?SnapshotTimeID=" + form.values.SETMISSnapshotTimeFileID.Id, (response) => {

                var blob = new Blob([base64ToArrayBuffer(response.Base64)], { type: response.Type });
                var url = URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                link.download = response.Name;
                link.target = '_blank';
                link.click();
            }, (e) => {
                setMessageStyle("outlinedError");
                setMessage(utils.SYSTEM_ERROR_MESSAGE);
            }
            );
        }
    }

    const base64ToArrayBuffer = (base64) => {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    const PopulateCheck = () => {
        let url = "api/sims/SETMIS/GetPopulateRequestCheck"
        utils.invokeUrl(url, (response) => {
            if (response.EntityId === 1) {
                setPopulateRequestCheck(false);
            }
            else {
                setPopulateRequestCheck(true);
                setMessageStyle(response.MessageType);
                setMessage(response.Message);
            }
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    return (
        <EntityForm ref={ref} viewId="provider" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="SETMISFilesForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                                <Tab label="Populate SETMIS Files" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                                <Tab label="Download SETMIS Files" {...a11yProps(1)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <AlertItem message={message} alertStyle={messageStyle} />
                            <br />
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                required={true}
                                                dataUrl="api/sims/SETMIS/GetSETMISPopulateLookupList"
                                                id="SETMISSnapshotTimeID"
                                                key={tabValue}
                                                parentId={0}
                                                label="SETMIS Files"
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form} />
                                        </td>
                                    </tr>                                  
                                </tbody>
                            </table>
                            <Col sm="6" style={{ marginTop: '50px' }}>
                                <Button
                                    block
                                    type="button"
                                    style={{ backgroundColor: 'black !important' }}
                                    className="btn btn-success"
                                    id="Populate"
                                    onClick={() => { populate(form); }}
                                    disabled={populateRequestCheck}
                                >
                                Populate SETMIS Files
                             </Button>
                            </Col>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <table cellPadding="2" className="w-100">
                                <tbody>
                                    <tr>
                                        <td>
                                            <SelectItem
                                                required={true}
                                                dataUrl="api/sims/SETMIS/GetSETMISDownloadsLookupList"
                                                id="SETMISSnapshotTimeFileID"
                                                key={tabValue}
                                                parentId={0}
                                                label="SETMIS Files"
                                                className="w-100"
                                                allowEmptyValue={true}
                                                form={form} />
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Col sm="6" style={{ marginTop: '50px' }}>
                                <Button
                                    block
                                    type="button"
                                    style={{ backgroundColor: 'black !important' }}
                                    className="btn btn-success"
                                    id="Download"
                                    onClick={() => { download(form); }}
                                >
                                    Download SETMIS Files
                             </Button>
                            </Col>
                        </TabPanel>
                    </FormTabContainer>
                </form >
            }

        </EntityForm >
    );
})