import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "../../../View.css";
import { AddProviderRoleForm } from './AddProviderRoleForm';
import ViewUtils from '../../../ViewUtils';
import EnhancedToolbar from '../../../widgets/Toolbar';
import Button from '@material-ui/core/Button';
import { ViewContainer } from "../../../View.jsx";
import AlertItem from "../../../widgets/AlertItem";

const utils = new ViewUtils();
const nextToolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'next', label: 'NEXT', tooltip: 'Add', theme: true }
            ]
        }
    ]
};

const createNewToolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'createNew', label: 'CREATE NEW', tooltip: 'Proceed with registration process', width: "150px", theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddProviderRoleSearch extends Component {

    state = {
        formData: {},
        selection: [],
        data: {},
        message: "",
        searchResult: {},
        searched: false,
        hasError: false,
        showTable: true,
        IsExternal: utils.isNull(this.props.IsExternal) ? false : this.props.IsExternal
    };

    handleToolbarButtonClick(buttonId) {
        if (!utils.isNull(this.state.formData)) {

            let data = utils.isNull(this.props.data) ? {} : this.props.data;
            if (!utils.isNull(this.state.data.ProviderDetails)) {
                data.providerIdentityDetails = {};
                data.providerIdentityDetails.formData = this.state.formData;
                data.providerIdentityDetails.searchResult = this.state.searchResult;
                data.providerIdentityDetails.searched = true;
                data.providerRoleDetails = {};
                data.providerRoleDetails.Provider = this.state.data.ProviderDetails;
            }
            else {
                data.providerIdentityDetails = {};
                data.providerIdentityDetails.formData = this.state.formData;
                data.providerIdentityDetails.searchResult = this.state.searchResult;
                data.providerIdentityDetails.searched = true;
            }

            this.props.stepHandler(0, true, data);
        }
    }

    search() {
        this.setState({ message: "", hasError: false });
        let formValue = formRef.current.getValue();
        if (formValue !== null && typeof formValue !== 'undefined') {
            formValue.RegistrationType = this.props.registrationType;
            this.setState({ message: "", hasError: false });
            var currentParameters = [{ Name: "SDL Number", Value: this.state.formData.SDLNumber }];

            if (this.props.IsExternal) {
                utils.invokeUrlExternal("api/sims/" + this.props.role + "/addProviderSearch?providerIdentityData=" + JSON.stringify(formRef.current.getValue()),
                    (data) => {
                        //let result = JSON.parse(data);
                        let result = data;
                        this.setState({ searchResult: result, searched: true })
                        if (this.props.registrationType != "reaccreditation") {
                            //if (result.ProviderFound === true) {
                            //    this.setState({ message: "An Provider with the specified criteria already exist.", hasError: true });
                            //}
                        }
                        else {
                            if (result.ProviderFound === true) {
                                this.setState({ message: "An Provider with the specified criteria was found.", hasError: false });
                            }
                            else {
                                this.setState({ message: "A Provider with the specified criteria cannot be found. Please confirm that all entered details are correct.", hasError: true });
                            }
                        }

                        if (result.DataValid === false) {
                            this.setState({ message: result.InvalidDataMessage, hasError: true });
                        }

                        //if (result.ProviderFound) {
                        this.setState({ data: result })
                        //}
                    }, null);
            }
            else {
                utils.invokeUrl("api/sims/" + this.props.role + "/addProviderSearch?providerIdentityData=" + JSON.stringify(formRef.current.getValue()),
                    (data) => {
                        //let result = JSON.parse(data);
                        let result = data;
                        this.setState({ searchResult: result, searched: true })
                        if (result.RoleRecordFound === true) {
                            this.setState({ message: "A Provider with the specified criteria already exist", hasError: true });
                        }

                        if (result.DataValid === false) {
                            this.setState({ message: result.InvalidDataMessage, hasError: true });
                        }

                        //if (result.ProviderFound) {
                        this.setState({ data: result })
                        //}
                    }, null);
            }

            this.setState({
                searchParameters: currentParameters
            });
        }
    }
    render() {
        return (
            <ViewContainer topPadding="0">
                <AlertItem message="Kindly note that you need all the information and documents to be available as per the guidelines to complete this application.  No data will be saved without all information completed. 
                Required document are as follow: Proof of registration of the company / organisation, Quality Management System, Proof that the organisation can develop, deliver, and evaluate learning programmes,  
                Management of Off-site and Work-site components (Policies and procedures), Proof of financial resources for training, Staff selection, appraisal and development policies and procedures, 
                Proof of registration of Assessors or access to Assessors, System for the Management of Assessments , System for the Moderation of training, Learner entry, guidance, and support system, Administrative Record Keeping and Reporting Capacity,
                Letter of intent to only apply for accreditation with FoodBev SETA" alertStyle="WARNING" />
                <p className="legend">
                    Provider Online Registration
                </p>
                <div className="w-100" style={{ overflowY: "auto", maxHeight: "55vh" }}>
                    <table className="w-100" border="0">
                        <tbody>
                            <tr>
                                <td width="50%" valign="top">
                                    <p style={{ height: "34px", paddingTop: "16px" }}>Please provide us with the SDL Number:</p>
                                    {
                                        !utils.isStringEmpty(this.state.message) ?
                                            <p className={this.state.hasError ? "errorMessage" : "message"}>
                                                {this.state.message}
                                            </p>
                                            :
                                            ""
                                    }
                                    {this.state.showTable === true && (
                                        <AddProviderRoleForm role={this.props.role} IsExternal={this.props.IsExternal} values={this.state.formData} ref={formRef} SecondaryRegistrationType={this.props.registrationType} />
                                    )}
                                    <br />
                                    <Button variant="contained" component="span"
                                        onClick={this.search.bind(this)}>
                                        Validate
                                    </Button>
                                    <div style={{ height: "42%", marginRight: "32px" }}>
                                        {
                                            this.state.searchResult.ProviderFound === true ?
                                                <div style={{ height: "100%" }}>
                                                    <div className="addLearnerSearchResults">
                                                        <div>The following organisation has been found using the supplied SDL Number. ( {this.state.searchResult.ProviderVerificationMessage} )</div>
                                                    </div>
                                                    {this.state.searchResult.ProviderFound === true && this.props.registrationType == "reaccreditation" ?
                                                        <table width="90%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="right">
                                                                        <div style={{ width: "64px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={nextToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        : ""
                                                    }
                                                    {this.props.registrationType == "newaccreditation" ?
                                                        <table width="90%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="right">
                                                                        <div style={{ width: "64px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={nextToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        : ""
                                                    }
                                                    {this.props.registrationType == "programmeapproval" ?
                                                        <table width="90%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="right">
                                                                        <div style={{ width: "64px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={nextToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        : ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.ProviderFound === false &&
                                                this.state.searchResult.DataValid === true && this.state.searched === true && this.state.searchResult.NewProvider === true && this.props.registrationType == "newaccreditation" ?
                                                <div>
                                                    <div className="addLearnerSearchResults">
                                                        <div>{this.state.searchResult.ProviderVerificationMessage}</div>
                                                    </div>
                                                    {this.state.searchResult.SDLNumberValid === true ?
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="left">
                                                                        <div style={{ width: "150px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={createNewToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.ProviderFound === false &&
                                                this.state.searchResult.DataValid === true && this.state.searched === true && this.state.searchResult.NewProvider === false && this.props.registrationType == "newaccreditation" ?
                                                <div>
                                                    <div className="addLearnerSearchResults">
                                                        <div>No existing record found for this Provider, kindly continue with your application.</div>
                                                    </div>
                                                    {this.state.searchResult.SDLNumberValid === true ?
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="left">
                                                                        <div style={{ width: "150px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={createNewToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.ProviderFound === false &&
                                                this.state.searchResult.DataValid === false && this.state.searched === true && this.state.searchResult.NewProvider === false && this.props.registrationType == "reaccreditation" ?
                                                <div>
                                                    <div className="addLearnerSearchResults">
                                                        <div>Cannot find any existing records for this provider. Please ensure provider is not already accredited.</div>
                                                    </div>
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.ProviderFound === false &&
                                                this.state.searchResult.DataValid === true && this.state.searched === true && this.state.searchResult.NewProvider === false && this.props.registrationType == "programmeapproval" ?
                                                <div>
                                                    <div className="addLearnerSearchResults">
                                                        <div>No existing record found for this Provider, kindly continue with your application.</div>
                                                    </div>
                                                    {this.state.searchResult.SDLNumberValid === true ?
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="left">
                                                                        <div style={{ width: "150px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={createNewToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.ProviderFound === false &&
                                                this.state.searchResult.DataValid === false && this.state.searched === true && this.state.searchResult.NewProvider === false && this.props.registrationType == "programmeapproval" ?
                                                <div>
                                                    <div className="addLearnerSearchResults">
                                                        <div>Cannot find any existing records for this provider. Please ensure provider is not already accredited.</div>
                                                    </div>
                                                </div>
                                                :
                                                ""
                                        }
                                        {
                                            this.state.searchResult.ProviderFound === false &&
                                                this.state.searchResult.DataValid === true && this.state.searched === true && this.state.searchResult.NewProvider === true && this.props.registrationType == "programmeapproval" ?
                                                <div>
                                                    <div className="addLearnerSearchResults">
                                                        <div>{this.state.searchResult.ProviderVerificationMessage}</div>
                                                    </div>
                                                    {this.state.searchResult.SDLNumberValid === true ?
                                                        <table width="100%">
                                                            <tbody>
                                                                <tr>
                                                                    <td width="90%">
                                                                    </td>
                                                                    <td width="10%" align="left">
                                                                        <div style={{ width: "150px" }}>
                                                                            <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={typeof this.state.selection !== 'undefined' && this.state.selection !== null && this.state.selection.length > 0} config={createNewToolbarConfig} />
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        :
                                                        ""
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddProviderRoleSearch)
);
