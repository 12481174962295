import React from 'react';
import { EntityForm } from '../../../widgets/Forms';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { Row, Col } from 'react-bootstrap';

export default function OnlineModeratorApprovalSearchForm(props) {
    return (
        <EntityForm ref={React.createRef()} viewId="moderator" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="OnlineModeratorSearchForm">
                    <div className="row search-form-container">
                        <div className="col form-col">
                            <CustomTextField
                                id="FirstName"
                                label="First Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="MiddleName"
                                label="Middle Name"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="Surname"
                                label="Surname"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="IDNo"
                                label="ID Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                        <div className="col form-col">
                            <CustomTextField
                                id="RegistrationNumber"
                                label="Registration Number"
                                className="w-100"
                                onKeyPress={props.onKeyUp}
                                InputProps={{
                                    readOnly: false,
                                }}
                                form={form}
                            />
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
}
