import React, { useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel, { FormTabContainer } from '../../../widgets/TabPanel';
import { SelectItem } from '../../../widgets/SelectItem';
import { CustomTextField } from '../../../widgets/CustomTextField';
import { LookupField } from '../../../widgets/LookupField';
import ProviderSearch from '../../provider/ProviderSearch';
import EmployerSearch from '../../employer/EmployerSearch';
import { CustomDatePicker } from '../../../widgets/CustomDatePicker';
import AppBar from '@material-ui/core/AppBar';
import { EntityForm } from '../../../widgets/Forms';
import TradeSetupSearch from '../../program/TradeSetupSearch';
import ViewUtils from "../../../ViewUtils";
import LearnerQualificationSearch from '../../program/LearnerQualificationSearch';
import ProjectSearch from '../../grants/grantsetup/ProjectSearch'


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const utils = new ViewUtils();
export const LearnerTradeForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    function GetProgrammeStatusID(value) {
        console.clear();
        console.log(value);
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function TerminationCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Terminated") {
                return true
            }
            else if (value.Description !== "Terminated") {
                return false
            }
        }
    }

    function SponsorCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "SETA Funded") {
                return true
            }
            else if (value.Description !== "Industry Funded") {
                return false
            }
        }
    }

    function SocioCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Employed") {
                return true
            }
            else if (value.Description !== "Employed") {
                return false
            }
        }
    }

    function terminationReasonCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Description === "Other") {
                return true
            }
            else if (value.Description !== "Other") {
                return false
            }
        }
    }

    useEffect(() => {

    }, [])

    return (
        <EntityForm ref={ref} values={props.data} viewId="TradeSetup" dataURL="api/sims/learnerTrade/GetLearnerLinkedTradeDetails"
            entityLookupId={props.id} org="saseta" useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="learnerTradeForm">
                    <FormTabContainer>
                        <AppBar position="static" color="default" style={{ background: 'transparent', boxShadow: 'none', borderBottom: '1px solid #d1d1d1' }}>
                            <Tabs value={tabValue} onChange={handleTabChange} indicatorColor="primary" textColor="primary" aria-label="tabs">
                            <Tab label="Trade Details" {...a11yProps(0)} style={{ color: form.fieldParentErrors[0] === true ? "red" : null }} />     
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <table cellPadding="2" width="80%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="AgreementReferenceNumber"
                                                key={tabValue}
                                                label="Artisan Agreement Reference Number"
                                                className="w-100 "
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        <td>
                                            <LookupField
                                                id="LeadProviderID"
                                                label="Lead Provider"
                                                className="w-100"
                                                form={form}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                            >
                                                <ProviderSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerTrade/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("LeadProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="SecondaryProviderID"
                                                label="Seconday Provider"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                form={form}
                                            >
                                                <ProviderSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerTrade/ProviderSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("SecondaryProviderID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                required={true}
                                                id="TradeID"
                                                label="Trade"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <TradeSetupSearch showTitle={false} mode='lookup' dataUrl="api/sims/learnerTrade/TradeSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("TradeID", value);
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <LookupField
                                                id="TradeQualificationID"
                                                label="Qualification"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                            >
                                                <LearnerQualificationSearch showTitle={true} mode='lookup'
                                                    dataUrl='api/sims/learnerTrade/QualificationSearch'
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("TradeQualificationID", value)
                                                    }} autoFetchData={true} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                        <td>
                                            <LookupField
                                                id="EmployerID"
                                                label="Employer"
                                                className="w-100"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                form={form}
                                                required={SocioCheck(form.values["SocioEconomicStatusID"])}
                                            >
                                                <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/learnerTrade/EmployerSearch"
                                                    onLookupValueChange={(value) => {
                                                        form.setLookupSelection("EmployerID", value)
                                                    }} autoFetchData={false} selection={props.selection} />
                                            </LookupField>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=Sponsorship"
                                                id="SponsorshipID"
                                                parentId={0}
                                                required
                                                key={tabValue}
                                                label="Sponsorship"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=ArtisanProject"
                                                id="ArtisanProjectID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Artisan Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                required
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=Project"
                                                id="ProjectID"
                                                parentId={0}
                                                required={SponsorCheck(form.values["SponsorshipID"])}
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Project"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                                allowEditEmptyValue={true}
                                            />
                                        </td>
                                        <td>
                                            <CustomTextField
                                                id="ContractNumber"
                                                key={tabValue}
                                                label="Contract Number"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className="w-100"
                                                InputProps={{
                                                    readOnly: false,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=FinancialYear"
                                                id="FinancialYearID"
                                                parentId={0}
                                                required
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                key={tabValue}
                                                label="Financial Year"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        <td>
                                            <CustomDatePicker
                                                id="CommencementDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                label="Commencement Date"
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                className='{classes.textField} w-100 '
                                                required
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <CustomDatePicker
                                                id="CompletionDate"
                                                validationGroup="registrationDates"
                                                key={tabValue}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                label="Completion Date"
                                                className='{classes.textField} w-100 '
                                                required={!TerminationCheck(form.values["ProgrammeStatusID"])}
                                                validator={{
                                                    script: (formValues) => {
                                                        return !utils.isNull(formValues)
                                                            && !utils.isNull(formValues.CommencementDate)
                                                            && formValues.CommencementDate.getTime() < formValues.CompletionDate.getTime()
                                                    }, message: "Start date must be before the end date"
                                                }}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                form={form}
                                            />
                                        </td>
                                        {
                                            (!props.hideFields) ?
                                                <td>
                                                    <CustomDatePicker
                                                        id="CompletionProcessedDate"
                                                        key={tabValue}
                                                        label="Completion Processed Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    <tr>
                                        <td colSpan="1">
                                            <SelectItem
                                                dataUrl="api/sims/learnerTrade/GetLookupList?listId=SocioEconomicStatus"
                                                id="SocioEconomicStatusID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                required
                                                key={tabValue}
                                                label="Socio Economic Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                        </td>
                                        {
                                            (!props.hideFields) ?
                                        <td>
                                            <SelectItem
                                                dataUrl={'api/sims/learnertrade/GetProgrammeStatusByRole?parameter=[{"Name":"ProgrammeStatusID","Value":' + GetProgrammeStatusID(form.values["ProgrammeStatusID"]) + '}]'}
                                                id="ProgrammeStatusID"
                                                parentId={0}
                                                disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !props.editPermission.Edit}
                                                key={tabValue}
                                                label="Programme Status"
                                                className="{classes.select} w-100"
                                                allowEmptyValue={true}
                                                form={form}
                                                InputProps={{
                                                    readOnly: props.disableFields
                                                }}
                                            />
                                                </td>
                                                :
                                                ""
                                        }
                                    </tr>
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="EnrolmentDate"
                                                        key={tabValue}
                                                        label="Enrolment Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="EnrolledBy"
                                                        key={tabValue}
                                                        label="Enrolled By"
                                                        className="w-100"
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ExtensionDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="DateExtensionCaptured"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Extension Captured Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="ExtensionReason"
                                                        key={tabValue}
                                                        label="Extension Reason"
                                                        className="w-100 "
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="TerminationDate"
                                                        key={tabValue}
                                                        label="Termination Date"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerTrade/GetLookupList?listId=TerminationReason"
                                                        id="TerminationReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Termination Reason"
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        required={TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !TerminationCheck(form.values["ProgrammeStatusID"])}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomTextField
                                                        id="OtherTerminationReason"
                                                        key={tabValue}
                                                        label="Other Termination Reason"
                                                        className="w-100 "
                                                        required={terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : !terminationReasonCheck(form.values["TerminationReasonID"])}
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomDatePicker
                                                        id="ActualTerminationDate"
                                                        key={tabValue}
                                                        label="Actual Termination Date"
                                                        disabled
                                                        className='{classes.textField} w-100 '
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="MostRecentRegistrationDate"
                                                        validationGroup="registrationDates"
                                                        key={tabValue}
                                                        label="Most Recent Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        required
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td >
                                                    <SelectItem
                                                        dataUrl="api/sims/learnerTrade/GetLookupList?listId=EnrolmentStatusReason"
                                                        id="EnrolmentStatusReasonID"
                                                        parentId={0}
                                                        key={tabValue}
                                                        label="Enrolment Status Reason"
                                                        disabled={!utils.isNull(props.viewOnly) ? props.viewOnly : false}
                                                        className="{classes.select} w-100"
                                                        allowEmptyValue={true}
                                                        form={form}
                                                        InputProps={{
                                                            readOnly: props.disableFields
                                                        }}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="CaptureDate"
                                                        key={tabValue}
                                                        label="Capture Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="CaptureByUser"
                                                        key={tabValue}
                                                        label="Capture By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="LastDateUpdated"
                                                        key={tabValue}
                                                        label="Last Date Updated"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="LastUpdatedByUser"
                                                        key={tabValue}
                                                        label="Last Updated By User"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                                <td>
                                                    <CustomDatePicker
                                                        id="RegistrationDate"
                                                        key={tabValue}
                                                        label="Registration Date"
                                                        className='{classes.textField} w-100 '
                                                        disabled
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                    {
                                        (!props.hideFields) ?
                                            <tr>
                                                <td>
                                                    <CustomTextField
                                                        id="RegisteredBy"
                                                        key={tabValue}
                                                        label="Registered By"
                                                        className="w-100 "
                                                        disabled
                                                        InputProps={{
                                                            readOnly: false,
                                                        }}
                                                        form={form}
                                                    />
                                                </td>
                                            </tr>
                                            :
                                            ""
                                    }
                                </tbody>
                            </table>
                        </TabPanel>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>
    );
})
