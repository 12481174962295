import React from 'react';
import { CustomTextField } from '../../widgets/CustomTextField';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { makeStyles } from '@material-ui/core/styles';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { SelectItem } from '../../widgets/SelectItem';

const useStyles = makeStyles({
    customDatePicker: {
        justifyContent: 'flex-start',
    },
});

const utils = new ViewUtils();
export const AddSurveyForm = React.forwardRef((props, ref) => {
    const classes = useStyles();

    return (
        <EntityForm ref={ref} viewId="AddSurveyForm" values={props.data} dataURL='api/sims/Survey/GetFind'
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="AddSurveyForm">
                    <div className="row w-100">
                        <div className="col w-100">
                            <table cellPadding="2" width="50%">
                                <tbody>
                                    <tr>
                                        <td>
                                            <CustomTextField
                                                id="SurveyTitle"
                                                label="Survey Title"
                                                form={form}
                                                className="w-100"
                                                required
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="50%">
                                            <CustomDatePicker
                                                id="SurveyDueDate"
                                                label="Survey Due Date"
                                                required
                                                className='w-100'
                                                form={form}
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <SelectItem
                                            dataUrl="api/sims/Survey/GetLookupList?listId=SurveyStatus"
                                            id="SurveyStatusID"
                                            label="Survey Status"
                                            className="w-100"
                                            form={form}
                                            allowEmptyValue={true}
                                        />
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </form>
            }
        </EntityForm>
    );
})

