import React from 'react';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';
import { CustomTextField } from '../../widgets/CustomTextField';



const utils = new ViewUtils();
export const QuestionForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);

    return (
        <EntityForm ref={ref} viewId="QuestionForm"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="QuestionForm">
                    <table cellPadding="2" width="100%">
                        <tbody>
                        <tr>
                            <td>
                                <CustomTextField
                                    id="QuestionDescription"
                                    required
                                    label="Question Description"
                                    className="w-100"
                                    form={form}

                                />
                            </td>
                            <td>
                                <SelectItem
                                    dataUrl="api/sims/survey/GetLookupList?listId=SurveyQuestionType"
                                    id="QuestionTypeID"
                                    required
                                    label="Question Type"
                                    className="w-100"
                                    form={form}
                                />
                            </td>
                            </tr>
                            <tr>
                                <td>
                                    <CustomTextField
                                        id="QuestionAnswerList"
                                        label="Question Answer List"
                                        className="w-100"
                                        form={form}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
