import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../widgets/Toolbar';
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import { CaptureMilestoneForm } from './CaptureMilestoneForm';
import ViewUtils from "./../ViewUtils";
import { ViewContainer } from "../View";
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import UserSearch from "../etqa/UserSearch";

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const utils = new ViewUtils();
const ref = React.createRef();

class CaptureMilestone extends Component {
    state = {
        message: "",
        SnackMessage: "",
        SnackMessageType: "",
        messageType: "",
        MilestoneID: !utils.isNull(this.props.location.state.MileStoneID) ? this.props.location.state.MileStoneID : 0,
        showTable: true,
        openEscalationModal: false,
        UserDetails: null,
        model: {
            ResourceID: null
        },
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' },
                        { id: 'escalate', label: 'ESCALATE', tooltip: 'Escalate this milestone', visible: this.props.location.state.isEdit }
                    ]
                }
            ]
        }
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ MilestoneDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectMilestone',
                state: { projectID: this.props.location.state.projectID }
            });
        } else if ("escalate" === buttonId) {
            this.setState({ openEscalationModal: true });
        }
    }

    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    MilestoneID: response.EntityId
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }
    
    save() {
        let formValue = ref.current.getValue();
        if (!utils.isNull(formValue)) {
            formValue.ID = this.state.MilestoneID;
            formValue.ProjectID = this.props.location.state.projectID;

            let data = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/projectmilestone/InsertProjectMilestone", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: data
            });
        }
    }

    handleOpenEscalationModal = () => {
        this.setState({ openEscalationModal: true });
    }

    handleCloseEscalationModal = () => {
        this.setState({ openEscalationModal: false, selectedEscalationUser: null });
    }

    email = () => {
        console.log(this.state.UserDetails)
        let data = {
            ProjectID: this.props.location.state.projectID,
            MileStoneID: this.state.MilestoneID,
            EscalationUserID: this.state.UserDetails[0].Id
        }

        utils.invokeUrl("api/sims/projectmilestone/EmailMilestoneEscalation", (response) => {
            this.handleAlertOpen();
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    }

    handleAlertOpen = () => {
        this.setState({ alertOpen: true, SnackMessage: "Milestone escalation sent successfully", SnackMessageType: "success" });
    };

    handleAlertClose = () => {
        this.setState({ alertOpen: false, SnackMessage: "", SnackMessageType: "", UserDetails: null });
    };

    SelectionChangeHandler(values) {
        if (!utils.isNull(values)) {
            this.setState({ UserDetails: values });
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={""}>

                <p className="breadcrumbs">
                    Capture Project Milestone
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Moderator" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                        <CaptureMilestoneForm viewRef={this} ref={ref} id={this.state.MilestoneID} ProjectId={this.props.location.state.projectID} valueChangeHandler={(values) => this.handleFormValueChange(values)}
                                            isEdit={this.props.location.state.isEdit} data={this.state.model} editPermission={this.props.menuPermissionItem.Edit} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Dialog open={this.state.openEscalationModal} onClose={this.handleCloseEscalationModal}>
                    <DialogTitle>Milestone Escalation</DialogTitle>
                    <DialogContent>
                        <UserSearch showTitle={false} mode='lookup' dataUrl="api/sims/projectmilestone/MileStoneUserSearch"
                            SelectionChangeHandler={(values) => this.SelectionChangeHandler(values)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseEscalationModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.email} disabled={!this.state.UserDetails} color="primary">
                            Send Escalation Email
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={this.state.alertOpen} autoHideDuration={6000} onClose={this.handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={this.handleAlertClose} severity={this.state.SnackMessageType} >
                        {this.state.SnackMessage}
                    </Alert>
                </Snackbar>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(CaptureMilestone));

