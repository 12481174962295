import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import "./Survey.css";
import SearchView from "../widgets/SearchView";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import withMenuPermission from "../widgets/withMenuPermission";


const toolbarRef = React.createRef();
const utils = new ViewUtils();

const headCells = [
    { id: 'SurveyNumber', numeric: false, disablePadding: true, label: 'Survey Number' },
    { id: 'SurveyTitle', numeric: false, disablePadding: true, label: 'Survey Title' },
    { id: 'NumberOfQuestions', numeric: false, disablePadding: true, label: 'Number of Questions' },
    { id: 'SurveyDueDate', numeric: false, disablePadding: true, label: 'Survey Due Date' }
];

class SurveyDashboardSearch extends Component {

    constructor(props) {
        super(props)

        this.searchParameters = [{ Name: "SurveyID", Value: 0  }]

        this.state = {
            keyUpSwitch: false,
            model: {},
            selectedSurvey: null,
            surveyData: null,
            SurveyTitle: ""
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: "view", label: "COMPLETE SURVEY", tooltip: "View Survey", disabled: true }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedSurvey: selection[0].Id, SurveyTitle: selection[0].SurveyTitle })
            toolbarRef.current.setButtonDisabled("view", false);
        } else {
            this.setState({ selectedSurvey: null, SurveyTitle: null })
            toolbarRef.current.setButtonDisabled("view", true);
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "view") {
            if (!utils.isNull(this.state.selectedSurvey)) {
                this.fetchSurveyData(this.state.selectedSurvey)
            }
        } 
    }

    fetchSurveyData(SurveyID) {
        let url = "api/sims/surveydashboard/GetSurveyData?SurveyID=" + SurveyID;
        utils.invokeUrlExternal(url, (response) => {
            this.setState({
                surveyData: response
            })
            this.props.history.push({
                pathname: '/ViewSurvey',
                state: {
                    selection: this.state.selectedSurvey,
                    surveyData: this.state.surveyData,
                    surveyTitle: this.state.SurveyTitle,
                    viaDashboard: true
                }
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )


    }

    render() {
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/surveydashboard/GetUserOutstandingSurveys"}
                    model={this.state.model}
                    searchParameters={this.searchParameters}
                    toolbarConfig={this.toolbarConfig} 
                    title='Survey Dashboard'
                    mode={this.props.mode}
                    toolbarRef={toolbarRef}
                    search={false}
                    autoFetchData={true}
                    history={this.props.history}
                    onLookupValueChange={this.props.onLookupValueChange} descriptionProp="SurveyNumber"
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(SurveyDashboardSearch));

