import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "./../../View.jsx";
import ViewUtils from "./../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import { LearnerCandidacyForm } from '../../etqa/program/registration/LearnerCandidacyForm';
import withMenuPermission from "../../widgets/withMenuPermission";

const CandidacyRef = React.createRef();
const utils = new ViewUtils();

class AddLearnerCandidacy extends Component {

    state = {
        CandidacyDetails: {
            SAQAQualificationTitle: "",
            SAQAQualificationID: ""
        },
        messageStyle: "message",
        id: null,
        Learner: this.props.location.state.LearnerID,
        CurrentProgramme: this.props.location.state.CandidacyID,
        CreateNewFlag: this.props.location.state.hideFields,
        showTable: true
    };

    toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'close', label: '', tooltip: 'Close', icon: 'CloseIcon' }
            ]
        }
        ,
        {
            type: "devider"
        },
        {
            type: "buttonGroup",
            buttons: [
                { id: 'save', label: '', tooltip: 'Save', icon: 'SaveIcon', visible: !this.props.location.state.viewOnly }
            ]
        }
        //,
        //{
        //    type: "devider"
        //},
        //{
        //    type: "buttonGroup",
        //    buttons: [
        //        { id: 'placement', label: 'Candidacy Placement', tooltip: 'Candidacy Placement', visible: this.state.CurrentProgramme === 0 ? false : true }
        //    ]
        //}
    ]
}


    handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {

            this.setState({
                CandidacyetupDetails: {
                    SAQAQualificationID: values.SAQAQualificationID,
                    SAQAQualificationTitle: values.SAQAQualificationTitle
                },
                Learner: this.props.location.state.LearnerID
            });
        }
    }


    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        } else if ("close" === buttonId) {
            this.closeView();
        }
        //} else if ("placement" === buttonId) {
        //    this.props.history.push({
        //        pathname: '/LearnerCandidacyPlacementSearch',
        //        state: {
        //            LearnerID: this.props.location.state.LearnerID,
        //            CurrentProgramme: this.props.location.state.CurrentProgramme,
        //            hideFields: false,
        //            learnerNames: this.props.location.state.learnerNames
        //        }
        //    })
        //}
    }


    successCallback = (response) => {
        if (response.MessageType === "SUCCESS") {
            this.setState({
                showTable: false
            }, () => {
                this.setState({
                    showTable: true,
                    message: response.Message,
                    messageStyle: response.MessageType,
                    CurrentProgramme: response.EntityId,
                    CreateNewFlag: false
                })
            });
        }
        else {
            this.setState({
                message: response.Message, messageStyle: response.MessageType
            });
        }
    }

    save() {
        let formValue = CandidacyRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.LearnerID;
            let input = JSON.stringify(formValue);

            utils.invokeUrl("api/sims/learnercandidacy/LinkLearnerCandidacy", (response) => {
                this.successCallback(response);
            }, () => { alert(utils.SYSTEM_ERROR_MESSAGE) }, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: input
            });
        }
    }

    closeView() {
        console.clear()
        console.log(this.state.CurrentProgramme)
        this.props.history.push({
            pathname: '/LearnerCandidacySearch',
            state: {
                learnerID: this.props.location.state.LearnerID,
                learnerNames: this.props.location.state.learnerNames
            }
        });
    }

    render() {
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {
                        (this.props.location.state.hideFields) ?
                            `Learner > Link New Candidacy > ${this.props.location.state.learnerNames}`
                            :
                            `Learner > Update Candidacy > ${this.props.location.state.learnerNames}`
                    }
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false}
                                    title=""
                                    buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "65%", maxHeight: "60vh" }}>
                                    {this.state.showTable === true && (
                                    <LearnerCandidacyForm ref={CandidacyRef}
                                        viewRef={this}
                                            id={this.state.CurrentProgramme}
                                            editPermission={this.props.location.state.permissions}
                                            viewOnly={this.props.location.state.viewOnly}
                                        hideFields={this.state.CreateNewFlag}
                                        valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                    )}
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(AddLearnerCandidacy));

