/// <reference path="onlineassessorskillsprogramme.js" />
import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import EnhancedToolbar from '../../../widgets/Toolbar';
import { OnlineAssessorForm } from './OnlineAssessorForm';
import ViewUtils from '../../../ViewUtils';
import { ViewContainer } from "../../../View.jsx";

const utils = new ViewUtils();

const toolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to document upload', theme: true },
                { id: 'next', label: 'NEXT', tooltip: 'Proceed to add registration details', theme: true }
            ]
        }
    ]
};

const formRef = React.createRef();
class AddOnlineAssessorDetails extends Component {
    constructor(props) {
        super(props)
        let data = {};

        if (!utils.isNull(props.data) && !utils.isNull(props.data.personRoleDetails)) {
            if (!utils.isNull(props.data.personRoleDetails.Person)) {
                data = props.data.personRoleDetails.Person;
            }
            else {
                data = props.data.personRoleDetails;
            }
        } else if (!utils.isNull(props.data) && !utils.isNull(props.data.identityDetails)) {
            data = props.data.identityDetails.Person ? props.data.identityDetails.Person : props.data.identityDetails.formData
        } else {
            data.Person = {};
        }

        this.state = {
            model: data
        }
    }

    handleFormValueChange() {
    }

    handleToolbarButtonClick(buttonId) {
        let formValue = formRef.current.getValue();
        if ("back" === buttonId) {
            this.props.stepHandler(1, false, formValue);
        } else if ("next" === buttonId) {
            if (!utils.isNull(formValue)) {
                this.props.stepHandler(1, true, formValue);
            }
        }
    }

    render() {
        return (
            <ViewContainer topPadding="0">
                <p className="legend">
                    Online Assessor Registration Details
                </p>
                <div style={{ height: "50%", maxHeight: "42vh" }}>
                    <OnlineAssessorForm ref={formRef} data={this.state.model} IsExternal={this.props.IsExternal} editPermission={true} valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} config={toolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(
    connect()(AddOnlineAssessorDetails)
);
