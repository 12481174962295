import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import "../Grid.css";
import "../Form.css";
import "../App.css";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import EnhancedToolbar from '../widgets/Toolbar';
import withMenuPermission from "../widgets/withMenuPermission";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';


import TrainingProviderListPerRegionBarChart from '../QALPDashboardCharts/TrainingProviderListPerRegionBarChart';
import TrainingProviderListPerRegionLineChart from '../QALPDashboardCharts/TrainingProviderListPerRegionLineChart';
import TrainingProviderListPerRegionPieChart from '../QALPDashboardCharts/TrainingProviderListPerRegionPieChart';

import TrainingProviderListPerAccreditationStatusLineChart from '../QALPDashboardCharts/TrainingProviderListPerAccreditationStatusLineChart';
import TrainingProviderListPerAccreditationStatusPieChart from '../QALPDashboardCharts/TrainingProviderListPerAccreditationStatusPieChart';
import TrainingProviderListPerAccreditationStatusBarChart from '../QALPDashboardCharts/TrainingProviderListPerAccreditationStatusBarChart';

import TrainingProviderListPerProviderTypePieChart from '../QALPDashboardCharts/TrainingProviderListPerProviderTypePieChart';
import TrainingProviderListPerProviderTypeBarChart from '../QALPDashboardCharts/TrainingProviderListPerProviderTypeBarChart';
import TrainingProviderListPerProviderTypeLineChart from '../QALPDashboardCharts/TrainingProviderListPerProviderTypeLineChart';

import LearnerPerProvinceBarChart from '../QALPDashboardCharts/LearnerPerProvinceBarChart';
import LearnerPerProvinceLineChart from '../QALPDashboardCharts/LearnerPerProvinceLineChart';
import LearnerPerProvincePieChart from '../QALPDashboardCharts/LearnerPerProvincePieChart';

import AssessorPerRegionBarChart from '../QALPDashboardCharts/AssessorPerRegionBarChart';
import AssessorPerRegionLineChart from '../QALPDashboardCharts/AssessorPerRegionLineChart';
import AssessorPerRegionPieChart from '../QALPDashboardCharts/AssessorPerRegionPieChart';

import AssessorPerRegistrationStatusBarChart from '../QALPDashboardCharts/AssessorPerRegistrationStatusBarChart';
import AssessorPerRegistrationStatusLineChart from '../QALPDashboardCharts/AssessorPerRegistrationStatusLineChart';
import AssessorPerRegistrationStatusPieChart from '../QALPDashboardCharts/AssessorPerRegistrationStatusPieChart';

import ModeratorPerRegionBarChart from '../QALPDashboardCharts/ModeratorPerRegionBarChart';
import ModeratorPerRegionLineChart from '../QALPDashboardCharts/ModeratorPerRegionLineChart';
import ModeratorPerRegionPieChart from '../QALPDashboardCharts/ModeratorPerRegionPieChart';

import ModeratorPerRegistrationStatusBarChart from '../QALPDashboardCharts/ModeratorPerRegistrationStatusBarChart';
import ModeratorPerRegistrationStatusLineChart from '../QALPDashboardCharts/ModeratorPerRegistrationStatusLineChart';
import ModeratorPerRegistrationStatusPieChart from '../QALPDashboardCharts/ModeratorPerRegistrationStatusPieChart';

import NumberOfCertificatesCreatedPerInterventionBarChart from '../QALPDashboardCharts/NumberOfCertificatesCreatedPerInterventionBarChart';
import NumberOfCertificatesCreatedPerInterventionLineChart from '../QALPDashboardCharts/NumberOfCertificatesCreatedPerInterventionLineChart';
import NumberOfCertificatesCreatedPerInterventionPieChart from '../QALPDashboardCharts/NumberOfCertificatesCreatedPerInterventionPieChart';


import { Row } from 'react-bootstrap';


const utils = new ViewUtils();


class QALPDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            trainingproviderlistperregionshow: false,
            trainingproviderlistperaccreditationstatusshow: false,
            trainingproviderlistperprovidertypeshow: false,
            assessorperregionshow: false,
            //learnerpersetaqualificationshow: false,
            learnerperprovinceshow: false,
            assessorperregistrationstatusshow: false,
            moderatorperregionshow: false,
            moderatorperregistrationstatusshow: false,
            numberofcertificatescreatedperinterventionshow: false
        };
    }

    componentDidMount() {
        utils.setLegend("QA And LP Dashboard");
        this.setState({ trainingproviderlistperregionshow: true });
    }


    handleReportNameChanged = (event) => {

        var divToShow = event.target.value;

        if (divToShow == 'divTrainingProviderListPerRegion') {
            this.setState({ trainingproviderlistperregionshow: true });

            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divTrainingProviderListPerAccreditationStatus') {
            this.setState({ trainingproviderlistperaccreditationstatusshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divTrainingProviderListPerProviderType') {
            this.setState({ trainingproviderlistperprovidertypeshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divAssessorPerRegion') {
            this.setState({ assessorperregionshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divAssessorPerRegistrationStatus') {            
            this.setState({ assessorperregistrationstatusshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divModeratorPerRegion') {
            this.setState({ moderatorperregionshow: true });

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divModeratorPerRegistrationStatus') {
            this.setState({ moderatorperregistrationstatusshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ learnerperprovinceshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
        else if (divToShow == 'divNumberOfCertificatesCreatedPerIntervention') {
            this.setState({ numberofcertificatescreatedperinterventionshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ learnerperprovinceshow: false });
        }
        else if (divToShow == 'divLearnerPerProvince') {
            this.setState({ learnerperprovinceshow: true });  

            this.setState({ trainingproviderlistperregionshow: false });
            this.setState({ trainingproviderlistperaccreditationstatusshow: false });
            this.setState({ trainingproviderlistperprovidertypeshow: false });
            this.setState({ assessorperregionshow: false });
            this.setState({ assessorperregistrationstatusshow: false });
            this.setState({ moderatorperregionshow: false });
            this.setState({ moderatorperregistrationstatusshow: false });
            this.setState({ numberofcertificatescreatedperinterventionshow: false });
        }
    }



    render() {
        return (

            <div className="container-fluid">
                <br />
                <Row>
                    <TextField className="w-30" id="ReportNameID" label="Report Name: " select margin="normal" size="small" variant="outlined" style={{ minWidth: "200px", width: "100% !important" }} onChange={(values) => this.handleReportNameChanged(values)}>
                        <MenuItem value="divTrainingProviderListPerRegion" key="1">Training Provider List Per Region</MenuItem>
                        <MenuItem value="divTrainingProviderListPerAccreditationStatus" key="2">Training Provider List Per Accreditation Status</MenuItem>
                        <MenuItem value="divTrainingProviderListPerProviderType" key="3">Training Provider List Per Provider Type</MenuItem>
                        <MenuItem value="divAssessorPerRegion" key="4">Assessor Per Region</MenuItem>
                        <MenuItem value="divAssessorPerRegistrationStatus" key="5">Assessor Per Registration Status</MenuItem>
                        <MenuItem value="divModeratorPerRegion" key="6">Moderator Per Region</MenuItem>
                        <MenuItem value="divModeratorPerRegistrationStatus" key="7">Moderator Per Registration Status</MenuItem>
                        <MenuItem value="divNumberOfCertificatesCreatedPerIntervention" key="8">Number Of Certificates Created Per Intervention</MenuItem>
                        <MenuItem value="divLearnerPerProvince" key="9">Learner Per Province</MenuItem>
                    </TextField>
                </Row>

                <br />

                {
                    this.state.trainingproviderlistperregionshow ?
                        <div id="divTrainingProviderListPerRegion" key="1" >
                            <Row>
                                <TrainingProviderListPerRegionPieChart />
                                <TrainingProviderListPerRegionLineChart />

                            </Row>
                            <Row>
                                <TrainingProviderListPerRegionBarChart />
                            </Row>
                        </div> : null
                }

              
                {
                    this.state.trainingproviderlistperaccreditationstatusshow ?
                        <div id="divTrainingProviderListPerAccreditationStatus" key="2">
                            <Row>
                                <TrainingProviderListPerAccreditationStatusPieChart />
                                <TrainingProviderListPerAccreditationStatusLineChart />
                            </Row>
                            <Row>
                                <TrainingProviderListPerAccreditationStatusBarChart />
                            </Row>
                        </div> : null
                }

               

                {
                    this.state.trainingproviderlistperprovidertypeshow ?
                        <div id="divTrainingProviderListPerProviderType" key="3">
                            <Row>
                                <TrainingProviderListPerProviderTypePieChart />
                                <TrainingProviderListPerProviderTypeLineChart />
                            </Row>
                            <Row>
                                <TrainingProviderListPerProviderTypeBarChart />
                            </Row>
                        </div> : null
                }

              

                {
                    this.state.assessorperregionshow ?
                        <div id="divAssessorPerRegion" key="4">
                            <Row>
                                <AssessorPerRegionPieChart />
                                <AssessorPerRegionLineChart />
                            </Row>
                            <Row>
                                <AssessorPerRegionBarChart />
                            </Row>
                        </div> : null
                }

                                             

                {
                    this.state.assessorperregistrationstatusshow ?
                        <div id="divAssessorPerRegistrationStatus" key="5">
                            <Row>
                                <AssessorPerRegistrationStatusPieChart />
                                <AssessorPerRegistrationStatusLineChart />
                            </Row>
                            <Row>
                                <AssessorPerRegistrationStatusBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.moderatorperregionshow ?
                        <div id="divModeratorPerRegion" key="6">
                            <Row>
                                <ModeratorPerRegionPieChart />
                                <ModeratorPerRegionLineChart />
                            </Row>
                            <Row>
                                <ModeratorPerRegionBarChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.moderatorperregistrationstatusshow ?
                        <div id="divModeratorPerRegistrationStatus" key="7">
                            <Row>
                                <ModeratorPerRegistrationStatusBarChart />
                                <ModeratorPerRegistrationStatusPieChart />
                            </Row>
                            <Row>
                                <ModeratorPerRegistrationStatusLineChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.numberofcertificatescreatedperinterventionshow ?
                        <div id="divNumberOfCertificatesCreatedPerIntervention" key="8">
                            <Row>
                                <NumberOfCertificatesCreatedPerInterventionBarChart />
                                <NumberOfCertificatesCreatedPerInterventionPieChart />
                            </Row>
                            <Row>
                                <NumberOfCertificatesCreatedPerInterventionLineChart />
                            </Row>
                        </div> : null
                }



                {
                    this.state.learnerperprovinceshow ?
                        <div id="divLearnerPerProvince" key="9">
                            <Row>
                                <LearnerPerProvincePieChart />
                                <LearnerPerProvinceLineChart />
                            </Row>
                            <Row>
                                <LearnerPerProvinceBarChart />
                            </Row>
                        </div> : null
                }

            </div>
        );

    }
}

export default withRouter(withMenuPermission(QALPDashboard));
