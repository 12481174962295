import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import HighchartsReact from 'highcharts-react-official';
import { Card, Col } from 'react-bootstrap';
import Highcharts from 'highcharts';
import './PieChart.css';
import { withStyles } from "@material-ui/core/styles";
import ViewUtils from '../ViewUtils';
import ReactDom from 'react-dom';
import Button from "@material-ui/core/Button";

const styles = {
    fullHeightCard: {
        height: "60%",
        color: "white"
    },
}

const utils = new ViewUtils();
var test = [];
var data = [];
var array = [];
var arrayData = [];
//var RoleID = !utils.isNull(this.props.RoleID) ? this.props.RoleID : 0;
var RoleID = 0;
var categories;
var xAxisCategory = [];
var yAxisCategory = [];



class PerRegionApplicationPieChart extends Component {
    constructor(props) {
        super(props);

        this.state = {
            searchParameters: [{ Name: "RoleID", Value: null },
            { Name: "ExportToExcel", Value: null },
            { Name: "Filter", Value: null }],
            //RoleID: !utils.isNull(this.props.RoleID) ? this.props.RoleID : 0,

            series: [{
                type: 'pie',
                allowPointSelect: true,
                keys: ['name', 'y', 'selected', 'sliced'],
                data: yAxisCategory,
                showInLegend: true,
            }],
            dataLabels: {
                enabled: true,
                format: "{point.y}",
                style: {
                    fontSize: '13px',
                    fontFamily: 'Verdana, sans-serif',
                    color: '#FFFFFF'
                }
            }
        }
    }



    highChartsRender() {

        xAxisCategory.splice(0, xAxisCategory.length);
        yAxisCategory.splice(0, yAxisCategory.length);
        var categories;


        let url = `api/sims/DGDashboard/GetPerRegionApplicationSearch?request={"searchParameters":[{"Name":"RoleID","Value":${RoleID}}, {"Name":"ExportToExcel","Value": "No"}, {"Name":"Filter","Value": ""}]}`

        utils.invokeUrl(url, (response) => {

            test = response;
            //arrayData = new Array();

            //console.log(response);

            for (var i = 0; i < response.length; i++) {
                var arr = response[i].Values
                xAxisCategory.push(arr);
            }


            for (var i = 0; i < response.length; i++) {
                yAxisCategory.push([response[i].Values, response[i].Total]);
            }

            categories = response.map((o) => String(o.Values))


            Highcharts.chart('piecontainer', {
                chart: {
                    styledMode: true,
                    height: '350px',
                },

                title: {
                    text: ' '
                },

                xAxis: {
                    title: "Region",
                    // keys: ['x'],
                    categories: categories
                },
                legend: {
                    enabled: true
                },
                tooltip: {
                    pointFormat: 'Region: <b>{point.y:.1f} TotalAppliations</b>'
                },
                plotOptions: {
                    series: {
                        cursor: 'pointer',
                        point: {
                            events: {
                                click: (e) => {

                                    let searchParameters = [];


                                    searchParameters.push({ Name: "RoleID", Value: this.RoleID })
                                    searchParameters.push({ Name: "ExportToExcel", Value: "Yes" })
                                    searchParameters.push({ Name: "Filter", Value: xAxisCategory[e.point.x] })
                                    console.clear();
                                    console.log(searchParameters);

                                    utils.invokeUrl("api/sims/DGDashboard/GetPerRegionApplicationExcel?request=" + utils.doGetRequestJson(0, 0, searchParameters, true), (data) => {
                                        var result;
                                        if (typeof data === "string") {
                                            result = JSON.parse(data);
                                        } else {
                                            result = data;
                                        }

                                        var blob = new Blob([this.base64ToArrayBuffer(result.Base64)], { type: result.Type });
                                        var url = URL.createObjectURL(blob);

                                        const link = document.createElement('a');
                                        link.href = url;
                                        link.download = result.Name;
                                        link.target = '_blank';
                                        link.click();

                                    }, (e) => {
                                        this.setState({ message: "Unable to execute report. A system error has occurred. Please contact your system administrator", messageStyle: "ERROR" });
                                    });


                                }
                            }
                        }
                    }
                },


                //plotOptions:
                //{
                //    series:
                //    {
                //        connectNulls: false,
                //        marker:
                //        {
                //            enabled: true,
                //            value: 'x'
                //        }
                //    }
                //},

                series: this.state.series
            });
        });
    }

    componentDidMount() {
        this.highChartsRender();
    };


    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    ExportAllToExcel() {
        let searchParameters = [];

        searchParameters.push({ Name: "RoleID", Value: RoleID })
        searchParameters.push({ Name: "ExportToExcel", Value: "All" })
        searchParameters.push({ Name: "Filter", Value: "" })


        utils.invokeUrl("api/sims/DGDashboard/GetPerRegionApplicationExcel?request=" + utils.doGetRequestJson(0, 0, searchParameters, true), (data) => {
            var result;
            if (typeof data === "string") {
                result = JSON.parse(data);
            } else {
                result = data;
            }

            var blob = new Blob([this.base64ToArrayBuffer(result.Base64)], { type: result.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = result.Name;
            link.target = '_blank';
            link.click();

        }, () => {
            this.setState({ message: "Unable to execute report. A system error has occurred. Please contact your system administrator", messageStyle: "ERROR" });
        });
    };

    render() {
        const { chartOptions, hoverData } = this.state;
        const { classes } = this.props;
        return (
            <Col sm="6">
                <Card>
                    <Card.Header as="h6">Per Region Application Pie Chart</Card.Header>
                    <Card.Body id="piecontainer"></Card.Body>
                </Card>
                <Button size="small" color="primary" align="right" onClick={() => this.ExportAllToExcel()}>
                    Print All
                </Button>
            </Col>
        )
    }
}


export default withStyles(styles)(PerRegionApplicationPieChart);   