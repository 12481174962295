import React from 'react';
import { TextField, FormControl, InputLabel, Select, MenuItem, FormControlLabel, Checkbox, Box, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    checkboxBox: {
        marginBottom: 0,
    },
    textField: {
        marginTop: 0,
    },
    label: {
        top: "-5px",
    },
}));

const renderQuestion = (question, questionNumber, handleChange) => {
    const classes = useStyles();

    const formElement = (() => {
        switch (question.QuestionType) {
            case 'Number':
                return (
                    <TextField
                        className="w-100"
                        style={{ marginTop: 35 }}
                        type="number"
                        onChange={handleChange(question.Id, 'Number')}
                        label={`${questionNumber}. ${question.QuestionDescription}`}
                        InputLabelProps={{
                            className: classes.label,
                        }}
                    />
                );
            case 'Dropdown':
                return (
                    <FormControl className="w-100" style={{ marginTop: 25 }}>
                        <InputLabel className={classes.label}>
                            {`${questionNumber}. ${question.QuestionDescription}`}
                        </InputLabel>
                        <Select onChange={handleChange(question.Id, 'Dropdown')} >
                            {question.QuestionAnswerList.split(';').map((option, index) => (
                                <MenuItem key={index} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                );
            case 'Checkbox':
                return (
                    <FormControl className="w-100" component="fieldset">
                        <FormLabel component="legend" style={{ marginBottom: 8 }}>
                            <Box component="span" mr={1}>
                                {questionNumber}.
                            </Box>
                            {question.QuestionDescription}
                        </FormLabel>
                        <Box display="flex" flexDirection="row" className={classes.checkboxBox}>
                            {question.QuestionAnswerList.split(';').map((option, index) => (
                                <FormControlLabel key={index} value={option} control={<Checkbox name={option} onChange={handleChange(question.Id, 'Checkbox')} />} label={option} />
                            ))}
                        </Box>
                    </FormControl>
                );
            case 'Text':
                return (
                    <TextField
                        className={`w-100 ${classes.textField}`}
                        style={{ marginTop: 25 }}
                        label={`${questionNumber}. ${question.QuestionDescription}`}
                        inputProps={{ maxLength: 25 }}
                        onChange={handleChange(question.Id, 'Text')}
                        InputLabelProps={{
                            className: classes.label,
                        }}
                    />
                );
            case 'Comment':
                return (
                    <TextField
                        className={`w-100 ${classes.textField}`}
                        style={{ marginTop: 25 }}
                        label={`${questionNumber}. ${question.QuestionDescription}`}
                        inputProps={{ maxLength: 250 }}
                        onChange={handleChange(question.Id, 'Comment')}
                        InputLabelProps={{
                            className: classes.label,
                        }}
                    />
                );
            default:
                return null;
        }
    })();

    return (
        <Box my={2} className="w-100" width="100%">
            {formElement}
        </Box>
    );
};

export const ViewSurveyForm = React.forwardRef((props, ref) => {
    const { handleChange, questions} = props;

    return (

        <table cellPadding="2" className="w-100">
            <tbody>
                {questions.map((question, index) => (
                    <tr key={index}>
                        <td>
                            {renderQuestion(question, index + 1, handleChange)}
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
})