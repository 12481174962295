import React from 'react';
import 'date-fns';
import ViewUtils from '../ViewUtils';
import Grid from '@material-ui/core/Grid';
import { BaseField } from "./BaseField";
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';

const utils = new ViewUtils();
const CustomDatePickerComponent = React.forwardRef((props, ref) => {
    const base = props.base;
    const defaultDate = !utils.isNull(base.valueObject[base.valueProperty]) ? new Date(base.valueObject[base.valueProperty]) : null;
    const [selectedDate, setSelectedDate] = React.useState(defaultDate);

    const handleDateChange = date => {
        setSelectedDate(new Date(date.setHours(0, 0, 0, 0)));
        props.form.doHandleChange(props.id, new Date(date.setHours(0, 0, 0, 0)));
       
        if (!utils.isNull(props.onChange)) {
            props.onChange(props.form, date);
        }
    };

    React.useEffect(() => {
        setSelectedDate(defaultDate);
    }, [props.base.valueObject[props.base.valueProperty]]);

    return (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Grid container justify="space-around">
                    <KeyboardDatePicker
                        id={props.id}
                        InputProps={props.InputProps}
                        disabled={props.disabled}
                        ref={base.reference}
                        required={base.required}
                        format="dd/MM/yyyy"
                        label={props.label}
                        value={selectedDate}
                        className={props.className}
                        error={base.hasError}
                        helperText={base.errorMessage}
                        margin="normal"
                        inputVariant="outlined"
                        style={{ minWidth: "200px" }}
                        onChange={handleDateChange}
                        minDate={props.minDate}
                        size="small"
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            'disabled': props.disabled
                        }}
                        readOnly={props.readOnly}
                    />
                </Grid>
            </MuiPickersUtilsProvider>
    );
})

export function CustomDatePicker(props) {
    return (
        <BaseField ref={React.createRef()} form={props.form} required={props.required} id={props.id} className={props.className}
            validator={props.validator} validationRegex={props.validationRegex} validationGroup={props.validationGroup}
            validationMessage={props.validationMessage} parentId={props.parentId}
            valueParser={(value) => {
                return !utils.isNull(value) && value.trim().length > 0 ? new Date(value) : null;
            }} form={props.form} required={props.required} id={props.id} className={props.className}>
            {base =>
                <CustomDatePickerComponent
                    id={props.id}
                    base={base}
                    InputProps={props.InputProps}
                    disabled={props.disabled}
                    required={props.required}
                    label={props.label}
                    onChange={props.onChange}
                    className={props.className}
                    minDate={props.minDate}
                    readOnly={props.readOnly}
                    form={props.form}>
                </CustomDatePickerComponent>
            }
        </BaseField>
    );
}