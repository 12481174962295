import React from 'react';
import TabPanel, { FormTabContainer } from '../widgets/TabPanel';
import { CustomTextField } from '../widgets/CustomTextField';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';
import { CustomDatePicker } from '../widgets/CustomDatePicker';


const utils = new ViewUtils();

export const ProjectBeneficiariesForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const handleFormValueChange = (values) => {
        if (!utils.isNull(values)) {
            if (!utils.isNull(props.valueChangeHandler)) {
                props.valueChangeHandler(values);
            }
        }
    }

    return (
        <EntityForm ref={ref} viewId="ProjectBeneficiaries" values={props.data} org="saseta" dataURL='api/sims/ProjectBeneficiaries/GetFind'
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={(values) => { handleFormValueChange(values) }}>
            {form =>
                <form className={form.classes.container} id="ProjectBeneficiariesForm">
                    <FormTabContainer>
                        <table cellPadding="2" width="80%">
                            <tbody>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="LNumber"
                                            label="L Number"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="EmployerName"
                                            label="Employer Name"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="ContractNumber"
                                            label="Contract Number"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="ContractStatus"
                                            label="Contract Status"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomDatePicker
                                            id="ContractStartDate"
                                            label="Contract Start Date"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomDatePicker
                                            id="ContractEndDate"
                                            label="Contract End Date"
                                            className='{classes.textField} w-100'
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="LearnersAwarded"
                                            label="# Learners Awarded"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="LearnersAssociated"
                                            label="Learners associated"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="LearnersRemained"
                                            label="Learners remained"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="AmountAwarded"
                                            label="Amount awarded"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GrantsPaid"
                                            label="GrantsPaid"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrantsClaimsCreated"
                                            label="Grants Claims Created"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GrantsClaimsApproved"
                                            label="Grants Claims Approved"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrantsBatchesCreated"
                                            label="Grants Batches Created"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                    <td>
                                        <CustomTextField
                                            id="GrantsBatchesApproved"
                                            label="Grants Batches Approved"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <CustomTextField
                                            id="GrantsDue"
                                            label="Grants Due"
                                            className="w-100"
                                            form={form}
                                            disabled
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </FormTabContainer>
                </form>
            }
        </EntityForm>

    );
})
