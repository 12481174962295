import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import { AddSurveyForm } from './AddSurveyForm';
import { ViewContainer } from "../../View";
import ViewUtils from "../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";
import EnhancedTable from '../../widgets/ResponsiveTable';
import FormDialog from "../FormDialog";
import { QuestionForm } from "./QuestionForm";
import Prompt from '../../widgets/Prompt';


const utils = new ViewUtils();


const surveydetailRef = React.createRef();
const toolbarRef = React.createRef();
const addFormRef = React.createRef();


const QuestionsTableHeadCells = [
    { id: 'QuestionDescription', numeric: false, disablePadding: true, label: 'Question Description' },
    { id: 'QuestionType', numeric: false, disablePadding: true, label: 'Question Type' },
    { id: 'QuestionAnswerList', numeric: false, disablePadding: true, label: 'Possible Answer List' },
]

class AddSurvey extends Component {
    constructor(props) {

        super(props);

        this.selectedQuestion = null;

        this.state = {
            showTable: true,
            updateDialogOpen: false,
            selectedSurveyQuestion: null,
            propmtOpen: false,
            promptTitle: "Remove Question From Survey?",
            promptContent: "Are you sure you want to remove this question from the survey?",
            toolbarConfig: {
                items: [
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                        ]
                    },
                    {
                        type: "devider"
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' },
                        ]
                    },
                    {
                        type: "devider"
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'add', label: 'ADD QUESTION', tooltip: 'ADD QUESTION', disabled: utils.isNull(this.props.location.state.selection) },
                        ]
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'edit', label: 'EDIT QUESTION', tooltip: 'EDIT QUESTION', disabled: utils.isNull(this.selectedQuestion) },
                        ]
                    },
                    {
                        type: "buttonGroup",
                        buttons: [
                            { id: 'delete', label: 'REMOVE QUESTION', tooltip: 'REMOVE QUESTION', disabled: utils.isNull(this.selectedQuestion) },
                        ]
                    }
                ]
            }
        };
    }
    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        }
        else if ("save" === buttonId) {
            this.save();
        }
        else if ("add" === buttonId) {
            this.selectedQuestion = null;
            this.setState({ selectedSurveyQuestion: null, updateDialogOpen: true, message: "" })
        }
        else if ("edit" === buttonId) {
            this.setState({ updateDialogOpen: true, message: "" })
        }
        else if ("delete" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    handleToolbarButtonMenuClick(menu) {
    }

    close() {
        this.props.history.push('/SurveySearch');
    }

    successCallback = () => {
        this.setState({ message: "Survey setup updated successfully", messageStyle: "SUCCESS" });
    }

    dialogUpdateActionHandler(isCancel, added) {
        if (added === true) {
            this.setState({
                showTable: false,
                updateDialogOpen: false,
                selection: this.props.location.state.selection
            }, () => {
                this.setState({ showTable: true })
            });
            this.selectedQuestion = null;
        }
        else if (isCancel === true) {
            this.setState({
                updateDialogOpen: false,
            })
            this.selectedQuestion = null;
        }
    }

    save() {
        let formValue = surveydetailRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.selection;
            let input = JSON.stringify(formValue);

            let url = "api/sims/survey/saveSurveySetup";

            utils.invokeUrl(url, (response) => {
                this.setState({ message: "Survey setup saved!", messageStyle: "SUCCESS", moderatorID: response.EntityId });
                toolbarRef.current.setButtonDisabled("add", false);
                this.props.location.state.selection = response.EntityId;
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: input
                }
            )
        }
    };

    handleSelectionChange = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.selectedQuestion = selection[0].Id;

            this.setState({ selectedSurveyQuestion: selection[0].Id });

            toolbarRef.current.setButtonDisabled("edit", false);
            toolbarRef.current.setButtonDisabled("delete", false);
        } else {
            this.selectedQuestion = null;
        }
    }

    removeQuestion(url,id) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("delete", true);
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let fullUrl = `${url}?id=${id}`;
        utils.invokeUrlDelete(fullUrl, this.successCallback, null);
    }

    closePrompt = () => {
        this.setState({ promptOpen: false })
    }

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
          
        }
    }

    render() {

        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Survey > Add Survey > Setup Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <FormDialog
                    open={this.state.updateDialogOpen}
                    dialogTitle={"Insert/Update Survey Question"}
                    viewRef={this}
                    entityId={this.props.location.state.selection}
                    secondaryEntityId={this.state.selectedSurveyQuestion}
                    formRef={addFormRef}
                    usePut={true}
                    saveUrl={"api/sims/survey/UpdateInsertSurveyQuestion"}
                    closeHandler={(isCancelled, linked) => this.dialogUpdateActionHandler(isCancelled, linked)}
                >
                    <QuestionForm ref={addFormRef} id={this.state.selectedSurveyQuestion} dataURL='api/sims/survey/GetFindSurveyQuestion' controllerName='survey' />
                </FormDialog>

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Bulk Email Setup" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} ref={toolbarRef} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "35vh", maxHeight: "35vh" }}>
                                    <AddSurveyForm ref={surveydetailRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                                </div>
                                <EnhancedTable autoFetchData={true} headCells={QuestionsTableHeadCells} searchParameters={[{ Name: `SurveyID`, Value: this.props.location.state.selection }]} paged={false}
                                    dataUrl="api/sims/Survey/GetSurveyQuestions" onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                                />
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => this.removeQuestion(`api/sims/survey/RemoveSurveyQuestion`, this.state.selectedSurveyQuestion)}
                    closePrompt={this.closePrompt}
                />
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(AddSurvey));
