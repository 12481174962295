import { withRouter } from 'react-router-dom';
import React, { Component } from "react";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import { ViewContainer } from "../../View.jsx";
import ViewUtils from '../../ViewUtils';
import LearnerProgrammeGrantSearchForm from './LearnerProgrammeGrantSearchForm';
import EnhancedToolbar from '../../widgets/Toolbar';
import EnhancedTable from '../../widgets/ResponsiveTable';
import AlertItem from "../../widgets/AlertItem";
import Prompt from '../../widgets/Prompt';
import withMenuPermission from "../../widgets/withMenuPermission";
import Dialog from '@material-ui/core/Dialog';
import { Typography } from "antd";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar';
import Slide from '@material-ui/core/Slide';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const utils = new ViewUtils();
const toolbarRef = React.createRef();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const headCells = [
    { id: 'ProgrammeCode', numeric: false, disablePadding: true, label: 'Programme Code' },
    { id: 'ProgrammeTitle', numeric: false, disablePadding: true, label: 'Programme Title' },
    { id: 'StartDate', numeric: false, disablePadding: true, label: 'Start Date' },
    { id: 'EndDate', numeric: false, disablePadding: true, label: 'End Date' },
    { id: 'ProgrammeStatus', numeric: false, disablePadding: true, label: 'Programme Status' },
];

const headCells2 = [
    { id: 'Tranche', numeric: false, disablePadding: true, label: 'Tranche', widthRatio: .148 },
    { id: 'GrantAmount', numeric: false, disablePadding: true, label: 'Grant Amount', widthRatio: .142 },
    { id: 'DueDate', numeric: false, disablePadding: true, label: 'Due Date', widthRatio: .142 },
    { id: 'ClaimNumber', numeric: false, disablePadding: true, label: 'Claim Number', widthRatio: .142 },
    { id: 'ClaimDate', numeric: false, disablePadding: true, label: 'Claim Date', widthRatio: .142 },
    { id: 'Status', numeric: false, disablePadding: true, label: 'Status', widthRatio: .142 },
    { id: 'BatchNumber', numeric: false, disablePadding: true, label: 'Batch Number', widthRatio: .142 },
];

const useStyles = theme => ({
    appBar: {
        position: 'relative',
    },
    title: {
        flex: 1,
        color: 'white',
    },
    table: {
        minWidth: 700,
    },
});

class LearnerGrants extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                Id: this.props.location.state.selection
            },
            editDialogOpen: false,
            selectedId: null,
            searchParameters: [{ Name: "LearnerID", Value: this.props.location.state.LearnerID }, { Name: "DiscretionaryGrantTypeID", Value: 0 }],
            searchParameters2: [{ Name: "Id", Value: 0 }, { Name: "DiscretionaryGrantTypeId", Value: 0 }],
            clearSelectedRow: false,
            promptOpen: false,
            OpenDialog: false,
            promptTitle: "Learner Programme Grants Association",
            promptContent: "Are you sure you want to associate this learner programme to grants?",
            TrancheObject: null,
            DiscretionaryGrantTypeID : null
        }

        this.toolbarConfig = {
            items: [

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
                ,
                {
                    type: "devider"
                },

                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'view', label: '', tooltip: 'View Grants', disabled: utils.isNull(this.state.selectedId), icon: 'InfoIcon', visible: this.props.menuPermissionItem.View },
                        { id: 'generate', label: '', tooltip: 'Associate Grant', disabled: utils.isNull(this.state.selectedId), icon: 'EditIcon', visible: this.props.menuPermissionItem.Add },
                    ]
                }
            ]
        }
    }


    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "edit") {
            this.setState({ editDialogOpen: true });
        }
        else if (buttonId === "view") {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            let temp_element2 = { ...newsearchParameters2[1] };
            temp_element.Value = this.state.selectedId;
            newsearchParameters2[0] = temp_element;
            temp_element2.Value = this.state.DiscretionaryGrantTypeID;
            newsearchParameters2[1] = temp_element2;

            this.setState({
                searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
                OpenDialog: true
            });
        }
        else if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/learner',
                state: {
                    selection: this.props.location.state.LearnerID
                }
            });
        }
        else if ("generate" === buttonId) {
            this.setState({ promptOpen: true })
        } else {
            // this.setState({ editDialogOpen: true });
        }
    }

    handleFormValueChange(values) {

        let DiscretionaryGrantTypeID = values.DiscretionaryGrantTypeID.Id;
        let newsearchParameters = [...this.state.searchParameters];
        let newsearchParameters2 = [...this.state.searchParameters2];
        let temp_element = { ...newsearchParameters[1] };
        let temp_element2 = { ...newsearchParameters2[1] };
        temp_element.Value = DiscretionaryGrantTypeID
        temp_element2.Value = DiscretionaryGrantTypeID
        newsearchParameters[1] = temp_element;
        newsearchParameters2[1] = temp_element2;

        this.setState({
            searchParameters: JSON.parse(JSON.stringify(newsearchParameters)),
            searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
            DiscretionaryGrantTypeID: DiscretionaryGrantTypeID,
            selectedId: null,
            Message: "",
            MessageStyle: ""
        });
        toolbarRef.current.setButtonDisabled("view", true);
        toolbarRef.current.setButtonDisabled("edit", true);
    }

    handleSelectionChange = (selection) => {

        if (selection !== null && selection.length !== 0) {
            let newsearchParameters2 = [...this.state.searchParameters2];
            let temp_element = { ...newsearchParameters2[0] };
            temp_element.Value = selection[0].Id
            newsearchParameters2[0] = temp_element;

            this.setState({
                searchParameters2: JSON.parse(JSON.stringify(newsearchParameters2)),
                selectedId: selection[0].Id, Message: "", clearSelectedRow: false
            }, () => {
                toolbarRef.current.setButtonDisabled("generate", selection === null || selection.length === 0);
                let url = "api/sims/learnergrants/CheckHasGrants?Id=" + this.state.selectedId + "&DiscretionaryGrantTypeId=" + this.state.DiscretionaryGrantTypeID;
                utils.invokeUrl(url, (response) => {
                    this.setState({
                        TrancheObject: response.Result
                    }, () => {
                        toolbarRef.current.setButtonDisabled("generate", this.state.TrancheObject === "1");
                    })
                },
                    null,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            'Authorization': "Bearer " + localStorage.getItem("token")
                        }
                    }
                )
            });
        }
        else {
            this.setState({
                searchParameters2: [{ Name: "Id", Value: 0 }, { Name: "DiscretionaryGrantTypeId", Value: 0 }],
                selectedId: null, Message: "", clearSelectedRow: false
            });
        }

        toolbarRef.current.setButtonDisabled("view", selection === null || selection.length === 0);

    }

    postAddHandler(cancel, save, response) {
        if (save === true) {
            if (response.MessageType === "SUCCESS") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "success",
                    clearSelectedRow: true
                });
            }
            else if (response.MessageType === "ERROR") {
                this.setState({
                    searchParameters: JSON.parse(JSON.stringify(this.state.searchParameters)),
                    editDialogOpen: false,
                    selectedId: null,
                    Message: response.Message,
                    MessageStyle: "warning"
                });
            }
        }
        else if (cancel === true) {
            this.setState({
                editDialogOpen: false,
                selectedId: null,
                clearSelectedRow: true
            })
        }
    }

    associateGrant() {
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null }, () => {
            toolbarRef.current.setButtonDisabled("edit", true);
        })

        let url = "api/sims/learnergrants/AssociateLearnerGrant?Id=" + this.state.selectedId + "&DiscretionaryGrantTypeId=" + this.state.DiscretionaryGrantTypeID;
        utils.invokeUrl(url, (response) => {
            this.successCallback(response);
        },
            (response) => {
                this.error(response);
            },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
        toolbarRef.current.setButtonDisabled("generate", true);
    }

    closePrompt = () => {
        toolbarRef.current.setButtonDisabled("edit", true);
        toolbarRef.current.setButtonDisabled("generate", true);
        this.setState({ promptOpen: false, clearSelectedRow: true, selectedId: null })
    }

    successCallback(response) {
        this.setState({ Message: response.Message, MessageStyle: response.MessageType });
    }
    error(response) {
        this.setState({ Message: response.Message, MessageStyle: response.MessageType });
    }

    onClearSelectedRow = () => {
        this.setState({ clearSelectedRow: false });
    }

    CloseForm = () => {
        this.setState({
            OpenDialog: false,
            selectedId: null,
            clearSelectedRow: true
        });
        toolbarRef.current.setButtonDisabled("generate", true);
    };

    render() {

        const { classes } = this.props;
        return (
            <ViewContainer mode={this.props.mode} title="">
                <p className="legend">
                    {"Learner > Programme Grants " + (this.props.location.state.LearnerName !== null ? "> " + this.props.location.state.LearnerName : "> Add")}
                </p>

                <AlertItem message={this.state.Message} alertStyle={this.state.MessageStyle} />

                <div className="searchResults">
                    < LearnerProgrammeGrantSearchForm
                        valueChangeHandler={(values) => this.handleFormValueChange(values)}
                    />

                    <EnhancedToolbar highlight={false}
                        title="Learner Grants"
                        buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                        ref={toolbarRef}
                        config={this.toolbarConfig}
                    />

                    <EnhancedTable
                        headCells={headCells}
                        onSelectionChange={(selection) => { this.handleSelectionChange(selection) }}
                        dataUrl="api/sims/learnergrants/GetSearch"
                        searchParameters={this.state.searchParameters}
                        onCellSelectionChange={(rowid, headCell) => { this.FileDownload(rowid, headCell) }}
                        clearSelectedRow={this.state.clearSelectedRow} onClearSelectedRow={this.onClearSelectedRow}
                        paged={true} />
                </div>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.associateGrant() }}
                    closePrompt={this.closePrompt}
                />

                <Dialog fullScreen open={this.state.OpenDialog} onClose={this.CloseForm} TransitionComponent={Transition}>
                    <AppBar className={classes.appBar}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={this.CloseForm} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                            <Typography variant="h1" className={classes.title}>
                                {"Grants Associated"}
                            </Typography>

                        </Toolbar >
                    </AppBar >
                    <br /><br />
                    <EnhancedTable
                        headCells={headCells2}
                        dataUrl={"api/sims/learnergrants/GetLearnerProgrammeGrant"}
                        makeRowBold={true}
                        searchParameters={this.state.searchParameters2} paged={false} autoFetchData={true} />
                </Dialog >

            </ViewContainer>
        );
    }
}

export default withRouter(withStyles(useStyles())(withMenuPermission(LearnerGrants)));