import React from 'react';
import Button from '@material-ui/core/Button';
import "../../../View.css";
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import DocumentUpload from '../../../widgets/DocumentUpload';
import ViewUtils from "../../../ViewUtils";

const utils = new ViewUtils();
export default function OnlineAssessorDocumentDialog(props) {

    const [open, setOpen] = React.useState(props.open);
    const [filename, setFilename] = React.useState(props.fileName);
    const [size, setSize] = React.useState(null);
    const [base64, setBase64] = React.useState(null);
    const [type, setType] = React.useState(null);
    const [entityId, setEntityId] = React.useState(props.EntityID);
    const [documentType, setDocumentType] = React.useState('');

    React.useEffect(() => {
        if (!utils.isNull(props.EntityID)) {
            setEntityId(props.EntityID);
        }
        if (!utils.isNull(props.DocumentType)) {
            setDocumentType(props.DocumentType);
        }
    }, [props.EntityID]);

    const handleClose = (save, cancel) => {
        setOpen(false);
        props.closeHandler(save, cancel)
    };


    const cancel = () => {
        handleClose(false, true);
    };

    const save = () => {
       
        let data = {};
        data.OriginalFileName = filename;
        data.EntityID = entityId;
        data.DocumentType = props.DocumentType.Id;
        data.Document = {};
        data.Document.name = filename;
        data.Document.size = size;
        data.Document.type = type;
        data.Document.base64 = base64;

        utils.invokeUrlExternal(props.postUrl, (response) => {
            handleClose(true, false);
        }, () => { handleClose(false, false); },
            {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        );
    }

    const onUploadHandler = (data) => {
        if (data) {
            setFilename(data.name);
            props._setFilename(data.name);
            setSize(data.size);
            setBase64(data.base64);
            setType(data.type);
        }
    }

    React.useEffect(() => {
        setOpen(props.open);
    }, [props.open]);

    return (
        <div className="w-100 lookup">
                <div>
                    <div style={{ height: "80%" }}>
                    <DocumentUpload showViewer={false} onUploadHandler={onUploadHandler} filename={filename} buttonLabel={props.buttonLabel}
                        />
                    {props.DocumentType !== undefined && (
                            <Button onClick={save} color="primary">
                            Add Document
                        </Button>
                        )}
                    </div>
                </div>
        </div>
    );
}
