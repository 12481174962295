import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../widgets/Toolbar';
import "../../Grid.css";
import "../../Form.css";
import "../../App.css";
import { BulkEmailForm } from './BulkEmailForm';
import { ViewContainer } from "../../View";
import ViewUtils from "../../ViewUtils";
import AlertItem from "../../widgets/AlertItem";
import withMenuPermission from "../../widgets/withMenuPermission";
import EnhancedTable from '../../widgets/ResponsiveTable';


const utils = new ViewUtils();


const bulkemaildetailRef = React.createRef();


const documentTableHeadCells = [
    { id: 'DocumentName', numeric: false, disablePadding: true, label: 'Document Name', contentType: 'document' },
    { id: 'UploadDate', numeric: false, disablePadding: true, label: 'Upload Date' }
]

class BulkEmailDetail extends Component {

    state = {
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon' },
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'email', label: 'SEND EMAIL', tooltip: 'SEND EMAIL' },
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AssessorDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.close();
        }
        else if ("save" === buttonId) {
            this.save();
        }
        else if ("email" === buttonId) {
            this.email();
        }
    }

    handleToolbarButtonMenuClick(menu) {
    }

    close() {
        this.props.history.push('/BulkEmailSearch');
    }

    successCallback = () => {
        this.setState({ message: "Bulk email setup updated successfully", messageStyle: "SUCCESS" });
    }

    save() {
        let formValue = bulkemaildetailRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.selection;
            let input = JSON.stringify(formValue);

            let url = "api/sims/BulkEmails/saveBulkEmailSetup";

            utils.invokeUrl(url, (response) => {
                this.setState({ message: "Bulk email setup saved!", messageStyle: "SUCCESS", moderatorID: response.EntityId });
            },
                null,
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: input
                }
            )
        }
    };

    handleCellSelect = (rowid, headCell) => {
        let url = "api/sims/BulkEmails/DownloadBulkEmailDocument?BulkEmailID=" + rowid;
        utils.invokeUrl(url, (response) => {
            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();

        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    email() {
        if (!utils.isNull(this.props.location.state.selection)) {
            let url = "api/sims/BulkEmails/SendBulkEmail?BulkEmailID=" + this.props.location.state.selection;
            utils.invokeUrl(url, (response) => {
                this.setState({ message: response.Message, messageStyle: response.MessageType });
            },
                (response) => {
                    this.error(response);
                },
                {
                    method: 'POST',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    }
                }
            )
        }
    };

    render() {
       
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"CRM > Bulk Email > Setup Details"}
                </p>
                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />

                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Bulk Email Setup" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <BulkEmailForm ref={bulkemaildetailRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)}  />
                                </div>
                                <EnhancedTable autoFetchData={false} headCells={documentTableHeadCells} onCellSelectionChange={(rowid, columnid) => { this.handleCellSelect(rowid, columnid) }} searchParameters={[{ Name: `BulkEmailID`, Value: this.props.location.state.selection }]} paged={false}
                                    dataUrl="api/sims/BulkEmails/GetUploadedDocument"
                                />
                            </td> 
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(BulkEmailDetail));
