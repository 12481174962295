import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./DG.css";
import SearchView from "../../widgets/SearchView";
import DGQueryReasonSearchForm from './DGQueryReasonSearchForm';
import CardLayout from '../../widgets/Card';
import ViewUtils from '../../ViewUtils';
import withMenuPermission from "../../widgets/withMenuPermission";

const utils = new ViewUtils();

const filterParameters = [

]
const headCells = [
    { id: 'DGFinancialYear', numeric: false, disablePadding: true, label: 'DG Financial Year' },
    { id: 'Name', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'Description', numeric: false, disablePadding: true, label: 'Description' }
];

const formRef = React.createRef();

class DGQueryReasonSearch extends Component {
    constructor(props) {
        super(props)

        this.state = {
            model: {
                DGFinancialYearID: { Id: 27, Description: "01 April 2025 - 31 March 2026" },
                Name: "",
                Description: ""

            },
            keyUpSwitch: false
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        {
                            id: 'add',
                            label: '',
                            tooltip: 'Add',
                            icon: 'AddIcon',
                            visible: this.props.menuPermissionItem.Add
                        },
                        {
                            id: 'edit',
                            label: '',
                            tooltip: 'Edit',
                            icon: 'EditIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.Edit
                        },
                        {
                            id: 'delete',
                            label: '',
                            tooltip: 'Delete selected query reason from records',
                            icon: 'DeleteIcon',
                            disabled: true,
                            visible: this.props.menuPermissionItem.Delete
                        }
                    ]
                }
            ]
        };

        this.searchParameters = [
            { Name: "DGFinancialYearID", Value: { Id: 27, Description: "01 April 2025 - 31 March 2026" } },
            { Name: "Name", Value: "" },
            { Name: "Description", Value: "" }
        ]
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "add") {
            this.props.history.push({
                pathname: '/DGQueryReason'
            })
        }
    } 

    handleFormValueChange(values) {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            this.setState({ model: values });
        }
    }

    handleSelection = (selection) => {

    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {
        return (
            <CardLayout title='DG Maintenance'>
                <SearchView headCells={headCells}
                    dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/dgqueryreasons/getsearch"}
                    entityViewPath='/DGQueryReason'
                    title='Query Reasons'
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig}
                    searchParameters={this.searchParameters}
                    filterParameters={filterParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="DGFinancialYear"
                    mode={this.props.mode}
                    history={this.props.history}
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch}
                    deleteUrl={"api/sims/dgqueryreasons/removedgqueryreasons"}
                    useDeletePost={true}
                    promptTitle={"Remove Query Reason"}
                    promptContent={"Are you sure you want to remove this query reason ?"}
                    setSelection={(selection) => { this.handleSelection(selection) }}
                ><DGQueryReasonSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }} ref={formRef} id={typeof this.props.location.state !== 'undefined' ? this.props.location.state.selection : null}
                        valueChangeHandler={(values) => this.handleFormValueChange(values)} data={this.state.model} />
                </SearchView>
            </CardLayout>
        );
    }
}

export default withRouter(withMenuPermission(DGQueryReasonSearch));
