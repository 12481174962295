import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../widgets/Toolbar';
import "./../Grid.css";
import "./../Form.css";
import "./../App.css";
import ViewUtils from "./../ViewUtils";
import { ViewContainer } from "../View";
import AlertItem from "../widgets/AlertItem";
import withMenuPermission from "../widgets/withMenuPermission";
import EnhancedTable from '../widgets/ResponsiveTable';

const headCells = [
    { id: 'ProjectDescription', numeric: false, disablePadding: true, label: 'Project Description' },
    { id: 'JobTaskNo', numeric: false, disablePadding: true, label: 'Job Task No' },
    { id: 'TaskDescription', numeric: false, disablePadding: true, label: 'Task Description' },
    { id: 'ScheduleTotalCost', numeric: true, disablePadding: true, label: 'Schedule Total Cost' },
    { id: 'ApprovedBudgetTotalCost', numeric: false, disablePadding: true, label: 'Approved Budget Total Cost' },
    { id: 'ActualTotalCost', numeric: false, disablePadding: true, label: 'Actual Total Cost' }
];


const utils = new ViewUtils();
const ref = React.createRef();

class ProjectBudget extends Component {
    state = {
        message: "",
        model: {},
        message: "",
        searchParameters: [{ Name: "ProjectID", Value: this.props.location.state.projectID }],
        selectedBudget: null,
        scheduleCostTotal: 0,
        budgetTotal: 0,
        actualCostTotal: 0,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'close', label: 'CLOSE', tooltip: 'Close', icon: 'CloseIcon' }
                    ]
                }
            ]
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("close" === buttonId) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: { projectID: this.props.location.state.projectID }
            });
        }
    }

    componentDidMount() {
        this.GetTotalAmounts();
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            this.setState({ selectedBudget: selection[0] })
        } else {
            this.setState({ selectedBudget: null })
        }
        console.log(this.state)
    }

    GetTotalAmounts() {
        let url = "api/sims/projectbudget/ProjectBudgetTotals?projectID=" + this.props.location.state.projectID;
        utils.invokeUrl(url, (response) => {
            if (!utils.isNull(response.Data)) {
                this.setState({ scheduleCostTotal: response.Data[0].ScheduleTotalCost, budgetTotal: response.Data[0].ApprovedBudgetTotalCost, actualCostTotal: response.Data[0].ActualTotalCost })
            }

        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    render() {
        return (
            <ViewContainer topPadding="0" title={""}>

                <p className="breadcrumbs">
                    Project Budget
                </p>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <div> {'Total Schedule Total Cost: R' + this.state.scheduleCostTotal} </div>
                <div> {'Total Budget: R' + this.state.budgetTotal} </div>
                <div> {'Total Actual Cost: R' + this.state.actualCostTotal} </div>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Grant" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)} enabled={true} config={this.state.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <EnhancedTable
                                headCells={headCells}
                                dataUrl={`api/sims/projectbudget/GetProjectBudgetData`}
                                height="70vh"
                                onSelectionChange={(selection) => { this.handleSelection(selection) }}
                                searchParameters={this.state.searchParameters} paged={true} />
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProjectBudget));

