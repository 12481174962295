import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import BulkEmailSearchForm from './BulkEmailSearchForm';
import SearchView from "../../widgets/SearchView";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();


const headCells = [
    { id: 'Subject', numeric: false, disablePadding: true, label: 'Subject' },
    { id: 'Body', numeric: false, disablePadding: true, label: 'Body' },
    { id: 'OriginalFileName', numeric: false, disablePadding: true, label: 'Original File Name' }
];

const filterParameters = [
    { dataField: "Subject", text: "Subject" }
]



class BulkEmailSearch extends Component {

    constructor(props) {
        super(props)

    }

    state = {
        model: {},
        searchParameters: [{ Name: "Subject", Value: "" }],
        selection: [],
        keyUpSwitch: false,
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Create Email', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit Email', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: "delete", label: "", tooltip: "Remove Email", icon: "DeleteIcon", disabled: true, visible: this.props.menuPermissionItem.Delete }

                    ]
                }
            ]
        }
    };

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    render() {

        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/BulkEmails/GetSearch"}
                    entityViewPath='/BulkEmailDetail' addViewPath='/BulkEmailDetail'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig} searchParameters={this.state.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    mode={this.props.mode}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    multiSelect={this.props.multiSelect}
                    title='Bulk Email Setup'
                >
                    <BulkEmailSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(BulkEmailSearch));

