import React, { Component } from "react";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import "./../../View.css";
import "./../../Grid.css";
import "./../../Form.css";
import "./../../App.css";
import "./Learner.css";
import LearnerSearchForm from './LearnerSearchForm';
import SearchView from "../../widgets/SearchView";
import { ViewContainer } from "./../../View.jsx";
import withMenuPermission from "../../widgets/withMenuPermission";


const headCells = [
    { id: 'FirstName', numeric: false, disablePadding: false, label: 'First Name' },
    { id: 'MiddleName', numeric: false, disablePadding: false, label: 'Middle Name' },
    { id: 'Surname', numeric: false, disablePadding: false, label: 'Surname' },
    { id: 'IDNo', numeric: false, disablePadding: false, label: 'ID Number' }
];



class LearnerSearch extends Component {

    constructor(props) {
        super(props)
        
    }

    state = {
        model: {},
        searchParameters: [{ Name: "FirstName", Value: "" },
                            { Name: "MiddleName", Value: "" },
                            { Name: "Surname", Value: "" },
                            { Name: "IDNo", Value: "" }],
        selection: [],
        keyUpSwitch: false, 
        toolbarConfig: {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'add', label: '', tooltip: 'Capture New Learner', icon: 'AddIcon', visible: this.props.menuPermissionItem.Add},
                        { id: 'edit', label: '', tooltip: 'Update Current Learner', icon: 'EditIcon',  visible: this.props.menuPermissionItem.Edit}
                       
                    ]
                }
            ]
        }
    };

    onkeyup(e) {
        //console.log(e.charCode)
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }
    
    render() {
 
        return (
            <ViewContainer title="">
                <SearchView headCells={headCells} dataUrl="api/sims/Learner/GetSearch" entityViewPath='/learner' addViewPath='/addLearnerFlow'
                    model={this.state.model} toolbarConfig={this.state.toolbarConfig} searchParameters={this.state.searchParameters}
                    onLookupValueChange={this.props.onLookupValueChange}
                    descriptionProp="FirstName"
                    SecondDescriptionProp = "Surname" 
                    mode={this.props.mode}
                    autoFetchData={false}
                    history={this.props.history}
                    keyUpSwitch={this.state.keyUpSwitch}
                    multiSelect={this.props.multiSelect}
                    title='Learner' 
                >
                    <LearnerSearchForm model={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }} />
                </SearchView>
            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(LearnerSearch));

