import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import "../View.css";
import "../Grid.css";
import "../Form.css";
import "../App.css";
import SearchView from "../widgets/SearchView";
import { ViewContainer } from "../View.jsx";
import ViewUtils from '../ViewUtils';
import withMenuPermission from "../widgets/withMenuPermission";
import ProjectsSearchForm from './ProjectsSearchForm';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import Prompt from '../widgets/Prompt';
import AlertItem from "../widgets/AlertItem";
import { ProjectPopupSearchForm } from './ProjectPopupSearchForm';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const toolbarRef = React.createRef();
const utils = new ViewUtils();
const projectRef = React.createRef();

const headCells = [
    { id: 'Id', numeric: false, disablePadding: false, label: 'Record ID' },
    { id: 'TargetNumber', numeric: true, disablePadding: false, label: 'Target Number' },
    { id: 'ProgrammeStructure', numeric: false, disablePadding: false, label: 'Programme Structure' },
    { id: 'SubProgrammeStructureCode', numeric: true, disablePadding: false, label: 'Sub Programme Structure Code' },
    { id: 'SubProgrammeStructure', numeric: false, disablePadding: false, label: 'Sub Programme Structure' },
    { id: 'Purpose', numeric: false, disablePadding: false, label: 'Purpose' },
    { id: 'Outcome', numeric: false, disablePadding: false, label: 'Outcome' },
    { id: 'Output', numeric: false, disablePadding: false, label: 'Output' },
    { id: 'OutputCode', numeric: true, disablePadding: false, label: 'Output Code' },
    { id: 'APPIndicator', numeric: false, disablePadding: false, label: 'APP Indicator' },
    { id: 'Q1Target', numeric: true, disablePadding: false, label: 'Q1 Target' },
    { id: 'Q2Target', numeric: true, disablePadding: false, label: 'Q2 Target' },
    { id: 'Q3Target', numeric: true, disablePadding: false, label: 'Q3 Target' },
    { id: 'Q4Target', numeric: true, disablePadding: false, label: 'Q4 Target' },
    { id: 'MTEFPeriod', numeric: false, disablePadding: false, label: 'MTEF Period' },
    { id: 'ImportIdentity', numeric: false, disablePadding: false, label: 'Import Identity' },
    { id: 'FinancialYear', numeric: false, disablePadding: false, label: 'Financial Year' },
    { id: 'ProjectType', numeric: false, disablePadding: false, label: 'Project Type' },
];

class ProjectsSearch extends Component {

    constructor(props) {
        super(props)

        this.state = {
            model: {
                FinancialYearID: "",
                ProjectTypeID: ""
            },
            modalOpen: false,
            openNextModal: false,
            projectmodel: {
                ProjectNumber: null
            },
            optionSelected: '',
            keyUpSwitch: false,
            selectedProject: null,
            projectData: null,
            showTable: true,
            selectedFinancialYear: null,
            message: "",
            selectedPType: null,
            propmtOpen: false,
            promptTitle: "Remove project",
            promptContent: "Are you sure you want to remove this Project?",
            selectedFinancialYearDescription: "",
            RemoveMessage: "",
            RemoveMessageStyle: "",
            searchParameters: [
                { Name: "FinancialYearID", Value: 0 },
                { Name: "ProjectTypeID", Value: 0 }
            ]
        };

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'addproject', label: '', tooltip: 'Create New Project', icon: 'AddIcon', disabled: true, visible: this.props.menuPermissionItem.Add },
                        { id: 'edit', label: '', tooltip: 'Edit', icon: 'EditIcon', disabled: true, visible: this.props.menuPermissionItem.Edit },
                        { id: 'remove', label: '', tooltip: 'Remove Project', icon: 'DeleteIcon', disabled: utils.isNull(this.state.selectedProject) }
                    ]
                }
            ]
        };
    }

    onkeyup(e) {
        if (e.charCode == 13) {
            this.setState({ keyUpSwitch: !this.state.keyUpSwitch })
        }
    }

    handleSelection = (selection) => {
        if (selection !== null && selection.length !== 0) {
            if (selection[0].ProjectType == "Annual Performance Plan (APP)") {
                this.setState({ selectedProject: selection[0], selectedPType: "APP" })
            }
            else {
                this.setState({ selectedProject: selection[0], selectedPType: "OTHER" })
            }
        } else {
            this.setState({ selectedProject: null })
        }
    }

    handleToolbarButtonClick = (buttonId) => {
        if (buttonId === "addproject") {
            if (utils.isNull(this.state.selectedFinancialYearID)) {
                this.setState({ alertOpen: true, message: "Please select a valid financial year" });
            }
            else {
                this.setState({ modalOpen: true, alertOpen: false, message: "" });
            }
        } 
        else if (buttonId === "edit") {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: {
                    selection: this.state.selectedProject,
                    IsAdd: false,
                    PType: this.state.selectedPType
                }
            })
        } 
        else if ("remove" === buttonId) {
            this.setState({ promptOpen: true })
        }
    }

    handleModelChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {
            if (!utils.isNull(values.FinancialYearID.Description)) {
                this.setState({ model: values, selectedFinancialYearID: values.FinancialYearID.Id, selectedFinancialYearDescription: values.FinancialYearID.Description });
                this.validateFinancialYear(values.FinancialYearID.Description)
            }
            else {
                this.setState({ model: values });
            }
        }
    }

    validateFinancialYear(FinancialYear) {
        let url = "api/sims/projects/ValidateProject?FinancialYear=" + FinancialYear;
        utils.invokeUrlExternal(url, (response) => {
            this.setState({
                projectData: response
            })
        },
            null,
            {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                }
            }
        )
    }

    handleModalClose = () => {
        this.setState({ modalOpen: false });
    };

    handleOptionChange = (event) => {
        this.setState({ optionSelected: event.target.value });
    };

    handleButtonClick = () => {
        if (this.state.optionSelected == "app") {
            this.props.history.push({
                pathname: '/NewProject',
                state: {
                    projectTypeID: 1,
                    FinancialYearID: this.state.selectedFinancialYearID,
                    FinancialYearDescription: this.state.selectedFinancialYearDescription
                }
            })
        }
        else if (this.state.optionSelected == "other") {
            this.setState({ openNextModal: true });
        }
        else {
            this.handleAlertOpen();
        }
    };

    handleAlertOpen = () => {
        this.setState({ alertOpen: true, message: "Please select a valid project type" });
    };

    handleAlertClose = () => {
        this.setState({ alertOpen: false, message: "" });
    };

    successfulNotificationCallback = (response) => {
        this.setState({ selectedProject: null, RemoveMessage: response.Message, RemoveMessageStyle: response.MessageType.toLowerCase(), showTable: false }, () => {
            this.setState({ showTable: true })
        });
    }

    unsuccessfulNotificationRemoveCallback = (response) => {
        if (!utils.isNull(response.Message) && response.Message !== "") {
            this.setState({ RemoveMessage: response.Message, RemoveMessageStyle: response.MessageType.toLowerCase() })
        }
    }

    RemoveProject(url) {
        this.setState({ promptOpen: false }, () => {
            toolbarRef.current.setButtonDisabled("remove", true);
        })

        let fullUrl = `${url}?id=${this.state.selectedProject.Id}`;
        utils.invokeUrlDelete(fullUrl, this.successfulNotificationCallback, this.unsuccessfulNotificationRemoveCallback);
    }

    closePrompt = () => {
        this.setState({ propmtOpen: false })
    }

    handleFormValueChange = (values) => {
        if (JSON.stringify(this.state.model) !== JSON.stringify(values)) {

        }
    }

    handleProjectSelect = (values) => {
        if (Array.isArray(values) && values.length !== 0) {
            this.setState({ selectedProjectID: values[0].Id, selectedProjectDescription: values[0].Description, startingDate: values[0].StartingDate, endingDate: values[0].EndDate });
        }
    }

    handleCloseNextModal = () => {
        this.setState({ openNextModal: false, alertOpen: false, message: "", messageType: "" });
    }

    handleNext = () => {
        if (!utils.isNull(this.state.selectedProjectID)) {
            this.props.history.push({
                pathname: '/ProjectDetail',
                state: {
                    selection: null,
                    projectID: 0,
                    projectIDDescription: this.state.selectedProjectID,
                    projectDescription: this.state.selectedProjectDescription,
                    startDate: this.state.startingDate,
                    endDate: this.state.endingDate,
                    IsAdd: true,
                    PType: "OTHER",
                    FinancialYearDesc: this.state.selectedFinancialYearDescription

                }
            })
        }
        else {
            this.setState({ alertOpen: true, message: "Please select a valid project to link to this setup", messageType: "error" });
        }
    }

    render() {
        return (
            <ViewContainer title="">

                <AlertItem message={this.state.RemoveMessage} alertStyle={this.state.RemoveMessageStyle} />
                {this.state.showTable === true && (
                <SearchView headCells={headCells} dataUrl={!utils.isNull(this.props.dataUrl) ? this.props.dataUrl : "api/sims/projects/GetSearch"}
                    model={this.state.model}
                    toolbarConfig={this.toolbarConfig} searchParameters={this.state.searchParameters}
                    title='Create New Project'
                    mode={this.props.mode}
                    handleObject={true}
                    toolbarRef={toolbarRef}
                    history={this.props.history}
                    onLookupValueChange={this.props.onLookupValueChange} descriptionProp="ProjectName"
                    buttonClickHandler={this.handleToolbarButtonClick}
                    keyUpSwitch={this.state.keyUpSwitch} 
                    promptTitle={"Remove Project"} 
                    setSelection={(selection) => { this.handleSelection(selection) }}
                >
                    <ProjectsSearchForm data={this.state.model} onKeyUp={(e) => { this.onkeyup(e) }}
                        valueChangeHandler={(values) => {
                            this.handleModelChange(values)
                        }} />
                </SearchView>
                )}

                <Dialog open={this.state.modalOpen} onClose={this.handleModalClose}>
                    <DialogTitle>Create New Project</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Please Select a Project Type:
                        </DialogContentText>
                        <RadioGroup value={this.state.optionSelected} onChange={this.handleOptionChange}>
                            <FormControlLabel value="app" control={<Radio />} label="Annual Performance Plan (APP)" />
                            <FormControlLabel value="other" control={<Radio />} label="Other SETA Project" />
                        </RadioGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleModalClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleButtonClick} color="primary">
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={this.state.openNextModal} onClose={this.handleCloseNextModal}>
                    <DialogTitle>Please select a project</DialogTitle>
                    <DialogContent>
                        <ProjectPopupSearchForm id={1} ref={projectRef} handleProjectSelect={(values) => this.handleProjectSelect(values)} fyFilter={this.state.selectedFinancialYearDescription} viewRef={this} data={this.state.projectmodel}
                            valueChangeHandler={(values) => this.handleFormValueChange(values)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleCloseNextModal} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={this.handleNext} color="primary">
                            Next
                        </Button>
                    </DialogActions>
                </Dialog>

                <Snackbar open={this.state.alertOpen} autoHideDuration={6000} onClose={this.handleAlertClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                    <Alert onClose={this.handleAlertClose} severity="error">
                        {this.state.message}
                    </Alert>
                </Snackbar>

                <Prompt open={this.state.promptOpen}
                    promptTitle={this.state.promptTitle}
                    promptContent={this.state.promptContent}
                    handleYes={() => { this.RemoveProject("api/sims/projects/RemoveProject") }}
                    closePrompt={this.closePrompt}
                />

            </ViewContainer>
        );
    }
}


export default withRouter(withMenuPermission(ProjectsSearch));

