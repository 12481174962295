import React from 'react';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from "../../ViewUtils";
import { SelectItem } from '../../widgets/SelectItem';
import { LookupField } from '../../widgets/LookupField';
import { CustomDatePicker } from '../../widgets/CustomDatePicker';
import { CustomTextField } from '../../widgets/CustomTextField';
import { CustomCheckbox } from '../../widgets/CustomCheckbox';
import ProviderSearch from '../Controls/ProviderSearch';
import AssessorSearch from "../../etqa/assessor/AssessorSearch";
import ModeratorSearch from "../../etqa/moderator/ModeratorSearch";
import EmployerSearch from "../../etqa/employer/EmployerSearch";

const utils = new ViewUtils();

export const ETQAReportForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const paramArray = !utils.isNull(props.ETQAReportDetails) && !utils.isNull(props.ETQAReportDetails.ReportParameters) ? props.ETQAReportDetails.ReportParameters.split(",") : [];

    const getName = (name) => {
        let text;
        if (name.slice(-2).includes("ID")) {
            text = name.slice(0, -2);
        } else {
            text = name;
        }

        let newText = [];
        newText.push(text[0]);

        for (var i = 1; i < text.length; i++) {
            if (checkUppercase(text[i]) && !checkUppercase(text[i - 1]) && !checkPuctuation(text[i - 1])) {
                newText.push(' ');
            }
            else if (checkDigit(text[i]) && checkLetter(text[i - 1])) {
                newText.push(' ');
            }
            else if (i < text.length - 1) {
                if (checkUppercase(text[i]) && (!checkUppercase(text[i + 1]) && text[i + 1] !== " " && text[i + 1] !== ")" && text[i + 1] !== "'") && !checkPuctuation(text[i - 1])) {
                    newText.push(' ');
                }
            }

            newText.push(text[i]);
        }
        return newText.join("").replace("  ", " ");
    }

    const checkUppercase = (value) => {
        if (value === value.toUpperCase()) {
            return true;
        } else {
            return false;
        }
    }

    const checkPuctuation = (value) => {
        if (value === ".") {
            return true;
        }
        else {
            return false;
        }
    }

    const checkDigit = (value) => {
        if (Number.isInteger(value)) {
            return true;
        }
        else {
            return false;
        }
    }

    const checkLetter = (str) => {
        return str.length === 1 && str.match(/[a-z]/i);
    }

    return (
        <EntityForm ref={ref} viewId="ETQAReports" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ETQAReportForm">
                    {/* <div className="row search-form-container">*/}
                    <table style={{ width: '100%' }}>
                        {
                            paramArray.map(parameter => {

                                let field;

                                if (parameter === "ProviderID") {
                                    field = (
                                        <tr>
                                            <td>
                                                <LookupField
                                                    id="ProviderID"
                                                    key={tabValue}
                                                    label="Provider"
                                                    className="w-50"
                                                    form={form}
                                                >
                                                    <ProviderSearch showTitle={true} mode='lookup' dataUrl="api/sims/ETQAReportsDetails/GetETQAReportsProviderSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("ProviderID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                        </tr>
                                    );
                                } else if (parameter === "AssessorID") {
                                    field = (
                                        <tr>
                                            <td>
                                                <LookupField
                                                    id="AssessorID"
                                                    key={tabValue}
                                                    label="Assessor"
                                                    className="w-50"
                                                    form={form}
                                                >
                                                    <AssessorSearch showTitle={true} mode='lookup' dataUrl="api/sims/ETQAReportsDetails/GetETQAReportsAssessorSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("AssessorID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                        </tr>);
                                } else if (parameter === "ModeratorID") {
                                    field = (
                                        <tr>
                                            <td>
                                                <LookupField
                                                    id="ModeratorID"
                                                    key={tabValue}
                                                    label="Moderator"
                                                    className="w-50"
                                                    form={form}
                                                >
                                                    <ModeratorSearch showTitle={true} mode='lookup' dataUrl="api/sims/ETQAReportsDetails/GetETQAReportsModeratorSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("ModeratorID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                        </tr>
                                    );
                                } else if (parameter === "EmployerID") {
                                    field = (
                                        <tr>
                                            <td>
                                                <LookupField
                                                    id="EmployerID"
                                                    key={tabValue}
                                                    label="Employer"
                                                    className="w-50"
                                                    form={form}
                                                >
                                                    <EmployerSearch showTitle={true} mode='lookup' dataUrl="api/sims/ETQAReportsDetails/GetETQAReportsEmployerSearch"
                                                        onLookupValueChange={(value) => {
                                                            form.setLookupSelection("EmployerID", value)
                                                        }} autoFetchData={false} selection={props.selection} />
                                                </LookupField>
                                            </td>
                                        </tr>);
                                } else if (parameter.slice(-2).includes("ID")) {
                                    field = (
                                        <tr>
                                            <td>
                                                <SelectItem
                                                    dataUrl={"api/sims/ETQAReportsDetails/GetLookupList?listId=" + parameter}
                                                    id={parameter}
                                                    key={tabValue}
                                                    label={getName(parameter)}
                                                    className="w-50"
                                                    allowEmptyValue={true}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                    );
                                } else if (parameter.includes("Date")) {
                                    field = (
                                        <tr>
                                            <td>
                                                <div style={{ width: '50%' }}>
                                                    <CustomDatePicker
                                                        id={parameter}
                                                        key={tabValue}
                                                        label={getName(parameter)}
                                                        className='w-100'
                                                        required
                                                        form={form}
                                                    />
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                } else if (parameter.slice(0, 2).includes("Is")) {
                                    field = (
                                        <tr>
                                            <td>
                                                <CustomCheckbox
                                                    id={parameter}
                                                    key={tabValue}
                                                    form={form}
                                                    InputProps={{
                                                        'aria-label': 'primary checkbox'

                                                    }}
                                                    label={getName(parameter)}
                                                />
                                            </td>
                                        </tr>
                                    );
                                } else if (parameter.length > 0) {
                                    field = (
                                        <tr>
                                            <td>
                                                <CustomTextField
                                                    id={parameter}
                                                    key={tabValue}
                                                    label={getName(parameter)}
                                                    className="w-50"
                                                    InputProps={{
                                                        readOnly: false,
                                                    }}
                                                    form={form}
                                                />
                                            </td>
                                        </tr>
                                    );
                                }

                                return field;
                            })
                        }
                    </table>
                    {/*</div>*/}
                </form >
            }
        </EntityForm >
    );
})