import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import EnhancedToolbar from '../../../widgets/Toolbar';
import "../../../Grid.css";
import "../../../Form.css";
import "../../../App.css";
import "./Assessor.css";
import { OnlineAssessorDetailsVerificationForm } from './OnlineAssessorDetailsVerificationForm';
import { ViewContainer } from "../../../View";
import ViewUtils from "../../../ViewUtils";
import AlertItem from "../../../widgets/AlertItem";
import withMenuPermission from "../../../widgets/withMenuPermission";


const utils = new ViewUtils();


const assessorVerifyRef = React.createRef();
const toolbarRef = React.createRef();

const lowertoolbarConfig = {
    items: [
        {
            type: "buttonGroup",
            buttons: [
                { id: 'back', label: 'BACK', tooltip: 'Back to uploaded documents', theme: true },
                { id: 'next', label: 'FINISH', tooltip: 'Approve Submission', theme: true }
            ]
        }
    ]
};

class OnlineAssessorDetailsVerification extends Component {

    constructor(props) {
        super(props)
        this.state = {
            AssessorDetails: {
                Person: {
                    FirstName: "",
                    Surname: "",
                    IDNo: ""
                }
            },
            assessorID: null,
            notYetApproved: true
        }

        this.toolbarConfig = {
            items: [
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'save', label: 'SAVE', tooltip: 'Save', icon: 'SaveIcon', visible: true}
                    ]
                },
                {
                    type: "devider"
                },
                {
                    type: "buttonGroup",
                    buttons: [
                        { id: 'email', label: 'Email Assessor Letter', tooltip: 'Email Assessor Letter', disabled: true },
                        { id: 'download', label: 'Download Assessor Letter', tooltip: 'Download Assessor Letter', disabled: true }
                    ]
                }
            ]
        }
    };

    handleFormValueChange(values) {
        if (!utils.isNull(values)) {
            this.setState({ AssessorDetails: values });
        }
    }

    handleToolbarButtonClick(buttonId) {
        if ("save" === buttonId) {
            this.save();
        }
        else if ("email" === buttonId) {
            this.emailDocuments();
        }
        else if ("download" === buttonId) {
            this.generateDocuments();
        }
    }

    emailLetter() {

    }

    handleLowerToolbarButtonClick(buttonId) {
        if ("next" === buttonId) {
            this.props.history.push({
                pathname: '/OnlineAssessorApprovalSearch',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        } else if ("back" === buttonId) {
            this.props.history.push({
                pathname: '/OnlineAssessorApprovalDocuments',
                state: {
                    selection: this.props.location.state.selection
                }
            });
        }
    }

    handleToolbarButtonMenuClick(menu) {
    }

    successCallback = () => {
        
    }

    save() {
        let formValue = assessorVerifyRef.current.getValue();
        if (formValue !== null && typeof formValue !== "undefined") {
            formValue.id = this.props.location.state.selection;
            let input = JSON.stringify(formValue);

            let url = "api/sims/OnlineAssessorApproval/UpdateAssessorApplication";

            utils.invokeUrl(url, (response) => {
                this.setState({ message: "Assessor Application Updated!", messageStyle: "SUCCESS", assessorID: response.EntityId });
                toolbarRef.current.setButtonDisabled("email", false);
                toolbarRef.current.setButtonDisabled("download", false);
            },
                null,
                {
                    method: 'PUT',
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': "Bearer " + localStorage.getItem("token")
                    },
                    body: input
                }
            )
        }
    };

    generateDocuments = () => {
        var assessorFormat = [];
        assessorFormat.push(this.state.assessorID);

        let data = {
            AssessorIDs: assessorFormat,
            ...this.state.model
        }
        let url = "api/sims/OnlineAssessorApproval/GenerateAssessorLetters";
        utils.invokeUrl(url, (response) => {
            let documentInfo = JSON.parse(response.Message);
            this.FileDownload(documentInfo.OriginalFileName);
            toolbarRef.current.setButtonDisabled("download", true);
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    base64ToArrayBuffer(base64) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
            bytes[i] = binary_string.charCodeAt(i);
        }
        return bytes.buffer;
    };

    FileDownload = (documentName) => {

        utils.invokeUrl("api/sims/OnlineAssessorApproval/DownloadAssessorLetters?FileName=" + documentName, (response) => {

            var blob = new Blob([this.base64ToArrayBuffer(response.Base64)], { type: response.Type });
            var url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.download = response.Name;
            link.target = '_blank';
            link.click();
            toolbarRef.current.setButtonDisabled("download", true);
        }, (e) => {
            this.setState({ messsageAlert: utils.SYSTEM_ERROR_MESSAGE, messageStyle: "outlinedError" });
        }
        );

    };

    emailDocuments = () => {
        var assessorFormat = [];
        assessorFormat.push(this.state.assessorID);

        let data = {
            AssessorIDs: assessorFormat,
            ...this.state.model
        }
        let url = "api/sims/OnlineAssessorApproval/GenerateAndEmailAssessorLetters";
        utils.invokeUrl(url, (response) => {
        },
            null,
            {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(data)
            }
        )
    };

    render() {
       
        return (
            <ViewContainer topPadding="0" title="">
                <p className="breadcrumbs">
                    {"Assessor Approval > Verify Details"}
                </p>
                <br></br>

                <AlertItem message={this.state.message} alertStyle={this.state.messageStyle} />
                <br></br>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td>
                                <EnhancedToolbar highlight={false} title="Assessor" buttonClickHandler={(buttonId) => this.handleToolbarButtonClick(buttonId)}
                                    buttonMenuClickHandler={(menu) => this.handleToolbarButtonMenuClick(menu)} enabled={true} ref={toolbarRef} config={this.toolbarConfig} />
                            </td>
                        </tr>
                        <tr>
                            <td className="formContainer">
                                <div style={{ height: "64vh", maxHeight: "64vh" }}>
                                    <OnlineAssessorDetailsVerificationForm ref={assessorVerifyRef} id={this.props.location.state.selection} valueChangeHandler={(values) => this.handleFormValueChange(values)} editPermission = {this.props.menuPermissionItem.Edit} />
                                </div>
                            </td> 
                        </tr>
                    </tbody>
                </table>
                <table width="100%">
                    <tbody>
                        <tr>
                            <td width="90%">
                            </td>
                            <td width="20%" align="right">
                                <div style={{ width: "120px", marginTop: "16px" }}>
                                    <EnhancedToolbar highlight={false} title="" buttonClickHandler={(buttonId) => this.handleLowerToolbarButtonClick(buttonId)} config={lowertoolbarConfig} />
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ViewContainer>
        );
    }
}

export default withRouter(withMenuPermission(OnlineAssessorDetailsVerification));
