import React from 'react';
import { EntityForm } from '../widgets/Forms';
import ViewUtils from '../ViewUtils';
import ResearchVisitDocumentDialog from "../etqa/employer/ResearchVisitDocumentDialog";

const utils = new ViewUtils();
export const ResearchOutcomeReportForm = React.forwardRef((props, ref) => {
    const [tabValue, setTabValue] = React.useState(0);
    const [documentsDialogOpen, setdocumentsDialogOpen] = React.useState(false);
    const [researchFileName, setResearchFileName] = React.useState('');

    const postUploadHandler = (save, cancel) => {
        if (save === true) {
            setdocumentsDialogOpen(false);
        }
        else if (cancel === true) {
            setdocumentsDialogOpen(false);
        }
        else {
            setdocumentsDialogOpen(false);
        }
    };

    return (
        <EntityForm ref={ref} viewId="ResearchOutcomeReportForm" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={form.classes.container} id="ResearchOutcomeReportForm">
                    <table cellPadding="2" width="80%">
                        <tbody>
                            <tr>
                                <td >
                                    <div>
                                        <ResearchVisitDocumentDialog
                                            open={documentsDialogOpen}
                                            closeHandler={(save, cancel) => postUploadHandler(save, cancel)}
                                            postUrl={"api/sims/" + props.controllerName + "/AddResearchVisitDocument"}
                                            EntityID={props.id}
                                            buttonLabel={"Upload Document"}
                                            fileName={researchFileName}
                                            _setFilename={(name) => { setResearchFileName(name) }}
                                        />
                                    </div>
                                </td>
                                <br></br>
                                <br></br>
                            </tr>
                        </tbody>
                    </table>
                </form>
            }
        </EntityForm>
    );
})
