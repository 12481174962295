import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import { SelectItem } from '../../widgets/SelectItem';
import { EntityForm } from '../../widgets/Forms';
import ViewUtils from '../../ViewUtils';

const utils = new ViewUtils();

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    MuiSelectSelectMenu: {
        '& .MuiSelect-selectMenu': { paddingRight: "150px !important" }
    }
}));

export default function DGDocumentsUploadSearchForm(props) {
    const [labelWidth, setLabelWidth, tabValue] = React.useState(0);
    const labelRef = React.useRef(null);
    const classes = useStyles();
    const [fYValue, setFYValue] = React.useState(null);
    const [clearOptions, setClearOptions] = React.useState(false);

    React.useEffect(() => {
        if (labelRef.current !== null) {
            setLabelWidth(labelRef.current.offsetWidth);
        }
    }, []);

    function FYCheck(value) {
        if (value !== undefined && value !== null) {
            if (value.Id >= "26") {
                setFYValue(true);
            }
            else {
                setFYValue(false);
            }
        }
    }

    function GetValue(value) {
        if (value !== undefined && value !== null) {
            return value.Id
        }
        else {
            return "0"
        }
    }

    function InterventionChange(value) {
        if (value !== undefined && value !== null) {
            setClearOptions(true);
        }
    }

    return (
        <EntityForm viewId="organisation" values={props.data}
            entityLookupController={props.entityLookupController} org="saseta"
            dataURL={utils.isNull(props.dataURL) ? "" : props.dataURL}
            entityLookupId={props.id} useCustomSettings={false} valueChangeHandler={props.valueChangeHandler}>
            {form =>
                <form className={classes.container} >
                    <table cellPadding="2">
                        <tbody>
                            <tr>
                                <td>
                                    <FormControl className={classes.formControl} variant="outlined">
                                        <SelectItem
                                            dataUrl="api/sims/dgdocumentupload/GetLookupList?listid=FinancialYear"
                                            id="FinancialYearID"
                                            key={tabValue}
                                            label="Financial Year"
                                            className={classes.MuiSelectSelectMenu}
                                            form={form}
                                        />
                                    </FormControl>
                                </td>
                                {
                                    (props.FYValue) ?
                                <td>
                                    <FormControl className={classes.formControl} variant="outlined">
                                        <SelectItem
                                            dataUrl={'api/sims/dgdocumentupload/GetDGDocumentInterventionByFY?parameter=[{"Name":"FinancialYearID","Value":' + GetValue(form.values["FinancialYearID"]) + '}]'}
                                            id="DGDocumentInterventionID"
                                            key={tabValue}
                                            label="Intervention Type"
                                            className={classes.MuiSelectSelectMenu}
                                            form={form}
                                        />
                                    </FormControl>
                                        </td>
                                        :
                                        ""
                                }
                                <td>
                                    <FormControl className={classes.formControl} variant="outlined">
                                        <SelectItem
                                            dataUrl={'api/sims/dgdocumentupload/GetDGDocumentTypes?parameter=[{"Name":"DGDocumentInterventionID","Value":' + GetValue(form.values["DGDocumentInterventionID"]) + '}, {"Name":"FinancialYearID","Value":' + GetValue(form.values["FinancialYearID"]) + '}]'}
                                            id="DocumentType"
                                            key={tabValue}
                                            label="Document Type"
                                            className={classes.MuiSelectSelectMenu}
                                            form={form}
                                            clearOptions={props.clearOptions}
                                            clearOptionsHandler={props.clearOptionsHandler}
                                        />
                                    </FormControl>                 
                                </td>
                                <td>
                         
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                </form >
            }
        </EntityForm>
    );
}
